import { KeywordState } from '@/store/KeywordStore'
import { ToastPositionWithLogical, AlertStatus } from '@chakra-ui/react'
import axios, { AxiosError } from 'axios'
import { format } from 'date-fns'

export const scrollIntoPage = (selector: string) => {
  const scrollTarget = document.querySelector(selector) as Element

  if(scrollTarget) {
    (scrollTarget as Element).scrollIntoView({ behavior: 'smooth' })
  } else {
    window.location.href = '/'
  }

}

export interface NoticeData {
  id: number
  title: string
  created_at: string
  hit: number
  content: string
  image_src: string[]
}

export const returnExampleNoticeData = () => {
  return [
    {
      id: 1,
      title: '엑스고 공지사항 제목입니다.',
      created_at: '2024.03.23',
      hit: 1234,
      content: `생의 천하를 품으며, 날카로우나 인생에 보라. 놀이 풀이 든 봄바람이다. 있음으로써 역사를 같이 아니한 이것이다. 그들의 대고, 황금시대의 꾸며 듣는다.그림자는 방황하였으며, 고행을 싸인 듣기만 말이다.전인 노년에게서 청춘이 없는 것이다.보라, 같이, 부패를 대중을 꽃이 끓는다.물방아 너의 사랑의 현저하게 석가는 길을 아름다우냐? 인생을 없으면 위하여, 끓는 꽃이 위하여서.영원히 얼음이 든 같이 것이다.보라, 유소년에게서 봄바람이다.새 청춘의 예수는 트고, 역사를 아름답고 소리다.이것은 천자만홍이 끓는다.\n방지하는 이것은 예가 위하여서, 청춘의 피는 듣기만 있을 동산에는 이것이다.사라지지 하여도 구할 그들은 얼마나 어디 그들의 사는가 인류의 것이다.끝까지 타오르고 따뜻한 되는 뭇 같은 것이다. 같지 넣는 있으며, 힘차게 무엇을 쓸쓸하랴?산야에 것은 할지라도 거선의 이상의 현저하게 이것이다. 불어 우리는 안고, 그들의 부패뿐이다.그들은 두기 이상의 이것이다. 가지에 풀이 청춘의 위하여서. 끓는 방황하여도,할지라도 일월과 청춘은 창공에 것이다.보라, 것이다.`,
      image_src: ['/example/notice_1.jpeg'],
    },
  ] as NoticeData[]
}

export const checkEmailValidation = (email: string): boolean => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  return emailRegex.test(email)
}

export const checkPhoneNumberVaildation = (phoneNumber: string): boolean => {
  return /^\d{3}-\d{4}-\d{4}$/.test(phoneNumber)
}

export const partialData = (targetData: any) => {
  return targetData
    ? Object.fromEntries(
        Object.entries(targetData).filter(
          ([_, value]) => value !== undefined && _ !== null,
        ),
      )
    : null
}

export interface ErrorResponseData {
  error: string
  detail: string
}

export const handleAPIError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    const axiosError = error as AxiosError<ErrorResponseData>
    if (axiosError.response) {
      if (axiosError.response.status === 500) {
        alert(axiosError.response.statusText)
      } else {
        alert(
          axiosError.response?.data?.error || axiosError.response?.data?.detail,
        )
      }
    }
  }
}

export const toLocalDateString = (dateString?: string) => {
  if (dateString) {
    return format(new Date(dateString), 'yyyy.MM.dd').toString()
  } else {
    return ''
  }
}

export const ToastUtil = (
  title: string,
  status?: string,
  description?: string,
) => {
  return {
    title: title,
    description: description || undefined,
    position: 'bottom-right' as ToastPositionWithLogical,
    status: (status || 'error') as AlertStatus,
    duration: 3000,
    isClosable: true,
  }
}

// KeywordState selector
export const selector = (state: KeywordState) => ({
  keyword: state.keyword,
  setKeyword: state.setKeyword,
})
