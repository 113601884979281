import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { Flex, Box, Text } from '@chakra-ui/react'

interface PaginationProps {
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  maxPageNumber: number
  pageList: number[]
}

const Pagination = ({
  page,
  setPage,
  maxPageNumber,
  pageList,
}: PaginationProps) => {
  return (
    <Flex width="100%" mx="auto" justifyContent="center" zIndex="1000">
      <Box
        userSelect="none"
        fontSize="15px"
        display="flex"
        backgroundColor="white"
        borderRadius={6}
      >
        <ChevronLeftIcon
          color="#CCCDCE"
          boxSize="5"
          alignSelf="center"
          cursor="pointer"
          pointerEvents={page <= 1 ? 'none' : 'auto'}
          onClick={() => setPage(page - 1)}
        />
        {pageList.map((eachPage) => {
          return (
            <Text
              key={eachPage}
              fontSize="16px"
              color={eachPage === page ? 'black' : '#CCCDCE'}
              fontWeight={400}
              marginX={3}
              marginY="5px"
              cursor="pointer"
              onClick={() => setPage(eachPage)}
            >
              {eachPage}
            </Text>
          )
        })}
        <ChevronRightIcon
          color="#CCCDCE"
          boxSize="5"
          alignSelf="center"
          cursor="pointer"
          pointerEvents={page === maxPageNumber ? 'none' : 'auto'}
          onClick={() => setPage(page + 1)}
        />
      </Box>
    </Flex>
  )
}

export default Pagination
