import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import MainLayout from '@/components/MainLayout'
import { HomeIcon } from '@/lib/HomeIcon'
import { useLocation, useNavigate } from 'react-router-dom'
import Service from '@/pages/main/Service'
import Partners from '@/pages/main/Partners'
import Mission from '@/pages/main/Mission'
import { useEffect, useState } from 'react'

const About = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate()
  const location = useLocation()
  const [isNoButton, setIsNoButton] = useState<boolean>(false)

  useEffect(() => {
    const isAboutPage = location?.pathname.includes('/about')
    if (isAboutPage) {
      setIsNoButton(true)
    }
  }, [location])

  return (
    <MainLayout>
      <Flex flexDirection="column">
        <Flex
          className="product-inquiry-bg"
          h={isMobile ? '270px' : '453px'}
          backgroundImage="/menu/about.jpeg"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
          flexDirection="column"
          justifyContent="center"
          alignItems="stretch"
          pt={20}
          pl={isMobile ? 2 : '10%'}
          w="full"
        >
          <Flex
            h="full"
            flexDirection="column"
            justifyContent="space-between"
            zIndex={4}
          >
            <Flex
              flexDirection="column"
              gap={2}
              pb={2}
              h="full"
              justifyContent="center"
              px={isMobile ? 4 : 0}
            >
              <Text
                fontSize={isMobile ? '12px' : '18px'}
                color="#CCCDCE"
                fontWeight={600}
              >
                COMPANY
              </Text>
              {/* todo: 정가운데 정렬 */}
              <Heading
                color="white"
                fontSize={isMobile ? '30px' : '50px'}
                fontWeight="600"
              >
                회사소개
              </Heading>
            </Flex>
            <Flex
              h={isMobile ? '50px' : '67px'}
              alignItems="center"
              justifyContent="space-between"
              w="full"
            >
              <Box />
              <Flex bgColor="white" alignItems="center">
                <Button
                  w={isMobile ? '50px' : '67px'}
                  h={isMobile ? '50px' : '67px'}
                  bgColor="#004A8D"
                  borderRadius={0}
                  onClick={() => navigate('#')}
                >
                  <HomeIcon boxSize={6} />
                </Button>
                <Flex px={8} gap={8} w={isMobile ? 'full' : '483px'}>
                  <Button variant="unstyled" onClick={() => navigate('#')}>
                    <Text
                      textDecor="underline"
                      color="#004A8D"
                      fontWeight={600}
                    >
                      회사소개
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Mission />
        <Service isNoButton={isNoButton} />
        <Partners isNoButton={isNoButton} />
        <Flex
          className="about-below-bg"
          h={isMobile ? '622px' : '402px'}
          backgroundImage="/menu/about_below.jpeg"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
          px={isMobile ? 6 : '10%'}
          justifyContent="center"
          alignItems="center"
          zIndex={1}
        >
          <Flex flexDirection="column" gap={6} zIndex={2} alignItems="center">
            <Text
              color="white"
              fontSize={isMobile ? '26px' : '34px'}
              fontWeight="600"
              textAlign="center"
              wordBreak="keep-all"
            >
              막막하기만 했던 해외 진출 <br />
              글로벌 시장에서의 성공의 문을 열어드립니다.
            </Text>
            <Button
              bgColor="transparent"
              color="white"
              borderRadius="60px"
              w={isMobile ? '166.44px' : '217px'}
              h={isMobile ? '45px' : '56px'}
              border="1.5px solid white"
              my={4}
              onClick={() => navigate('/service/contact')}
            >
              문의하기
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </MainLayout>
  )
}

export default About
