import { create } from 'zustand'
import produce from 'immer'

export interface KeywordState {
  keyword: string
  setKeyword: (targetKeyword: KeywordState['keyword']) => void
}

const useKeywordStore = create<KeywordState>((set, get) => ({
  keyword: '',

  setKeyword: (targetKeyword: KeywordState['keyword']) => {
    set(
      produce((state: KeywordState) => {
        state.keyword = targetKeyword
      }),
    )
  },
}))

export default useKeywordStore
