import { CargoProps, EstimateState, isAllStateParams, useRequestNewEstimate } from '@/API/estimateAPI'
import CountryInput from '@/components/form/CountryInput'
import PhoneInput from '@/components/form/PhoneInput'
import PortInput from '@/components/form/PortInput'
import MainLayout from '@/components/MainLayout'
import { HomeIcon } from '@/lib/HomeIcon'
import { checkPhoneNumberVaildation, ToastUtil } from '@/util/util'
import { AddIcon, CloseIcon, TriangleDownIcon } from '@chakra-ui/icons'
import {
  Flex,
  Heading,
  Box,
  Button,
  useMediaQuery,
  Text,
  Grid,
  FormControl,
  chakra,
  Input,
  CheckboxGroup,
  Checkbox,
  Stack,
  Select,
  InputGroup,
  InputRightElement,
  IconButton,
  UseToastOptions,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'


interface LCLProps {
  lcl_length: number | undefined;
  lcl_width: number | undefined;
  lcl_height: number | undefined;
  lcl_unit: string | undefined;
  lcl_weight: number | undefined;
}


const Estimate = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate()
  const toast = useToast()

  const [POL_country, setPOL_country] = useState<string>('')
  const [POL_port, setPOL_port] = useState<string>('')

  const [POD_country, setPOD_country] = useState<string>('')
  const [POD_port, setPOD_port] = useState<string>('')

  const [export_or_import, set_export_or_import] = useState<string>("export")
  const [transportation_mode, set_transportation_mode] = useState<string>("sea")

  const [cbm, setCbm] = useState<number>(0); // CBM 상태 추가

  const [LCL, setLCL] = useState<LCLProps>({
    lcl_length: undefined,
    lcl_width: undefined,
    lcl_height: undefined,
    lcl_unit: undefined,
    lcl_weight: undefined
  })

  const [cargoCreate, setCargoCreate] = useState<CargoProps>({
    info: undefined,
    type: undefined,
    danger_class: undefined,
    length: undefined,
    width: undefined,
    height: undefined,
    unit: undefined,
    min_temperature: undefined,
    max_temperature: undefined,
    container_type: undefined,
    container_count: undefined,
    weight: undefined, 
  })

  const [cargos, setCargos] = useState<CargoProps[]>([])




  const handleCargoChange = (event_value: string, field: keyof CargoProps) => {
    let value: string = event_value;
    if (field !== 'unit' && field !== 'info' && field !== 'type' && field !== 'danger_class' && field !== 'container_type') {
      value = value.replace(/[^0-9.-]/g, '') 
        .replace(/(?!^)-/g, '')
        .replace(/^-{2,}/, '-')
        .replace(/(\..*)\./g, '$1');
    }

    setCargoCreate((prevLCL) => ({
      ...prevLCL,
      [field]: value, // 해당 필드 업데이트
    }))
  };

  const pushCargoList = () => {
    let cargoCheck: boolean = false;

    if(cargoCreate.type === "SPECIAL") {
      switch (cargoCreate.info) {
        case "DG":
          cargoCheck = (
            cargoCreate.type && 
            cargoCreate.container_type && 
            cargoCreate.weight && 
            cargoCreate.danger_class
          ) ? true: false;
          break;
  
        case "FR":
        case "OT":
          cargoCheck = (
            cargoCreate.type && 
            cargoCreate.container_type && 
            cargoCreate.weight && 
            cargoCreate.length !== undefined &&
            cargoCreate.width !== undefined &&
            cargoCreate.height !== undefined
          ) ? true : false;
          break;
  
        case "RF":
          cargoCheck = (
            cargoCreate.type && 
            cargoCreate.container_type && 
            cargoCreate.weight && 
            cargoCreate.min_temperature !== undefined &&
            cargoCreate.max_temperature !== undefined
          ) ? true : false;
          break;
      }
    } else if (
      cargoCreate.type && 
      cargoCreate.container_type && 
      cargoCreate.weight
    ) {
      cargoCheck = true;
    };


    if(cargoCheck) {
      setCargos(list => [...list, cargoCreate]);
      
      setCargoCreate({
        info: undefined,
        type: undefined,
        danger_class: undefined,
        length: undefined,
        width: undefined,
        height: undefined,
        unit: undefined,
        min_temperature: undefined,
        max_temperature: undefined,
        container_type: undefined,
        container_count: undefined,
        weight: undefined, 
      })
    } else {
      toast(
        ToastUtil(
          '공란 확인 필요',
          'warning',
          '비어있는 란이 있습니다. 내용을 모두 기재한 후 제출해 주세요.',
        ) as UseToastOptions,
      )
    }
  }

  const handleClearCargo = (index: number) => {
    setCargos((prevCargoList) => prevCargoList.filter((_, i) => i !== index));
  };


  
  const handleLCLChange = (event_value: string, field: keyof LCLProps) => {
    let value: string = event_value;
    if (field !== 'lcl_unit') {
      value = value.replace(/[^0-9.]/g, '');
    }

    setLCL((prevLCL) => ({
      ...prevLCL,
      [field]: value, // 해당 필드 업데이트
    }))
  };



  useEffect(() => {
    const { lcl_length, lcl_width, lcl_height, lcl_unit } = LCL
    
    if (
      lcl_length !== undefined && 
      lcl_width !== undefined && 
      lcl_height !== undefined && 
      lcl_unit !== undefined
    ) {
      const lengthInMeters = lcl_unit === 'cm' ? lcl_length / 100 : lcl_length
      const widthInMeters = lcl_unit === 'cm' ? lcl_width / 100 : lcl_width
      const heightInMeters = lcl_unit === 'cm' ? lcl_height / 100 : lcl_height

      const calculatedCbm = lengthInMeters * widthInMeters * heightInMeters // CBM 계산
      setCbm(calculatedCbm)
    } else {
      setCbm(0);
    }
  }, [LCL]);

  
  const { mutate: requestNewEstimate, isSuccess } = useRequestNewEstimate()

  const estimateSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const target = e.target as HTMLFormElement
    const params: Partial<EstimateState> = {}
    const inputs = Array.from(target.elements) as Array<HTMLInputElement>

    inputs.map((input) => {
      if (input.tagName === 'INPUT' && !input.id.includes('field')) {
        const id = input.id as keyof EstimateState
        params[id] = input.value as any
      }
    })

    params['export_or_import'] = export_or_import
    params['transportation_mode'] = transportation_mode
    params['departure_country'] = POL_country
    params['departure_port'] = POL_port
    params['destination_country'] = POD_country
    params['destination_port'] = POD_port

    params["lcl_length"] = Number(LCL.lcl_length)
    params["lcl_width"] = Number(LCL.lcl_width)
    params["lcl_height"] = Number(LCL.lcl_height)
    params["lcl_unit"] = LCL.lcl_unit
    params["lcl_weight"] = Number(LCL.lcl_weight)

    if(cargos.length === 0) {
      // LCL 검수
      if(
        typeof params.lcl_length === 'number' &&
        typeof params.lcl_width === 'number' &&
        typeof params.lcl_height === 'number' &&
        typeof params.lcl_unit === 'string' &&
        typeof params.lcl_weight === 'number'
      ) {
        params['cargos'] = []
      } else {
        toast(
          ToastUtil(
            '화물정보 필요',
            'warning',
            '화물 정보를 기재한 후, 화물 추가를 눌러주세요.',
          ) as UseToastOptions,
        )
  
        return
      }
    } else {
      params['cargos'] = cargos
    }

    if (isAllStateParams(params)) {
      if(
        POL_country === '' ||
        POL_port === '' || 
        POD_country === '' || 
        POD_port=== ''
      ) {
        toast(
          ToastUtil(
            '출발지/도착지 형식 오류',
            'warning',
            '출발지/도착지를 입력해 주세요.',
          ) as UseToastOptions,
        )
      } else {
        const isVaildPhoneNumber = checkPhoneNumberVaildation(params.phone)
        if (isVaildPhoneNumber) {
          
          const validatePhone = params.phone.replace(/-/gi, '')
  
          const paramsWithCategory = {
            ...params,
            phone: validatePhone,
            cargos: params.cargos?.map((item, index: number) => {
              return {
                ...item,
                container_count: index + 1,
                type: item.type === "SPECIAL" ? "special" : "dry_normal"
              }
            })?.reverse()
          }
  
          requestNewEstimate(paramsWithCategory)
        } else if(!isVaildPhoneNumber) {
          toast(
            ToastUtil(
              '전화번호 형식 오류',
              'warning',
              '올바른 전화번호를 입력해 주세요.',
            ) as UseToastOptions,
          )
        }
      }

    } else {
      toast(
        ToastUtil(
          '공란 확인 필요',
          'warning',
          '비어있는 란이 있습니다. 내용을 모두 기재한 후 제출해 주세요.',
        ) as UseToastOptions,
      )
    }
  }

  
  // todo: 견적 등록 토스트 텍스트 추가
  useEffect(() => {
    if (isSuccess) {
      toast(
        ToastUtil(
          '견적 등록 성공',
          'success',
          '견적요청이 완료되었습니다.',
        ) as UseToastOptions,
      )
    }
  }, [isSuccess])

  
  return (
    <MainLayout>
      <Flex flexDirection="column">
        <Flex
          className="product-inquiry-bg"
          h={isMobile ? '270px' : '453px'}
          backgroundImage="/menu/estimate.jpeg"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
          flexDirection="column"
          justifyContent="center"
          alignItems="stretch"
          pt={20}
          pl={isMobile ? 2 : '10%'}
          w="full"
        >
          <Flex
            h="full"
            flexDirection="column"
            justifyContent="space-between"
            zIndex={4}
          >
            <Flex
              flexDirection="column"
              gap={2}
              pb={2}
              h="full"
              justifyContent="center"
              px={isMobile ? 4 : 0}
            >
              <Text
                fontSize={isMobile ? '12px' : '18px'}
                color="#CCCDCE"
                fontWeight={600}
              >
                RFQ
              </Text>
              {/* todo: 정가운데 정렬 */}
              <Heading
                color="white"
                fontSize={isMobile ? '30px' : '50px'}
                fontWeight="600"
              >
                물류비 견적요청
              </Heading>
            </Flex>
            <Flex
              h={isMobile ? '50px' : '67px'}
              alignItems="center"
              justifyContent="space-between"
              w="full"
            >
              <Box />
              <Flex bgColor="white" alignItems="center">
                <Button
                  w={isMobile ? '50px' : '67px'}
                  h={isMobile ? '50px' : '67px'}
                  bgColor="#004A8D"
                  borderRadius={0}
                  onClick={() => navigate('#')}
                >
                  <HomeIcon boxSize={6} />
                </Button>
                <Flex px={8} gap={8} w={isMobile ? 'full' : '483px'}>
                  <Button variant="unstyled" onClick={() => navigate('#')}>
                    <Text
                      textDecor="underline"
                      color="#004A8D"
                      fontWeight={600}
                    >
                      물류비 견적요청
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          id="estimate"
          py={20}
          pb={isMobile ? 14 : 20}
          px={isMobile ? 6 : '10%'}
          minW="100vw"
          flexDirection="column"
          gap={isMobile ? 6 : 0}
        >
          <Flex
            flexDirection="column"
            gap={4}
            pb={2}
            alignItems="center"
          >
            <Heading color="#004A8D" fontSize={isMobile ? '34px' : '50px'}>
              RFQ
            </Heading>

            <Text
              fontSize={isMobile ? 'md' : '22px'}
              color="#787E88"
              fontWeight={500}
              textAlign="center"
            >
              엑스고에서 글로벌 운송사의 물류비 견적을<br/>
              빠르고 정확하게 알려드리겠습니다.
            </Text>
          </Flex>
          
          <Flex
            w="full" 
            py={isMobile ? 8 : 20} 
            overflowY="auto"
            flexDirection="column"
          >
            <chakra.form onSubmit={estimateSubmit}>
              <FormControl
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
              >
                <Grid
                  gap={6}
                  w="full"
                  templateColumns={{
                    md: 'repeat(1, 1fr)',
                    lg: 'repeat(2, 1fr)',
                  }}
                  px="2px"
                >
                  {/* 구분 */}
                  <Box w="full">
                    <Text marginBottom={3} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>구분</Text>
                    <Flex w="full" h={isMobile ? '50px' : '60px'} px="16px" bgColor="#F7F7F7">
                      <Grid
                        w="full"
                        templateColumns={{
                          base: 'repeat(2, 1fr)',
                          lg: 'repeat(3, 1fr)',
                        }}
                      >
                        <Checkbox 
                          colorScheme="blue"
                          isChecked={export_or_import === "export"} 
                          onChange={() => set_export_or_import("export")}
                        >
                          수출
                        </Checkbox>
                        
                        <Checkbox 
                          colorScheme="blue"
                          isChecked={export_or_import === "import"} 
                          onChange={() => set_export_or_import("import")}
                        >
                          수입
                        </Checkbox>
                      </Grid>
                    </Flex>
                  </Box>


                  {/* 출항예정일 */}
                  <Box w="full">
                    <Text marginBottom={3} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>출항예정일</Text>
                    <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                      <Input
                        id="scheduled_departure_date"
                        type="date"
                        h={isMobile ? '50px' : '60px'}
                        bgColor="#F7F7F7"
                        borderRadius={0}
                        border="none"
                      />
                    </Box>
                  </Box>


                  {/* 운송모드 */}
                  <Box w="full">
                    <Text marginBottom={3} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>운송모드</Text>
                    <Flex w="full" h={isMobile ? '50px' : '60px'} px="16px" bgColor="#F7F7F7">
                      <Grid
                        w="full"
                        templateColumns={{
                          base: 'repeat(2, 1fr)',
                          lg: 'repeat(3, 1fr)',
                        }}
                      >
                        <Checkbox 
                          colorScheme="blue"
                          isChecked={transportation_mode === "sea"} 
                          onChange={() => set_transportation_mode("sea")}
                        >
                          해상
                        </Checkbox>
                        
                        <Checkbox 
                          colorScheme="blue"
                          isChecked={transportation_mode === "aviation"} 
                          onChange={() => set_transportation_mode("aviation")}
                        >
                          항공
                        </Checkbox>
                      </Grid>
                    </Flex>
                  </Box>


                  {/* 전화번호 */}
                  <Box w="full">
                    <Text marginBottom={3} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>전화번호</Text>
                    <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                      <PhoneInput
                        id="phone"
                        isMobile={isMobile}
                      />
                    </Box>
                  </Box>


                  {/* 출발지(POL) */}
                  <Box w="full">
                    <Text marginBottom={3} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>출발지(POL)</Text>
                    <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                      <CountryInput 
                        isMobile={isMobile}
                        onChange={(country: string) => {
                          setPOL_country(country)
                        }}
                      />
                    </Box>
                  </Box>
                  
                  <Box w="full">
                    <Text marginBottom={3} h={isMobile ? '0px' : '27px'} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}></Text>
                    <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                      <PortInput
                        country={POL_country}
                        isMobile={isMobile}
                        onChange={(port: string) => {
                          setPOL_port(port)
                        }}
                      />
                    </Box>
                  </Box>

                  {/* 도착지(POD) */}
                  <Box w="full">
                    <Text marginBottom={3} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>도착지(POD)</Text>
                    <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                      <CountryInput 
                        isMobile={isMobile}
                        onChange={(country: string) => {
                          setPOD_country(country)
                        }}
                      />
                    </Box>
                  </Box>

                  <Box w="full">
                    <Text marginBottom={3} h={isMobile ? '0px' : '27px'} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}></Text>
                    <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                      <PortInput
                        country={POD_country}
                        isMobile={isMobile}
                        onChange={(port: string) => {
                          setPOD_port(port)
                        }}
                      />
                    </Box>
                  </Box>


                  {/* 컨테이너 적입 작업지 */}
                  <Box w="full">
                    <Text marginBottom={3} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>컨테이너 적입 작업지</Text>
                    <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                      <Input
                        placeholder="도(Province)"
                        id="stuffing_province"
                        h={isMobile ? '50px' : '60px'}
                        bgColor="#F7F7F7"
                        borderRadius={0}
                        border="none"
                      />
                    </Box>
                  </Box>

                  <Box w="full">
                    <Text marginBottom={3} h={isMobile ? '0px' : '27px'} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}></Text>
                    <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                      <Input
                        placeholder="시(City)"
                        id="stuffing_city"
                        h={isMobile ? '50px' : '60px'}
                        bgColor="#F7F7F7"
                        borderRadius={0}
                        border="none"
                      />
                    </Box>
                  </Box>
                </Grid>

                
                
                <Flex w="full" justifyContent="space-between" marginTop={6} marginBottom={3} >
                  <Text fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>화물정보</Text>
                  <Button 
                    leftIcon={<AddIcon color="#004A8D" />}
                    height={isMobile ? '0px' : '27px'}
                    color="#004A8D" 
                    fontSize={isMobile ? 'md' : '18px'} 
                    fontWeight={500}
                    onClick={pushCargoList}
                  >
                    화물 추가
                  </Button>
                </Flex>
                <Grid
                  gap={6}
                  w="full"
                  px="2px"
                  templateColumns={{
                    md: 'repeat(3, 1fr)',
                    lg: 'repeat(5, 1fr)',
                  }}
                >
                  <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                    <Select
                      placeholder="선택"
                      minW="full"
                      h={isMobile ? '50px' : '60px'}
                      bgColor="#F7F7F7"
                      borderRadius={0}
                      border="none"
                      onChange={e => handleCargoChange(e.target.value, 'type')}
                      icon={<TriangleDownIcon fontSize="8px" />}
                      textIndent="1rem"
                      value={cargoCreate.type || ""}
                    >
                      {['DRY(일반)', 'SPECIAL'].map((category, idx) => {
                        return (
                          <option key={idx} value={category}>
                            {category}
                          </option>
                        )
                      })}
                    </Select>
                  </Box>

                  {/* 추가 정보란 */}
                  {cargoCreate.type === "SPECIAL" && 
                    <>
                    <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                      <Select
                        placeholder="선택"
                        minW="full"
                        h={isMobile ? '50px' : '60px'}
                        bgColor="#F7F7F7"
                        borderRadius={0}
                        border="none"
                        onChange={e => handleCargoChange(e.target.value, 'info')}
                        icon={<TriangleDownIcon fontSize="8px" />}
                        textIndent="1rem"
                        value={cargoCreate.info || ""}
                      >
                        {['DG', 'RF', 'FR', 'OT'].map((category, idx) => {
                          return (
                            <option key={idx} value={category}>
                              {category}
                            </option>
                          )
                        })}
                      </Select>
                    </Box>
                      {cargoCreate.info === "DG" && 
                        <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                          <Select
                            placeholder="추가 정보"
                            minW="full"
                            h={isMobile ? '50px' : '60px'}
                            bgColor="#F7F7F7"
                            borderRadius={0}
                            border="none"
                            onChange={e => handleCargoChange(e.target.value, 'danger_class')}
                            icon={<TriangleDownIcon fontSize="8px" />}
                            textIndent="1rem"
                            value={cargoCreate.danger_class || ""}
                          >
                            {[
                              '위험물 등급 1',
                              '위험물 등급 2',
                              '위험물 등급 3',
                              '위험물 등급 4',
                              '위험물 등급 5',
                              '위험물 등급 6',
                              '위험물 등급 7',
                              '위험물 등급 8'
                            ].map((category, idx) => {
                              return (
                                <option key={idx} value={category}>
                                  {category}
                                </option>
                              )
                            })}
                          </Select>
                        </Box>
                      }


                      {
                        cargoCreate.info === "RF" && 
                        <>
                          <Input            
                            onChange={(e) => handleCargoChange(e.target.value, 'min_temperature')}
                            placeholder="최소 온도"
                            h={isMobile ? '50px' : '60px'}
                            bgColor="#F7F7F7"
                            borderRadius={0}
                            border="none"
                            value={cargoCreate.min_temperature || ""}
                          />
                          <Input            
                            onChange={(e) => handleCargoChange(e.target.value, 'max_temperature')}
                            placeholder="최대 온도"
                            h={isMobile ? '50px' : '60px'}
                            bgColor="#F7F7F7"
                            borderRadius={0}
                            border="none"
                            value={cargoCreate.max_temperature || ""}
                          />
                        </>
                      }

                      {
                        (cargoCreate.info === "FR" || cargoCreate.info === "OT") && 
                        <>
                          <Input            
                            onChange={(e) => handleCargoChange(e.target.value, 'length')}
                            placeholder="길이"
                            h={isMobile ? '50px' : '60px'}
                            bgColor="#F7F7F7"
                            borderRadius={0}
                            border="none"
                            value={cargoCreate.length || ""}
                          />
                          <Input            
                            onChange={(e) => handleCargoChange(e.target.value, 'width')}
                            placeholder="너비"
                            h={isMobile ? '50px' : '60px'}
                            bgColor="#F7F7F7"
                            borderRadius={0}
                            border="none"
                            value={cargoCreate.width || ""}
                          />
                          <Input            
                            onChange={(e) => handleCargoChange(e.target.value, 'height')}
                            placeholder="높이"
                            h={isMobile ? '50px' : '60px'}
                            bgColor="#F7F7F7"
                            borderRadius={0}
                            border="none"
                            value={cargoCreate.height || ""}
                          />
                          <Select
                            placeholder="단위"
                            minW="full"
                            h={isMobile ? '50px' : '60px'}
                            bgColor="#F7F7F7"
                            borderRadius={0}
                            border="none"
                            onChange={e => handleCargoChange(e.target.value, 'unit')}
                            icon={<TriangleDownIcon fontSize="8px" />}
                            textIndent="1rem"
                            defaultValue="클릭하여 카테고리를 선택해 주세요."
                          >
                            {['cm', 'm'].map((category, idx) => {
                              return (
                                <option key={idx} value={category}>
                                  {category}
                                </option>
                              )
                            })}
                          </Select>
                        </>
                      }


                    </>
                  }


                  <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                    <Select
                      placeholder="컨테이너 타입"
                      minW="full"
                      h={isMobile ? '50px' : '60px'}
                      bgColor="#F7F7F7"
                      borderRadius={0}
                      border="none"
                      onChange={e => handleCargoChange(e.target.value, 'container_type')}
                      icon={<TriangleDownIcon fontSize="8px" />}
                      textIndent="1rem"
                      value={cargoCreate.container_type || ""}
                    >
                      {["20'", "40'", "40'HC"].map((category, idx) => {
                        return (
                          <option key={idx} value={category}>
                            {category}
                          </option>
                        )
                      })}
                    </Select>
                  </Box>
                  <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                    <Input            
                      onChange={(e) => handleCargoChange(e.target.value, 'weight')}
                      placeholder="무게(톤)"
                      h={isMobile ? '50px' : '60px'}
                      bgColor="#F7F7F7"
                      borderRadius={0}
                      border="none"
                      value={cargoCreate.weight || ""}
                    />
                  </Box>
                </Grid>

                {cargos.length > 0 && 
                  <Box marginTop={6}  px="2px" w="full">
                    {cargos.map((cargo: CargoProps, index) => (
                      <InputGroup
                        bgColor="#F7F7F7"
                        marginBottom={2}
                        display="flex"
                        justifyContent="center"
                        key={`cargo-item-${index}`}
                      >
                        <Input
                          value={
                            [
                              cargo.type,
                              cargo.info,
                              cargo.danger_class,
                              cargo.length,
                              cargo.width,
                              cargo.height,
                              cargo.unit,
                              cargo.min_temperature,
                              cargo.max_temperature,
                              cargo.container_type,
                              cargo.container_count,
                              cargo.weight 
                            ].filter(item => item !== undefined).join(', ')
                          }
                          readOnly={true}
                          h={isMobile ? '50px' : '60px'}
                          bgColor="#F7F7F7"
                          borderRadius={0}
                          border="none"
                          placeholder="내용 입력(선택)"
                        />
                        <InputRightElement h="full" >
                          <IconButton
                            aria-label="Clear"
                            icon={<CloseIcon color="#000"/>}
                            size="sm"
                            onClick={() => handleClearCargo(index)} // X 버튼 클릭 시 실행될 함수
                          />
                        </InputRightElement>
                      </InputGroup>
                    ))}
                  </Box>
                }


                <Flex w="full" justifyContent="space-between" marginTop={6} marginBottom={3} >
                  <Text fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>LCL</Text>
                  <Text fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>총 CBM {cbm.toFixed(4)} m³</Text>
                </Flex>
                <Grid
                  gap={6}
                  w="full"
                  px="2px"
                  templateColumns={{
                    md: 'repeat(3, 1fr)',
                    lg: 'repeat(5, 1fr)',
                  }}
                >
                  <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                    <Input
                      value={LCL.lcl_length}
                      onChange={(e) => handleLCLChange(e.target.value, 'lcl_length')}
                      placeholder="가로(L)"
                      h={isMobile ? '50px' : '60px'}
                      bgColor="#F7F7F7"
                      borderRadius={0}
                      border="none"
                    />
                  </Box>
                  <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                    <Input
                      value={LCL.lcl_width}
                      placeholder="세로(W)"
                      onChange={(e) => handleLCLChange(e.target.value, 'lcl_width')}
                      h={isMobile ? '50px' : '60px'}
                      bgColor="#F7F7F7"
                      borderRadius={0}
                      border="none"
                    />
                  </Box>
                  <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                    <Input
                      value={LCL.lcl_height}
                      onChange={(e) => handleLCLChange(e.target.value, 'lcl_height')}
                      placeholder="높이(H)"
                      h={isMobile ? '50px' : '60px'}
                      bgColor="#F7F7F7"
                      borderRadius={0}
                      border="none"
                    />
                  </Box>
                  <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                    <Select
                      placeholder="단위"
                      minW="full"
                      h={isMobile ? '50px' : '60px'}
                      bgColor="#F7F7F7"
                      borderRadius={0}
                      border="none"
                      onChange={e => handleLCLChange(e.target.value, 'lcl_unit')}
                      icon={<TriangleDownIcon fontSize="8px" />}
                      textIndent="1rem"
                      defaultValue="클릭하여 카테고리를 선택해 주세요."
                    >
                      {['cm', 'm'].map((category, idx) => {
                        return (
                          <option key={idx} value={category}>
                            {category}
                          </option>
                        )
                      })}
                    </Select>
                  </Box>
                  <Box w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                    <Input            
                      value={LCL.lcl_weight}
                      onChange={(e) => handleLCLChange(e.target.value, 'lcl_weight')}
                      placeholder="무게(톤)"
                      h={isMobile ? '50px' : '60px'}
                      bgColor="#F7F7F7"
                      borderRadius={0}
                      border="none"
                    />
                  </Box>
                </Grid>
                

                <Text marginTop={6} marginBottom={3} fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>메모</Text>
                <Box px="2px" w="full" h={isMobile ? '50px' : '60px'} bgColor="#F7F7F7">
                  <Input
                    id="memo"
                    h={isMobile ? '50px' : '60px'}
                    bgColor="#F7F7F7"
                    borderRadius={0}
                    border="none"
                    placeholder="내용 입력(선택)"
                  />
                </Box>

                <Flex w="full" justifyContent="center" my={4} mt="60px">
                  <Button
                    type="submit"
                    w={isMobile ? "full" : "462px"}
                    h={isMobile ? '50px' : '60px'}
                    bgColor="#0D3A65"
                    borderRadius={0}
                  >
                    견적요청
                  </Button>
                </Flex>
              </FormControl>
            </chakra.form>
          </Flex>
        </Flex>
      </Flex>
    </MainLayout>
  )
}

export default Estimate
