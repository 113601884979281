import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  UseToastOptions,
  chakra,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import MainLayout from '@/components/MainLayout'
import { useNavigate } from 'react-router-dom'
import FindPassword from '@/components/modal/FindPassword'
import {
  isAllLoginParams,
  LoginRequest,
  useGetUser,
  useLoginUser,
} from '@/API/userAPI'
import { checkEmailValidation, ToastUtil } from '@/util/util'
import { useEffect, useState } from 'react'
import { HiEye } from 'react-icons/hi2'
import { HiEyeOff } from 'react-icons/hi'

const Login = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const [show, setShow] = useState<boolean>(false)
  const [isGetUserData, setIsGetUserData] = useState<boolean>(false)
  const { mutate: loginUser, isSuccess: isLoginUserSuccess } = useLoginUser()
  const { data: loginUserData } = useGetUser(isGetUserData)

  const toggleLoginUser = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as HTMLFormElement
    const inputs = Array.from(target.elements) as Array<HTMLInputElement>
    const params: Partial<LoginRequest> = {}

    inputs.map((input) => {
      const id = input.id as keyof LoginRequest
      params[id] = input.value
    })
    // 모든 프로퍼티 존재 여부 체크
    if (isAllLoginParams(params)) {
      if (checkEmailValidation(params.email as string)) {
        loginUser({
          email: params.email,
          password: params.password,
        })
      } else {
        toast(
          ToastUtil(
            '이메일 주소 형식 오류',
            'warning',
            '올바른 이메일 주소를 입력해주세요.',
          ) as UseToastOptions,
        )
      }
    }
  }

  useEffect(() => {
    if (isLoginUserSuccess) {
      setIsGetUserData(true)
    }
  }, [isLoginUserSuccess])

  useEffect(() => {
    if (loginUserData) {
      localStorage.setItem('EXGO_USER_NAME', loginUserData.fullname)
      toast(
        ToastUtil(
          '로그인 성공',
          'success',
          `${loginUserData.fullname} 님, 환영합니다.`,
        ) as UseToastOptions,
      )
      navigate('/')
      setIsGetUserData(false)
    }
  }, [setIsGetUserData, loginUserData])

  return (
    <MainLayout>
      <Flex
        minH={isMobile ? '650px' : '850px'}
        alignItems="center"
        flexDirection="column"
        py={isMobile ? 20 : 16}
        w="full"
      >
        <Flex
          my={isMobile ? '40px' : '80px'}
          w="full"
          alignItems="center"
          justifyContent="center"
        >
          <Heading color="#004A8D" fontSize={isMobile ? '36px' : '50px'}>
            Login
          </Heading>
        </Flex>
        <chakra.form w="full" onSubmit={toggleLoginUser}>
          <FormControl
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            gap={8}
            justifyContent="center"
            alignItems="center"
            w="full"
            px={isMobile ? 6 : 0}
          >
            <Flex
              flex={1}
              w={isMobile ? 'full' : '462px'}
              flexDirection="column"
              gap={2}
              id={`input-group`}
            >
              <FormLabel fontSize="18px" fontWeight={500}>
                아이디(E-mail)
              </FormLabel>
              <Input
                id="email"
                minH="60px"
                bgColor="#F7F7F7"
                borderRadius={0}
                border="none"
              />
            </Flex>
            <Flex
              flex={1}
              w={isMobile ? 'full' : '462px'}
              flexDirection="column"
              gap={2}
              id={`input-group`}
            >
              <FormLabel fontSize="18px" fontWeight={500}>
                비밀번호
              </FormLabel>
              <InputGroup>
                <Input
                  id="password"
                  minH="60px"
                  bgColor="#F7F7F7"
                  borderRadius={0}
                  border="none"
                  type={show ? 'text' : 'password'}
                />
                <InputRightElement height="100%">
                  <IconButton
                    size="lg"
                    bg="transparent !important"
                    variant="ghost"
                    aria-label={show ? 'Mask password' : 'Reveal password'}
                    icon={show ? <HiEyeOff /> : <HiEye />}
                    boxSize="7"
                    onClick={() => setShow(!show)}
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
            <Button
              type="submit"
              w={isMobile ? 'full' : '462px'}
              h="60px"
              bgColor="#0D3A65"
              borderRadius={0}
              my={4}
            >
              로그인
            </Button>
            <Flex alignItems="center" gap={4}>
              <FindPassword />
              <Divider
                orientation="vertical"
                h="14px"
                opacity={1}
                borderColor="#CCCCCC"
                borderWidth="0.8px"
              />

              <Button
                variant="unstyled"
                onClick={() => navigate('/user/signup')}
              >
                <Text color="#0E3961" fontWeight={600}>
                  회원가입
                </Text>
              </Button>
            </Flex>
          </FormControl>
        </chakra.form>
      </Flex>
    </MainLayout>
  )
}

export default Login
