import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

interface PartnersProps {
  isNoButton?: boolean
}

const Partners = ({ isNoButton }: PartnersProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate();

  const LogoImages = [
    // todo: change with real logo images
    'partner1.svg',
    'partner2.svg',
    'partner3.svg',
    'partner4.svg',
    'partner5.svg'
  ]
  return (
    <Flex
      id="partners"
      py={20}
      px={isMobile ? 6 : '10%'}
      maxH="1080px"
      minW="100vw"
      bgColor="#F7F7F7"
    >
      <Flex
        flexDirection="column"
        gap={isMobile ? 4 : 6}
        justifyContent="center"
        w="full"
      >
        <Flex flexDirection="column" gap={2} pb={2}>
          <Text
            fontSize={isMobile ? '12px' : '18px'}
            color="#7F8589"
            fontWeight={600}
          >
            WHO WITH US
          </Text>
          <Heading color="#004A8D" fontSize={isMobile ? '34px' : '50px'}>
            Our Partners
          </Heading>
        </Flex>
        {!isNoButton && (
          <Button
            border="1.5px solid #7F8589"
            borderRadius="60px"
            py="20px"
            px="40px"
            w={isMobile ? '166px' : '217px'}
            h={isMobile ? '45px' : '56px'}
            onClick={() => navigate('/about')}
          >
            <Text color="#7F8589">회원사 보러가기</Text>
          </Button>
        )}
        <Grid
          templateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(5, 1fr)',
          }}
          pb={4}
          pt={8}
          gap={4}
        >
          {LogoImages.map((src: string, index: number) => (
            <Image
              key={index}
              src={'/main/logo/' + src}
              alt={`image-${index + 1}`}
            />
          ))}
        </Grid>
      </Flex>
    </Flex>
  )
}

export default Partners
