import { useGetFAQ } from '@/API/serviceAPI'
import { MinusIcon, AddIcon } from '@chakra-ui/icons'
import {
  Flex,
  Heading,
  Button,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  useMediaQuery,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const FAQ = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const navigate = useNavigate();
  const { data: FAQList } = useGetFAQ()


  const FAQAccordionItems = () => {
    return FAQList?.map((faq, idx) => {
      return (
        <AccordionItem
          key={idx}
          borderTopWidth={idx === 0 ? 0 : 1}
          borderColor="#CCCDCE"
        >
          {({ isExpanded }) => (
            <>
              <h2>
                <AccordionButton flexDirection="row-reverse" gap={8}>
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize={isMobile ? 'md' : '20px'}
                    py={4}
                    wordBreak="keep-all"
                  >
                    {faq.title}
                  </Box>
                  {isExpanded ? (
                    <MinusIcon
                      fontSize={isMobile ? '16px' : '30px'}
                      color="#0e3a60"
                    />
                  ) : (
                    <AddIcon
                      fontSize={isMobile ? '16px' : '30px'}
                      color="#0e3a60"
                    />
                  )}
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={8}
                px={isMobile ? 6 : 20}
                display="flex"
                gap={isMobile ? 10 : 6}
              >
                <Divider
                  orientation="vertical"
                  h={isMobile ? '44px' : 'auto'}
                  borderColor="#0e3a60"
                  opacity="1"
                  borderLeftWidth="2px"
                  my={1}
                />
                <Text
                  wordBreak="keep-all"
                  color="#7F8589"
                  fontWeight="500"
                  fontSize={isMobile ? '14px' : 'md'}
                >
                  {faq.content}
                </Text>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      )
    })
  }
  return (
    <Flex
      id="FAQ"
      py={20}
      px={isMobile ? 6 : '10%'}
      flexDirection={isMobile ? 'column' : 'row'}
      maxH="1080px"
      minW="100vw"
      bgColor="#F7F7F7"
    >
      <Flex
        flexDirection="column"
        gap={isMobile ? 4 : 6}
        justifyContent="flex-start"
        pb={isMobile ? 10 : 0}
      >
        <Flex flexDirection="column" gap={2} pb={2}>
          <Text
            fontSize={isMobile ? '12px' : '18px'}
            color="#7F8589"
            fontWeight={600}
          >
            INFORMATION
          </Text>
          <Heading color="#004A8D" fontSize={isMobile ? '34px' : '50px'}>
            FAQ
          </Heading>
        </Flex>
        <Button
          border="1.5px solid #7F8589"
          borderRadius="60px"
          py="20px"
          px="40px"
          w={isMobile ? '166px' : '217px'}
          h={isMobile ? '45px' : '56px'}
          onClick={() => navigate('/service/notice')}
        >
          <Text color="#7F8589">자세히 보기</Text>
        </Button>
      </Flex>
      <Flex w="full" p={isMobile ? 0 : 20} overflowY="auto">
        <Accordion allowMultiple w="full" defaultIndex={[0]}>
          {FAQAccordionItems()}
        </Accordion>
      </Flex>
    </Flex>
  )
}

export default FAQ
