import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import MainLayout from '@/components/MainLayout'
import { HomeIcon } from '@/lib/HomeIcon'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

const ServicePage = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const navigate = useNavigate()
  const location = useLocation()

  return (
    <MainLayout>
      <Flex flexDirection="column">
        <Flex
          className="product-inquiry-bg"
          h={isMobile ? '270px' : '453px'}
          backgroundImage="/menu/service.jpeg"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
          flexDirection="column"
          justifyContent="center"
          alignItems="stretch"
          pt={20}
          pl={isMobile ? 2 : '10%'}
          w="full"
        >
          <Flex
            h="full"
            flexDirection="column"
            justifyContent="space-between"
            zIndex={4}
          >
            <Flex
              flexDirection="column"
              gap={2}
              pb={2}
              h="full"
              justifyContent="center"
              px={isMobile ? 4 : 0}
            >
              <Text
                fontSize={isMobile ? '12px' : '18px'}
                color="#CCCDCE"
                fontWeight={600}
              >
                SERVICE
              </Text>
              {/* todo: 정가운데 정렬 */}
              <Heading
                color="white"
                fontSize={isMobile ? '30px' : '50px'}
                fontWeight="600"
              >
                고객센터
              </Heading>
            </Flex>
            <Flex
              h={isMobile ? '50px' : '67px'}
              alignItems="center"
              justifyContent="space-between"
              w="full"
            >
              <Box />
              <Flex bgColor="white" alignItems="center">
                <Button
                  w={isMobile ? '50px' : '67px'}
                  h={isMobile ? '50px' : '67px'}
                  bgColor="#004A8D"
                  borderRadius={0}
                  onClick={() => navigate('#')}
                >
                  <HomeIcon boxSize={6} />
                </Button>
                <Flex px={8} gap={8} w={isMobile ? 'full' : '483px'}>
                  <Button
                    variant="unstyled"
                    onClick={() => navigate('/service/notice')}
                  >
                    <Text
                      textDecor={
                        location.pathname.includes('/notice') ||
                        location.pathname === '/service'
                          ? 'underline'
                          : 'none'
                      }
                      color={
                        location.pathname.includes('/notice') ||
                        location.pathname === '/service'
                          ? '#004A8D'
                          : '#7F8589'
                      }
                      fontWeight={600}
                    >
                      공지사항
                    </Text>
                  </Button>
                  <Button
                    variant="unstyled"
                    onClick={() => navigate('/service/register')}
                  >
                    <Text
                      textDecor={
                        location.pathname.includes('/register')
                          ? 'underline'
                          : 'none'
                      }
                      color={
                        location.pathname.includes('/register')
                          ? '#004A8D'
                          : '#7F8589'
                      }
                      fontWeight={600}
                    >
                      상품등록신청
                    </Text>
                  </Button>
                  <Button
                    variant="unstyled"
                    onClick={() => navigate('/service/contact')}
                  >
                    <Text
                      textDecor={
                        location.pathname.includes('/contact')
                          ? 'underline'
                          : 'none'
                      }
                      color={
                        location.pathname.includes('/contact')
                          ? '#004A8D'
                          : '#7F8589'
                      }
                      fontWeight={600}
                    >
                      문의하기
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Outlet />
      </Flex>
    </MainLayout>
  )
}

export default ServicePage
