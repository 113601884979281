import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Divider,
  ModalCloseButton,
  useMediaQuery,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react'
import ResetPassword from './ResetPassword'
import {
  useReceiveVerifyCode,
  useSendFindCode,
  VerifyRequest,
} from '@/API/userAPI'
import { useEffect, useState } from 'react'
import { checkEmailValidation, ToastUtil } from '@/util/util'

const FindPassword = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isResetPasswordOpen,
    onOpen: onResetPasswordOpen,
    onClose: onResetPasswordClose,
  } = useDisclosure()

  const { mutate: sendFindCode } = useSendFindCode()
  const { mutate: receiveVerifyCode, isSuccess: isVerifyCodeSuccess } =
    useReceiveVerifyCode()

  const [emailAddress, setEmailAddress] = useState<string>('')
  const [verifyCode, setVerifyCode] = useState<string>('')
  const [verifyParams, setVerifyParams] = useState<Partial<VerifyRequest>>({})

  const functionWithEmailValidation = (paramFn: () => void) => {
    const isValidEmail = checkEmailValidation(emailAddress)
    if (!isValidEmail) {
      toast(
        ToastUtil(
          '이메일 주소 형식 오류',
          'warning',
          '올바른 이메일 주소를 입력해주세요.',
        ) as UseToastOptions,
      )
      return
    } else {
      paramFn()
    }
  }

  const sendFindCodeAfterCheckEmail = () => {
    const verifyParams = { email: emailAddress }
    functionWithEmailValidation(() => {
      toast(
        ToastUtil(
          '인증번호 전송 완료',
          'success',
          '인증번호를 이메일로 전송했습니다.',
        ) as UseToastOptions,
      )
      sendFindCode(verifyParams)
    })
    
  }

  const verifyReceivedCode = () => {
    const verifyParams = { email: emailAddress, code: verifyCode }
    functionWithEmailValidation(() => receiveVerifyCode(verifyParams))
  }

  useEffect(() => {
    if (isVerifyCodeSuccess) {
      setVerifyParams({ email: emailAddress, code: verifyCode })
    }
  }, [isVerifyCodeSuccess])

  return (
    <>
      <Button variant="unstyled" onClick={onOpen}>
        <Text color="#4A637B" fontWeight={600}>
          비밀번호 찾기
        </Text>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent p={10} borderRadius={0}>
          <ModalHeader px={isMobile ? 0 : 4}>
            <Flex py={4} fontSize={isMobile ? 'md' : '18px'} fontWeight={700}>
              비밀번호 찾기
            </Flex>
            <Divider border="1px solid black" opacity={1} />
          </ModalHeader>
          <ModalCloseButton fontSize="18px" />
          <ModalBody py={isMobile ? 4 : 8} px={isMobile ? 0 : 4}>
            <chakra.form
            //   onSubmit={handleSubmit}
            >
              <FormControl>
                <Stack spacing={6}>
                  <Flex flexDirection="column" gap={2}>
                    <FormLabel
                      fontWeight={500}
                      fontSize={isMobile ? 'md' : '18px'}
                    >
                      아이디(E-mail)
                    </FormLabel>

                    <Flex flexDirection="column" gap={4}>
                      <Flex alignItems="center" gap={3}>
                        <>
                          <Input
                            w="462px"
                            h={isMobile ? '50px' : '60px'}
                            bgColor="#F7F7F7"
                            borderRadius={0}
                            border={0}
                            value={emailAddress || ''}
                            onChange={(e) => setEmailAddress(e.target.value)}
                          />
                          <Button
                            border="1.5px solid #787E88"
                            borderRadius={0}
                            color="#787E88"
                            w="175px"
                            h={isMobile ? '50px' : '60px'}
                            fontSize={isMobile ? '12px' : 'md'}
                            onClick={sendFindCodeAfterCheckEmail}
                          >
                            인증 코드 전송
                          </Button>
                        </>
                      </Flex>
                    </Flex>
                  </Flex>

                  <Flex flexDirection="column" gap={2}>
                    <FormLabel
                      fontWeight={500}
                      fontSize={isMobile ? 'md' : '18px'}
                    >
                      인증번호
                    </FormLabel>
                    <Flex flexDirection="column" gap={2}>
                      <Flex alignItems="center" gap={3}>
                        <>
                          <Input
                            w="462px"
                            h={isMobile ? '50px' : '60px'}
                            bgColor="#F7F7F7"
                            borderRadius={0}
                            border={0}
                            value={verifyCode || ''}
                            onChange={(e) => setVerifyCode(e.target.value)}
                          />
                          <Button
                            border="1.5px solid #787E88"
                            borderRadius={0}
                            color="#787E88"
                            w="175px"
                            h={isMobile ? '50px' : '60px'}
                            fontSize={isMobile ? '12px' : 'md'}
                            onClick={verifyReceivedCode}
                          >
                            인증 코드 확인
                          </Button>
                        </>
                      </Flex>
                    </Flex>
                  </Flex>
                </Stack>
              </FormControl>
            </chakra.form>
          </ModalBody>
          <ModalFooter px={isMobile ? 0 : 4}>
            {isVerifyCodeSuccess && (
              <Flex w="full" justifyContent="center">
                <Button
                  type="submit"
                  w={isMobile ? 'full' : '272px'}
                  h={isMobile ? '50px' : '60px'}
                  bgColor="#0D3A65"
                  borderRadius={0}
                  my={4}
                  onClick={onResetPasswordOpen}
                >
                  다음
                </Button>
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
      <ResetPassword
        isOpen={isResetPasswordOpen}
        onOpen={onResetPasswordOpen}
        onClose={onResetPasswordClose}
        onCloseFindPasswordModal={onClose}
        verifyParams={verifyParams}
      />
    </>
  )
}

export default FindPassword
