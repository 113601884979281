import {
  CategoryList,
  ProductRequest,
  useGetCategoryList,
} from '@/API/productAPI'
import {
  AddIcon,
  CheckIcon,
  ChevronRightIcon,
  MinusIcon,
} from '@chakra-ui/icons'
import {
  Flex,
  Divider,
  Text,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  useMediaQuery,
  Box,
} from '@chakra-ui/react'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface AccordionCategoryProps {
  isSearchPage?: boolean
  URLParams?: Partial<ProductRequest>
  setURLParams?: React.Dispatch<React.SetStateAction<Partial<ProductRequest>>>
}

const AccordionCategory = ({
  isSearchPage,
  URLParams,
  setURLParams,
}: AccordionCategoryProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const [isShowAllCategories, setIsShowAllCategories] = useState<boolean>(false)
  const [categoryList, setCategoryList] = useState<CategoryList[] | null>(null)
  const { data: categoryListData } = useGetCategoryList()

  const navigate = useNavigate()
  const location = useLocation()

  const handleAccordionClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ): void => {
    if (
      event.target instanceof SVGElement &&
      event.target.classList.contains('chakra-icon')
    ) {
      return
    }
    event.preventDefault()
    event.stopPropagation()
  }

  const navigateAfterSetURLParams = (categoryId?: number) => {
    if (setURLParams) {
      // ALL 선택 -> 카테고리 id가 고의적으로 undefined가 주입되도록 함 (전체 제품 출력)
      setURLParams({
        ...URLParams,
        category: categoryId?.toString(),
      })
      navigate(`/product/list`)
    }
  }

  useEffect(() => {
    const productNumber = location.pathname.split('/product/')[1]
    const isNotSearchPage = !location.pathname.includes('/product/search')
    if ((!!!productNumber || isNotSearchPage) && isMobile) {
      setIsShowAllCategories(true)
    } else {
      setIsShowAllCategories(false)
    }
  }, [location, isMobile])

  useLayoutEffect(() => {
    if (categoryListData) {
      if (isShowAllCategories) {
        setCategoryList([{ name: 'All Categories', id: undefined }])
      } else {
        setCategoryList(Array.from([{ name: 'All' }]).concat(categoryListData))
      }
    }
  }, [categoryListData, isShowAllCategories])

  return (
    <Accordion allowMultiple maxW="100%" py={isMobile ? 4 : 0}>
      <Flex
        w={isMobile ? 'full' : '220px'}
        flexDirection={isMobile ? 'row' : 'column'}
        gap={isMobile ? 4 : 2}
        overflowX="auto"
        whiteSpace={isMobile ? 'nowrap' : 'normal'}
        // h={isMobile ? '40px' : 'auto'}
        alignItems="center"
        mt={isShowAllCategories ? 4 : 0}
      >
        {categoryList?.map((category, idx) => {
          return (
            <AccordionItem
              w={isShowAllCategories ? '100%' : isMobile ? 'auto' : '220px'}
              minW={isMobile ? 'auto' : '220px'}
              key={idx}
              flexDirection="column"
              border="0"
              flex={isMobile ? 'none' : 'auto'}
            >
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      display="flex"
                      justifyContent={'space-between'}
                      textAlign={'left'}
                      alignItems="center"
                      paddingInline={0}
                      py={1}
                      onClick={handleAccordionClick}
                      _hover={{
                        backgroundColor: isShowAllCategories
                          ? 'white'
                          : 'blackalpha.50',
                      }}
                    >
                      <Text
                        fontWeight="bold"
                        fontSize="16px"
                        w="full"
                        py={isMobile ? 0 : 2}
                        pb={isMobile ? 1 : 2}
                        cursor="pointer"
                        onClick={() => navigateAfterSetURLParams(category.id)}
                        // todo: searchParam 을 사용하지 않을 경우 방식 변경
                      >
                        {category.name}
                      </Text>
                      {idx === 0 && !isMobile ? (
                        // todo: 추후 선택된 카테고리에 체크 아이콘 표시
                        <CheckIcon boxSize={3} />
                      ) : (
                        <Flex justifyContent="center">
                          {!isSearchPage &&
                            (isExpanded && !isMobile ? (
                              <MinusIcon fontSize="12px" color="#7F8589" />
                            ) : (
                              !isMobile && (
                                <AddIcon fontSize="12px" color="#0e3a60" />
                              )
                            ))}
                        </Flex>
                      )}
                      {isShowAllCategories && <ChevronRightIcon boxSize={7} />}
                    </AccordionButton>
                  </h2>
                  {idx > 0 && !isSearchPage && (
                    <AccordionPanel
                      display="flex"
                      flexDirection="column"
                      gap={2}
                    >
                      {category?.sub_categories?.map((subCategory, i) => {
                        return (
                          <Button
                            key={i}
                            display="flex"
                            justifyContent="flex-start"
                            variant="unstyled"
                            maxH="20px"
                            onClick={() =>
                              navigateAfterSetURLParams(subCategory.id)
                            }
                          >
                            <Text
                              fontSize="14px"
                              wordBreak="keep-all"
                              color="#7F8589"
                              fontWeight="700"
                            >
                              {'- ' + subCategory.name}
                            </Text>
                          </Button>
                        )
                      })}
                    </AccordionPanel>
                  )}
                  <Divider
                    borderBottomWidth={
                      idx === 0 && !isShowAllCategories ? '2px' : '0.5px'
                    }
                    borderColor={
                      idx === 0 && !isShowAllCategories ? 'black' : '#EDEDED'
                    }
                    opacity={1}
                    pb={isMobile ? 2 : 0}
                  />
                </>
              )}
            </AccordionItem>
          )
        })}
      </Flex>
    </Accordion>
  )
}

export default AccordionCategory
