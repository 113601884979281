interface homeTabContent {
  title: string
  subTitle: string
}

const replaceDots = (title: string, isMobile: boolean) => {
  return isMobile ? title : title.replaceAll('.', '')
}

export const homeTabContents = (isMobile: boolean): homeTabContent[] => {
  return [
    {
      title: replaceDots(
        `글로벌 시장 진출을 쉽게.
        함께 성장하는. 최고의 수출 파트너`,
        isMobile,
      ),
      subTitle: replaceDots(
        `Solving Export Challenges,. Driving Expansion`,
        isMobile,
      ),
    },
    {
      title: replaceDots(
        `글로벌 시장 진출을 쉽게.
      함께 성장하는. 최고의 수출 파트너`,
        isMobile,
      ),
      subTitle: replaceDots(
        `Solving Export Challenges,. Driving Expansion`,
        isMobile,
      ),
    },
    {
      title: replaceDots(
        `국내 기업의 수출을.
        성장과 혁신으로. 이끄는 엑스고`,
        isMobile,
      ),
      subTitle: replaceDots(
        `Empowering Domestic Enterprise’s Exports. Through Growth and Innovation`,
        isMobile,
      ),
    },
  ]
}

export const serviceTabContents = () => {
  return [
    {
      title: '수출 상품 등록',
      content: `상품 등록만으로도 전세계 공급 업체에 국내 기업의 수출 상품이
      소개됩니다. 실시간 검색을 통해 적극적인 구매 유도와 문의가
      즉시 발생합니다.`,
    },
    {
      title: '바이어 소개 및 제품 홍보',
      content: `단일 제품이 아닌 전체 제품 라인을 홍보하기 때문에 수많은
      바이어들과 유기적인 네트워크를 가지고 있습니다. 각 제품의
      분야에 맞게 전문적인 마케팅을 지원합니다.`,
    },
    {
      title: '물류비 견적 문의',
      content: `바이어와의 수출 성사 시 화주(수출자) 및 글로벌
      운송사(포워더)의 물류비 견적 진행을 통해 논스톱으로 비즈니스를
      진행할 수 있습니다.`,
    },
    {
      title: '수출/물류 자료실',
      content: `수출 업무 전반에 관한 정보와 이후 진행되는
      물류 시스템에 대한 자료를 공유하여 
      수출자의 애로사항을 최대한 줄이고 비즈니스를 수행할 수 있도록 합니다.`,
    },
  ]
}
