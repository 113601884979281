import { AxiosError } from 'axios'
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import axiosInstance from '@/API/http'
import { ErrorResponseData, partialData } from '@/util/util'

export interface LoginRequest {
  email: string
  password: string
}

export interface VerifyRequest {
  email: string
  code?: string
  password?: string
}

export interface UserState {
  email: string
  password?: string
  confirm_password?: string
  fullname: string
  phone: string
  address: string
  address_detail: string
  company_name: string
}

// 회원가입 시 체크
export const isAllStateParams = (
  params: Partial<UserState>,
): params is UserState => {
  return (
    typeof params.email === 'string' &&
    typeof params.password === 'string' &&
    typeof params.confirm_password === 'string' &&
    typeof params.fullname === 'string' &&
    typeof params.phone === 'string' &&
    typeof params.address === 'string' &&
    typeof params.address_detail === 'string' &&
    typeof params.company_name === 'string'
  )
}

// 로그인 시 체크
export const isAllLoginParams = (
  params: Partial<LoginRequest>,
): params is LoginRequest => {
  return typeof params.email === 'string' && typeof params.password === 'string'
}

const useLoginUser = () => {
  return useMutation<UserState, AxiosError<ErrorResponseData>, LoginRequest>({
    mutationFn: async (params: LoginRequest) => {
      const response = await axiosInstance.post('/user/login/', params)
      return response.data
    },
    onSuccess: (res) => {
      return res
    },
  })
}

const useLogoutUser = () => {
  return useMutation<void, AxiosError<ErrorResponseData>, void>({
    mutationFn: async () => {
      await axiosInstance.delete('/user/logout/')
    },
  })
}

const useGetUser = (
  enabled: boolean,
): UseQueryResult<UserState, AxiosError<ErrorResponseData>> => {
  return useQuery({
    queryKey: [],
    queryFn: async () => {
      const response = await axiosInstance.get(`/user/`)
      return response.data
    },
    enabled: enabled,
  })
}

const getUserList = async ({
  email,
  fullname,
}: UserState): Promise<UserState[]> => {
  const response = await axiosInstance.get(
    `/user/list/?email=${email}&fullname=${fullname}`,
  )
  return response.data
}

const useSignUpUser = () => {
  return useMutation<UserState, AxiosError<ErrorResponseData>, UserState>({
    mutationFn: async (params: UserState) => {
      const response = await axiosInstance.post(`/user/`, params)
      return response.data
    },
    onSuccess: (res) => {
      return res
    },
  })
}

const usePutUser = () => {
  return useMutation<UserState, AxiosError<ErrorResponseData>, UserState>({
    mutationFn: async (userData: Partial<UserState>) => {
      const response = await axiosInstance.put(`/user/`, partialData(userData))
      return response.data
    },
    onSuccess: (res) => {
      return res
    },
  })
}

const useDeleteUser = () => {
  return useMutation<void, AxiosError<ErrorResponseData>, void>({
    mutationFn: async () => {
      await axiosInstance.delete(`/user/`)
    },
  })
}

const useSendFindCode = () => {
  return useMutation<void, AxiosError<ErrorResponseData>, VerifyRequest>({
    mutationFn: async ({ email }: VerifyRequest) => {
      const response = await axiosInstance.post(`/user/send_find_code/`, {
        email,
      })
      // alert(response.data.message as string)
    },
    onSuccess: (res) => {
      return res
    },
  })
}

const useReceiveVerifyCode = () => {
  return useMutation<void, AxiosError<ErrorResponseData>, VerifyRequest>({
    mutationFn: async ({ email, code }: VerifyRequest) => {
      const response = await axiosInstance.post(`/user/verify/`, {
        email,
        code,
      })
      alert(response.data.message as string)
    },
    onSuccess: (res) => {
      return res
    },
  })
}

const useResetPassword = () => {
  return useMutation<void, AxiosError<ErrorResponseData>, VerifyRequest>({
    mutationFn: async ({ email, code, password }: VerifyRequest) => {
      const response = await axiosInstance.post(`/user/reset_password`, {
        email,
        code,
        password,
      })
      alert(response.data.message as string)
    },
    onSuccess: (res) => {
      return res
    },
  })
}

export {
  useLoginUser,
  useLogoutUser,
  useGetUser,
  getUserList,
  useSignUpUser,
  usePutUser,
  useDeleteUser,
  useSendFindCode,
  useReceiveVerifyCode,
  useResetPassword,
}
