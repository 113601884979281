import { Flex } from '@chakra-ui/react'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { ScrollRestoration } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useGetUser } from '@/API/userAPI'
import Cookies from 'js-cookie'

const MainLayout = ({ children }: React.PropsWithChildren) => {
  const [isGetUserData, setIsGetUserData] = useState<boolean>(false)
  const { data: loginUserData, isSuccess } = useGetUser(isGetUserData)

  useEffect(() => {
    // 로컬 스토리지에 유저 이름 정보가 아직 담겨있을 경우, 유저 정보를 받아오는 API 연결
    const exgoUserName = localStorage.getItem('EXGO_USER_NAME')
    if (exgoUserName) {
      setIsGetUserData(true)
    } else {
      setIsGetUserData(false)
      localStorage?.removeItem('EXGO_USER_NAME')
    }
  }, [])

  // useEffect(() => {
  //   // 유저 정보 API 연결에 실패했을 경우 (session && csrftoken 체크 실패)
  //   if (!loginUserData || !isSuccess) {
  //     // 저장되어 있는 정보가 있을 경우 삭제
  //     localStorage.removeItem('EXGO_USER_NAME')
  //     Cookies.remove('csrftoken')
  //   }
  // }, [loginUserData, isSuccess])

  return (
    <Flex w="full" h="auto" flexDirection="column" overflow="hidden">
      <Header />
      {children}
      <ScrollRestoration />
      <Footer />
    </Flex>
  )
}

export default MainLayout
