import { ProductResponse } from '@/API/productAPI'
import { Flex, Box, Text, Grid } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

interface ProductGridProps {
  productList: ProductResponse[] | null
  isLoading: boolean
  isSearchPage: boolean
}

const ProductGrid = ({
  productList,
  isLoading,
  isSearchPage,
}: ProductGridProps) => {
  const navigate = useNavigate()

  const innerProductFrame = (product: ProductResponse) => {
    const thumbnailImageURL = product.thumbnail
      ? `${product.thumbnail}`
      : '/example/iphone.jpeg'
    return (
      <Flex
        maxW="278px"
        maxH="278px"
        border="1px solid #EDEDED"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={() => navigate(`/product/${product.id}`)}
      >
        <Box
          w="100%"
          aspectRatio={270 / 226}
          backgroundImage={thumbnailImageURL}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="contain"
        />
      </Flex>
    )
  }

  return productList && productList.length > 0 ? (
    <Grid
      templateColumns={{
        base: 'repeat(2, 1fr)',
        md: 'repeat(2, 1fr)',
        lg: 'repeat(4, 1fr)',
      }}
      pb={4}
      pt={8}
      gap={4}
      overflow="auto"
    >
      {productList?.map((product, idx) => {
        return (
          <Flex flexDirection="column" key={idx}>
            {isSearchPage ? (
              <Box pos="relative" w="100%" h="auto">
                <Flex
                  pos="absolute"
                  bgColor="#004A8D"
                  w="37.65px"
                  h="37.65px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color="white" fontWeight={600}>
                    {idx + 1}
                  </Text>
                </Flex>

                {innerProductFrame(product)}
              </Box>
            ) : (
              innerProductFrame(product)
            )}
            <Flex flexDirection="column" pt={2} pb={8} gap={1}>
              <Text color="#7F8589" fontSize="14px" fontWeight="600">
                {`${product.company}`}
              </Text>
              <Text fontWeight="600">{`${product.name}`}</Text>
            </Flex>
          </Flex>
        )
      })}
    </Grid>
  ) : !isLoading ? (
    <Flex w="full" h="full" alignItems="center" justifyContent="center">
      제품이 없습니다.
    </Flex>
  ) : (
    <></>
  )
}

export default ProductGrid
