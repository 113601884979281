import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  UseToastOptions,
  chakra,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import MainLayout from '@/components/MainLayout'
import {
  isAllStateParams,
  useDeleteUser,
  useGetUser,
  useLogoutUser,
  UserState,
  useSignUpUser,
} from '@/API/userAPI'
import { checkEmailValidation, ToastUtil } from '@/util/util'
import { useDaumPostcodePopup } from 'react-daum-postcode'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserInfoConsent from '@/components/user/UserInfoConsent'
import PhoneInput from '@/components/form/PhoneInput'
import { HiEye } from 'react-icons/hi2'
import { HiEyeOff } from 'react-icons/hi'

const MyPage = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const [isConsent, setIsConsent] = useState<boolean>(false)
  const [userData, setUserData] = useState<UserState | null>(null)

  const { data: user, refetch } = useGetUser(true)

  const toast = useToast()
  const navigate = useNavigate()
  const open = useDaumPostcodePopup(
    'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  )
  const { mutate: signUpUser, isSuccess: isSignUpUserSuccess } = useSignUpUser()
  const { mutate: deleteUser, isSuccess: isDeleteUserSuccess } = useDeleteUser()
  const { mutate: logoutUser, isSuccess: isLogoutUserSuccess } = useLogoutUser()
  const [address, setAddress] = useState<string>('')
  const [addressDetail, setAddressDetail] = useState<string>('')
  const [postCode, setPostCode] = useState<string>('')
  
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(false)

  const toggleDelete = () => {
    deleteUser()
  }

  const toggleLogout = () => {
    logoutUser()
  }

  useLayoutEffect(() => {
    if (user) {
      setUserData(user)
    }
  }, [user])

  const handleComplete = (data: any) => {
    let extraAddress = ''

    setAddress(data.roadAddress)
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
      setAddressDetail(extraAddress !== '' ? ` (${extraAddress})` : '')
    }
    setPostCode(data.zonecode)
  }

  const handleClick = () => {
    open({ onComplete: handleComplete })
  }

  const toggleSignUpUser = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const target = e.target as HTMLFormElement
    const inputs = Array.from(target.elements) as Array<HTMLInputElement>
    const params: Partial<UserState> = {}

    inputs.map((input) => {
      if (input.tagName === 'INPUT' && !input.id.includes('field')) {
        const id = input.id as keyof UserState
        params[id] = input.value
      }
    })

    // TODO: 비밀번호 show / setShow
    // TODO2: 휴대폰 번호 '-' string 자동 replace

    // 모든 프로퍼티 존재 여부 체크
    if (isAllStateParams(params)) {
      const isVaildEmail = checkEmailValidation(params.email)
      const isCorrectPassword =
        params.password && params.password === params.confirm_password
      const isValidAddress = !!params.address && !!postCode
      if (isVaildEmail && isCorrectPassword && isValidAddress && isConsent) {
        const validatePhone = params.phone.replace(/-/gi, '')
        signUpUser({
          ...params,
          phone: validatePhone
        } as UserState)
      } else if (!isVaildEmail) {
        toast(
          ToastUtil(
            '이메일 주소 형식 오류',
            'warning',
            '올바른 이메일 주소를 입력해주세요.',
          ) as UseToastOptions,
        )
      } else if (!isCorrectPassword) {
        toast(
          ToastUtil(
            '비밀번호 불일치 오류',
            'warning',
            '인증이 완료 되지 않았습니다. 인증 절차를 다시 진행해 주세요.',
          ) as UseToastOptions,
        )
      } else if (!isValidAddress) {
        toast(
          ToastUtil(
            '주소값 입력 필요',
            'warning',
            '주소가 올바르게 입력되지 않았습니다. 다시 입력해 주세요.',
          ) as UseToastOptions,
        )
      } else if (!isConsent) {
        toast(
          ToastUtil(
            '개인정보 이용 동의 필요',
            'warning',
            '개인정보 수집 및 이용에 대한 동의가 필요합니다.',
          ) as UseToastOptions,
        )
      }
    }
  }

  useEffect(() => {
    if (isSignUpUserSuccess) {
      toast(
        ToastUtil(
          '회원 가입 완료',
          'success',
          '회원 가입이 완료 되었습니다. 로그인 화면으로 이동합니다.',
        ) as UseToastOptions,
      )
      navigate('/user/login')
    }
  }, [isSignUpUserSuccess])

  useEffect(() => {
    if (isLogoutUserSuccess) {
      toast(
        ToastUtil(
          '로그아웃 성공',
          'success',
          '메인 화면으로 돌아갑니다.',
        ) as UseToastOptions,
      )
      navigate('/')
    }
  }, [isLogoutUserSuccess])

  useEffect(() => {
    if (isDeleteUserSuccess) {
      toast(
        ToastUtil(
          '회원탈퇴 완료',
          'success',
          '그동안 엑스고 페이지를 이용해주셔서 감사합니다.',
        ) as UseToastOptions,
      )
      navigate('/')
    }
  }, [isDeleteUserSuccess])

  return (
    <MainLayout>
      <Flex
        minH="700px"
        alignItems="center"
        flexDirection="column"
        py={isMobile ? 8 : 16}
        px={isMobile ? 6 : '10%'}
      >
        <Flex
          my={isMobile ? '60px' : '80px'}
          alignItems="center"
          justifyContent="center"
        >
          <Heading color="#004A8D" fontSize={isMobile ? '36px' : '50px'}>
            My Page
          </Heading>
        </Flex>
        <chakra.form onSubmit={toggleSignUpUser}>
          <FormControl
            display="flex"
            flexDirection="column"
            flexWrap="wrap"
            gap={isMobile ? 4 : 8}
            justifyContent="center"
            alignItems="center"
            w={isMobile ? 'full' : '706px'}
          >
            <Flex
              flex={1}
              w="full"
              flexDirection="column"
              gap={2}
              id={`input-group`}
            >
              <FormLabel fontSize={isMobile ? '16px' : '18px'} fontWeight={500}>
                아이디(E-mail)
              </FormLabel>
              <Input
                id="email"
                h={isMobile ? '50px' : '60px'}
                bgColor="#F7F7F7"
                borderRadius={0}
                border="none"
                defaultValue={userData?.email}
                isDisabled
              />
            </Flex>
            <Flex
              flex={1}
              w="full"
              flexDirection="column"
              gap={2}
              id={`input-group`}
            >
              <FormLabel fontSize={isMobile ? '16px' : '18px'} fontWeight={500}>
                비밀번호
              </FormLabel>
              
              <InputGroup>
                <Input
                  id="password"
                  h={isMobile ? '50px' : '60px'}
                  bgColor="#F7F7F7"
                  borderRadius={0}
                  border="none"
                  type={showPassword ? 'text' : 'password'}
                />
                <InputRightElement height="100%">
                  <IconButton
                    size="lg"
                    bg="transparent !important"
                    variant="ghost"
                    aria-label={showPassword ? 'Mask password' : 'Reveal password'}
                    icon={showPassword ? <HiEyeOff /> : <HiEye />}
                    boxSize="7"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
            <Flex
              flex={1}
              w="full"
              flexDirection="column"
              gap={2}
              id={`input-group`}
            >
              <FormLabel fontSize={isMobile ? '16px' : '18px'} fontWeight={500}>
                비밀번호 확인
              </FormLabel>
              <InputGroup>
                <Input
                  id="confirm_password"
                  h={isMobile ? '50px' : '60px'}
                  bgColor="#F7F7F7"
                  borderRadius={0}
                  border="none"
                  type={showPasswordConfirm ? 'text' : 'password'}
                />
                <InputRightElement height="100%">
                  <IconButton
                    size="lg"
                    bg="transparent !important"
                    variant="ghost"
                    aria-label={showPasswordConfirm ? 'Mask password' : 'Reveal password'}
                    icon={showPasswordConfirm ? <HiEyeOff /> : <HiEye />}
                    boxSize="7"
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                  />
                </InputRightElement>
              </InputGroup>
            </Flex>
            <Flex
              flex={1}
              w="full"
              flexDirection="column"
              gap={2}
              id={`input-group`}
            >
              <FormLabel fontSize={isMobile ? '16px' : '18px'} fontWeight={500}>
                이름
              </FormLabel>
              <Input
                id="fullname"
                h={isMobile ? '50px' : '60px'}
                bgColor="#F7F7F7"
                borderRadius={0}
                border="none"
                defaultValue={userData?.fullname}
                isDisabled
              />
            </Flex>
            <Flex
              flex={1}
              w="full"
              flexDirection="column"
              gap={2}
              id={`input-group`}
            >
              <FormLabel fontSize={isMobile ? '16px' : '18px'} fontWeight={500}>
                전화번호
              </FormLabel>
              <PhoneInput
                isMobile={isMobile}
                defaultValue={userData?.phone}
              />
            </Flex>
            <Flex
              flex={1}
              w="full"
              flexDirection="column"
              gap={4}
              id={`input-group`}
            >
              <FormLabel fontSize={isMobile ? '16px' : '18px'} fontWeight={500}>
                주소
              </FormLabel>
              <Flex gap={4}>
                <Input
                  h={isMobile ? '50px' : '60px'}
                  bgColor="#F7F7F7"
                  borderRadius={0}
                  border="none"
                  value={postCode}
                  onChange={(e) => setAddress(e.target.value)}
                  isDisabled
                />
                <Button
                  w="223px"
                  h={isMobile ? '50px' : '60px'}
                  border="1.5px solid #787E88"
                  borderRadius="0"
                  color="#787E88"
                  onClick={handleClick}
                >
                  우편번호 검색
                </Button>
              </Flex>
              <Input
                id="address"
                h={isMobile ? '50px' : '60px'}
                bgColor="#F7F7F7"
                borderRadius={0}
                border="none"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                defaultValue={userData?.address}
                isDisabled
              />
              <Input
                id="address_detail"
                h={isMobile ? '50px' : '60px'}
                bgColor="#F7F7F7"
                borderRadius={0}
                border="none"
                value={addressDetail}
                defaultValue={userData?.address_detail}
                onChange={(e) => setAddressDetail(e.target.value)}
              />
            </Flex>
            <Flex
              flex={1}
              w="full"
              flexDirection="column"
              gap={2}
              id={`input-group`}
            >
              <FormLabel fontSize={isMobile ? '16px' : '18px'} fontWeight={500}>
                회사명
              </FormLabel>
              <Input
                id="company_name"
                h={isMobile ? '50px' : '60px'}
                bgColor="#F7F7F7"
                borderRadius={0}
                border="none"
                defaultValue={userData?.company_name}
              />
            </Flex>
            <UserInfoConsent
              isConsent={isConsent}
              setIsConsent={setIsConsent}
            />
            <Flex w="full" justifyContent="center" mb={4}>
              <Button
                type="submit"
                w="full"
                h={isMobile ? '50px' : '60px'}
                bgColor="#0D3A65"
                borderRadius={0}
              >
                회원 정보 수정하기
              </Button>
            </Flex>
            <Flex w="full" justifyContent="space-between" mb={4}>
              <Text
                fontSize={isMobile ? 'md' : '18px'}
                fontWeight={500}
                color="#787E88"
                cursor="pointer"
                onClick={toggleDelete}
              >
                회원탈퇴
              </Text>
              <Text
                fontSize={isMobile ? 'md' : '18px'}
                fontWeight={600}
                cursor="pointer"
                onClick={toggleLogout}
              >
                로그아웃
              </Text>
            </Flex>
          </FormControl>
        </chakra.form>
      </Flex>
    </MainLayout>
  )
}

export default MyPage
