import { Input } from "@chakra-ui/react"
import { useEffect, useState } from "react";

interface PhoneInputProps {
  isMobile: boolean;
  defaultValue?: string | undefined;
  id?: string | undefined;
}


const PhoneInput: React.FC<PhoneInputProps> = ({ 
  isMobile,
  defaultValue,
  id="phone"
}) => {
  const [phone, setPhone] = useState<string>("")

  // 숫자만 처리
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9]/g, "") // 숫자 외 제거
    setPhone(value)
  }

  // 포커스 아웃 시 하이픈 추가
  const handleBlur = () => {
    if (phone.length === 11) {
      const formattedPhone = phone.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
      setPhone(formattedPhone)
    }
  }

  // 포커스 시 하이픈 제거
  const handleFocus = () => {
    const numericPhone = phone.replace(/-/g, "") // 하이픈 제거
    setPhone(numericPhone)
  }


  useEffect(() => {
    if (defaultValue) {
      setPhone(defaultValue.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3"))
    }
  }, [defaultValue])



  return (
    <Input
      id={id}
      value={phone}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      h={isMobile ? '50px' : '60px'}
      bgColor="#F7F7F7"
      borderRadius={0}
      border="none"
      placeholder="전화번호"
    />
  )
}

export default PhoneInput