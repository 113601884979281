import {
  ChevronDownIcon,
  ChevronRightIcon,
  HamburgerIcon,
  SearchIcon,
} from '@chakra-ui/icons'
import {
  Button,
  chakra,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Image,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  useToast,
  UseToastOptions,
  Text,
  useMediaQuery,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FaRegUser } from 'react-icons/fa6'
import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useLogoutUser } from '@/API/userAPI'
import { selector, ToastUtil } from '@/util/util'
import useKeywordStore from '@/store/KeywordStore'
import { useShallow } from 'zustand/react/shallow'

const Header = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()
  const UserIcon = chakra(FaRegUser)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false)
  const [isScroll, setIsScroll] = useState<boolean>(false)
  const [isUserPage, setIsUserPage] = useState<boolean>(false)
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
  const [userName, setUserName] = useState<string>('')

  const [searchKeyword, setSearchKeyword] = useState<string>('')

  const { mutate: logoutUser, isSuccess: isLogoutUserSuccess } = useLogoutUser()
  const { setKeyword } = useKeywordStore(useShallow(selector))

  const toggleLogout = () => {
    logoutUser()
  }

  const toggleSearch = () => {
    setKeyword(searchKeyword)
    setSearchKeyword('')
    setIsSearchOpen(false)
    navigate(`/product/list`)
  }

  // scroll handle logic
  const handleScroll = () => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop
    setIsScroll(scrollPosition > (isMobile ? 80 : 100))
  }

  useEffect(() => {
    // csrfToken -> isLoggedIn -> 아바타 클릭 시 로직 변경
    const csrfToken = Cookies.get('csrftoken')
    setIsLoggedIn(!!csrfToken)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    if (location.pathname) {
      const value = (
        location.pathname.includes('/user') ||
        location.pathname.includes('/policy/private') ||
        location.pathname.includes('/policy/service')
      )
      setIsUserPage(value)
    }
  }, [location])

  useEffect(() => {
    if (isLogoutUserSuccess) {
      localStorage?.removeItem('EXGO_USER_NAME')
      setUserName('')
      setIsLoggedIn(false)
      Cookies.remove('csrftoken')
      toast(
        ToastUtil(
          '로그아웃 성공',
          'success',
          '메인 화면으로 돌아갑니다.',
        ) as UseToastOptions,
      )
      navigate('/')
    }
  }, [isLogoutUserSuccess])

  useEffect(() => {
    const exgoUserName = localStorage?.getItem('EXGO_USER_NAME')
    if (exgoUserName) {
      setUserName(exgoUserName)
    }
  }, [localStorage])

  const returnMobileDrawerItems = () => {
    // const eachMenu = ['category', 'product', 'service', 'about', 'estimate']
    // todo: category 메뉴가 들어오려면, 카테고리를 설정한 상태에서 페이지를 넘겨야 함 (현재 빠른 구현 어려움)
    const eachMenu = ['product', 'service', 'about', 'estimate', 'reference']
    const closeDrawerAfterNavigate = (item: string) => {
      onClose()
      navigate(`/${item}${item === 'service' ? '/notice' : ''}`)
    }
    return eachMenu.map((item, idx) => {
      const isItemPath = location.pathname.includes(item)
      return (
        <Flex
          key={idx}
          w="full"
          h="63px"
          px={4}
          pl={6}
          bgColor={isItemPath ? '#F7F7F7' : '#fff'}
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid #EDEDED"
          borderTop={idx === 0 ? '1px solid #EDEDED' : 'none'}
          onClick={() => closeDrawerAfterNavigate(item)}
        >
          <Text
            color="#7F8589"
            fontWeight={600}
          >{`${item.toUpperCase()}`}</Text>
          {isItemPath ? (
            <ChevronRightIcon boxSize={6} color="#7F8589" />
          ) : (
            <ChevronDownIcon boxSize={6} color="#7F8589" />
          )}
        </Flex>
      )
    })
  }

  return (
    <Flex flexDirection="column" zIndex={5}>
      <Flex
        position="fixed"
        top="0"
        w="full"
        h={isMobile ? '60px' : '80px'}
        gap={8}
        justifyContent="space-between"
        alignItems="center"
        px={isMobile ? 6 : '10%'}
        zIndex="999"
        background={
          isScroll && !isUserPage
            ? 'rgba(0, 0, 0, 0.8)'
            : isUserPage
              ? 'white'
              : 'transparent'
        }
        transition="background 0.2s ease-in-out"
      >
        <Flex alignItems="center">
          <Button
            w={isMobile ? '70.82px' : '92px'}
            h={isMobile ? '20px' : '26px'}
            variant="unstyled"
            onClick={() => navigate('/')}
          >
            <Image
              src="/logo.png"
              filter={!isUserPage ? 'brightness(0) invert(1)' : 'none'}
            />
          </Button>
        </Flex>
        {/* 모바일 반응형 (일반 화면일 경우) */}
        {!isMobile && (
          <Flex minW="480px" width="60%" justifyContent="space-between">
            <Button
              fontWeight="700"
              fontSize="18px"
              onClick={() => navigate('/product')}
              color={!isUserPage ? 'white' : '#0E3961'}
            >
              PRODUCT
            </Button>
            <Button
              fontWeight="700"
              fontSize="18px"
              onClick={() => navigate('/service/notice')}
              color={!isUserPage ? 'white' : '#0E3961'}
            >
              SERVICE
            </Button>
            <Button
              fontWeight="700"
              fontSize="18px"
              onClick={() => navigate('/about')}
              color={!isUserPage ? 'white' : '#0E3961'}
            >
              ABOUT
            </Button>
            <Button
              fontWeight="700"
              fontSize="18px"
              onClick={() => navigate('/estimate')}
              color={!isUserPage ? 'white' : '#0E3961'}
            >
              ESTIMATE
            </Button>
            <Button
              fontWeight="700"
              fontSize="18px"
              onClick={() => navigate('/reference')}
              color={!isUserPage ? 'white' : '#0E3961'}
            >
              REFERENCE
            </Button>
          </Flex>
        )}
        <Flex
          h="full"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
        >
          <IconButton
            w="40px"
            minH="40px"
            aria-label="search"
            icon={<SearchIcon />}
            color={!isUserPage ? 'white' : '#0E3961'}
            boxSize="8px"
            fontSize="22px"
            onClick={() => setIsSearchOpen(!isSearchOpen)}
          />
          {isLoggedIn ? (
            <>
              <Menu>
                <MenuButton as={Flex} width="40px" h="40px" cursor="pointer">
                  <Flex alignItems="center">
                    <IconButton
                      width="40px"
                      minH="40px"
                      aria-label="search"
                      icon={<UserIcon />}
                      color={!isUserPage ? 'white' : '#0E3961'}
                      boxSize="8px"
                      fontSize="22px"
                    />
                    {!isMobile && (
                      <Text
                        color={!isUserPage ? 'white' : '#0E3961'}
                        fontWeight={600}
                        fontSize="17px"
                        textTransform="uppercase"
                      >
                        {userName}
                      </Text>
                    )}
                  </Flex>
                </MenuButton>
                <MenuList minW="8rem" px={2}>
                  <MenuItem
                    fontSize="sm"
                    borderRadius="0.25rem"
                    my={1}
                    py={2}
                    onClick={() => navigate('/user/mypage')} // TODO: Logout CSRF
                  >
                    My Page
                  </MenuItem>
                  <MenuItem
                    fontSize="sm"
                    borderRadius="0.25rem"
                    my={1}
                    py={2}
                    onClick={toggleLogout} // TODO: Logout CSRF
                  >
                    로그아웃
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : (
            // 로그인 되어있지 않을 때
            <IconButton
              w="40px"
              h="40px"
              aria-label="search"
              icon={<UserIcon />}
              color={!isUserPage ? 'white' : '#0E3961'}
              boxSize="8px"
              fontSize="22px"
              onClick={() => navigate('/user/login')}
            />
          )}
          {/* 모바일 반응형 */}
          {isMobile && (
            <IconButton
              aria-label="menu-open"
              icon={
                <HamburgerIcon
                  boxSize={6}
                  color={!isUserPage ? 'white' : '#0E3961'}
                />
              }
              onClick={onOpen}
            />
          )}
          <Drawer
            isOpen={isOpen}
            placement="right"
            size="full"
            onClose={onClose}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton h="45px" fontSize="16px" color="#0E3961" />
              <DrawerHeader>
                <Button
                  w={isMobile ? '70.82px' : '92px'}
                  h={isMobile ? '20px' : '26px'}
                  variant="unstyled"
                  onClick={() => navigate('/')}
                >
                  <Image src="/logo.png" />
                </Button>
              </DrawerHeader>
              <DrawerBody
                display="flex"
                flexDirection="column"
                gap={0}
                px={0}
                py={0}
              >
                {returnMobileDrawerItems()}
                {isLoggedIn && (
                  <>
                    <Flex
                      w="full"
                      h="63px"
                      px={4}
                      pl={6}
                      justifyContent="space-between"
                      alignItems="center"
                      borderBottom="1px solid #EDEDED"
                      onClick={toggleLogout}
                    >
                      <Text color="#7F8589" fontWeight={600}>
                        LOGOUT
                      </Text>
                      <ChevronDownIcon boxSize={6} color="#7F8589" />
                    </Flex>
                  </>
                )}
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
      </Flex>
      {/* 제품 검색 창 */}
      {/* TODO: 실제 검색 기능 연동 */}
      <Flex
        className={`search-bar ${isSearchOpen ? `open` : `hidden`}`}
        pos="fixed"
        top="0"
        zIndex={4}
        w="full"
        h={isMobile ? '142px' : '200px'}
        bgColor="rgba(0, 0, 0, 30%)"
        flexDirection="column"
      >
        <Flex h="80px" borderBottom="1px solid white" opacity="0.3" />
        <Flex w="full" h="120px" justifyContent="center" alignItems="center">
          <InputGroup w="max-content" display="flex" alignItems="center">
            <InputLeftElement mx={3} h="full">
              <SearchIcon color="#004A8D" fontSize="18px" />
            </InputLeftElement>
            <Input
              w={isMobile ? '337px' : '710px'}
              h={isMobile ? '48px' : '60px'}
              bgColor="#F7F7F7"
              borderWidth={0}
              borderRadius="50px"
              placeholder="상품을 검색해보세요."
              fontSize={isMobile ? '14px' : '18px'}
              fontWeight={500}
              textAlign="center"
              value={searchKeyword || ''}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <InputRightElement w="81px" h="full" mx={isMobile ? 0 : 3}>
              <Button
                w={isMobile ? '64.8px' : 'full'}
                h={isMobile ? '36px' : '45px'}
                bgColor="#004A8D"
                borderRadius="50px"
                onClick={toggleSearch}
              >
                검색
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Header
