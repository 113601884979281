const ServicePolicyText = 
`본 약관은 엑스고(이하 "회사"라 함)가 제공하는 서비스의 이용과 관련하여 회사와 회원의 권리, 의무 및 책임 사항을 규정함을 목적으로 합니다.

목적

본 약관은 회사가 제공하는 모든 서비스의 이용 조건 및 절차, 회원과 회사의 권리, 의무 및 책임 사항을 규정합니다.
용어의 정의

"서비스"란 회사가 제공하는 모든 온라인 서비스를 말합니다.
"회원"이란 본 약관에 동의하고 서비스를 이용하는 이용자를 말합니다.
약관의 게시와 개정

본 약관은 서비스 초기 화면 또는 연결 화면에 게시됩니다.
회사는 관련 법령을 위배하지 않는 범위에서 약관을 개정할 수 있으며, 개정 내용은 적용일자 7일 전부터 공지합니다.
서비스 이용 계약 체결

이용 계약은 회원이 되고자 하는 자가 약관의 내용에 동의하고 회원가입 신청을 한 후, 회사가 이를 승낙함으로써 체결됩니다.
회원의 의무

회원은 관계 법령, 약관의 규정, 이용 안내 등 회사가 통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 해서는 안 됩니다.
회사의 의무

회사는 관련 법령과 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적이고 안정적으로 서비스를 제공하기 위해 최선을 다합니다.
서비스의 제공 및 변경

회사는 회원에게 아래와 같은 서비스를 제공합니다:
콘텐츠 제공 서비스
기타 회사가 정하는 서비스
서비스는 연중무휴, 1일 24시간 제공을 원칙으로 합니다. 단, 정기점검 등의 필요로 회사가 정한 시간에 서비스를 일시 중지할 수 있습니다.
서비스 이용의 제한 및 중지

회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 서비스 제공을 중지할 수 있습니다.
콘텐츠의 저작권

서비스 내 제공되는 콘텐츠에 대한 저작권 및 지적재산권은 회사에 귀속됩니다.
회원은 회사의 사전 승인 없이 콘텐츠를 복제, 송신, 출판, 배포, 방송 등의 방법으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
면책조항

회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 책임을 지지 않습니다.
회사는 회원의 귀책 사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.
분쟁의 해결

회사와 회원 간에 발생한 분쟁에 관한 소송은 민사소송법 상의 관할 법원에 제소합니다.
부칙

본 약관은 2024년 10월 2일부터 시행됩니다.
고객센터 안내

회사명: 주식회사 엑스고
대표이사: 강택연
주소: 경기도 수원시 팔달구 향교로 160, 3층 8호 창업지원센터 창업관 308호,309호
연락처: 031-8068-6558, tykang70@gamil.com
본 약관 및 개인정보 처리방침에 대한 문의는 고객센터로 연락해주시기 바랍니다.`





const PrivatePolicyText = 
`엑스고(이하 "회사"라 함)는 고객의 개인정보를 중요시하며, 「개인정보 보호법」 및 관련 법령을 준수하고 있습니다. 본 개인정보 처리방침은 회사가 제공하는 서비스 이용과 관련하여 고객의 개인정보가 어떻게 수집, 이용, 제공되고 보호되는지에 대해 알려드립니다.

수집하는 개인정보의 항목 및 수집 방법

수집 항목: 이름, 사명, 직책, 연락처(전화번호, 이메일 주소), 주소, 서비스 이용 기록, 접속 로그, 쿠키, IP 주소 등
수집 방법: 홈페이지 회원가입, 고객센터 문의, 이벤트 응모, 서비스 이용 과정에서 자동으로 수집
개인정보의 수집 및 이용 목적

회원 관리: 본인 확인, 개인 식별, 부정 이용 방지
서비스 제공: 콘텐츠 제공, 맞춤 서비스 제공
마케팅 및 광고: 이벤트 정보 제공, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계
개인정보의 보유 및 이용 기간

회원 탈퇴 시 지체 없이 파기합니다. 단, 관련 법령에 따라 일정 기간 보존이 필요한 경우 해당 기간 동안 보존합니다.
계약 또는 청약철회 등에 관한 기록: 5년
대금 결제 및 재화 등의 공급에 관한 기록: 5년
소비자의 불만 또는 분쟁 처리에 관한 기록: 3년
개인정보의 제3자 제공

원칙적으로 고객의 개인정보를 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
고객이 사전에 동의한 경우
법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
개인정보의 파기 절차 및 방법

파기 절차: 수집 및 이용 목적이 달성된 후 별도의 DB로 옮겨 일정 기간 저장 후 파기
파기 방법:
전자적 파일 형태: 복구 불가능한 방법으로 삭제
종이 문서: 분쇄하거나 소각
고객의 권리와 행사 방법

언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 회원 탈퇴를 요청할 수 있습니다.
개인정보 조회, 수정 및 탈퇴는 '마이페이지'에서 직접 가능하며, 고객센터를 통해서도 요청할 수 있습니다.
개인정보의 안전성 확보 조치

관리적 조치: 내부관리계획 수립, 직원 교육 등
기술적 조치: 개인정보 접근 제한, 보안 프로그램 설치
물리적 조치: 전산실, 자료 보관실 등의 접근 통제
개인정보 보호책임자

성명: 강택연
직책: 대표이사
연락처: [031-8068-6558], [tykang70@gmail.com]
고지의 의무

본 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 수정이 있을 경우에는 변경 사항의 시행 7일 전부터 공지사항을 통해 고지합니다.`


export { ServicePolicyText, PrivatePolicyText }