import {
  Flex,
  Text,
  Image,
  Button,
  Divider,
  useMediaQuery,
  UseToastOptions,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useLayoutEffect, useState } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import {
  NoticeContent,
  NoticeResponseWithPagination,
  useGetNotice,
} from '@/API/serviceAPI'
import { ToastUtil, toLocalDateString } from '@/util/util'

interface ContextType {
  noticeList: NoticeResponseWithPagination | null
}

const NoticeArticle = () => {
  const toast = useToast()
  const { articleId } = useParams<{ articleId: string }>()
  const { noticeList } = useOutletContext<ContextType>()
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const navigate = useNavigate()
  const [noticeData, setNoticeData] = useState<NoticeContent | null>(null)
  const [targetArticleId, setTargetArticleId] = useState<number | null>(null)
  const [articleIdList, setArticleIdList] = useState<number[]>([])
  const { data: noticeArticleData, refetch } = useGetNotice(
    targetArticleId?.toString() || articleId,
  )

  const changeTargetArticleId = (increase: number) => {
    const currentIndex = articleIdList.indexOf(Number(articleId))
    const targetArticleIdFromArray = articleIdList[currentIndex + increase]
    if (targetArticleIdFromArray) {
      setTargetArticleId(articleIdList[currentIndex + increase])
    } else {
      toast(
        ToastUtil(
          '해당 페이지의 마지막 글 입니다.',
          'warning',
          '',
        ) as UseToastOptions,
      )
      return
    }
  }

  useLayoutEffect(() => {
    if (noticeArticleData) {
      setNoticeData(noticeArticleData)
      setTargetArticleId(Number(articleId))
    }
  }, [noticeArticleData, articleId])

  useEffect(() => {
    if (noticeList) {
      const idArray = noticeList.results.map((r) => r.id)
      setArticleIdList(idArray)
    }
  }, [noticeList])

  useEffect(() => {
    if (targetArticleId) {
      navigate(`/service/notice/${targetArticleId}`)
      setNoticeData(null)
      refetch()
    }
  }, [targetArticleId])

  return (
    <>
      <Flex
        px={isMobile ? 6 : '10%'}
        py={isMobile ? 12 : 16}
        flexDirection="column"
        gap={12}
      >
        <Flex
          flexDirection="column"
          borderTop="1px solid #004A8D"
          borderBottom="2px solid #F0F0F0"
          justifyContent={'center'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          h={'120px'}
          gap={isMobile ? 4 : 2}
          px={isMobile ? 4 : 0}
        >
          <Text fontWeight={600} fontSize={isMobile ? '18px' : '22px'}>
            {noticeData?.title}
          </Text>
          <Flex alignItems="center" gap={8} h={isMobile ? '26.29px' : '22px'}>
            <Flex gap={4} alignItems="center">
              <Text fontSize={isMobile ? '15px' : '18px'}>작성일</Text>
              <Divider orientation="vertical" h="20px" />
              <Text fontSize={isMobile ? '15px' : '18px'} color="#7F8589">
                {toLocalDateString(noticeData?.created_at)}
              </Text>
            </Flex>
            <Flex gap={4} alignItems="center">
              <Text fontSize={isMobile ? '15px' : '18px'}>조회수</Text>
              <Divider orientation="vertical" h="20px" />
              <Text fontSize={isMobile ? '15px' : '18px'} color="#7F8589">
                {noticeData?.view_count}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {/* Content */}
        <Flex
          flexDirection="column"
          alignItems="center"
          gap={6}
          py={isMobile ? 2 : 4}
        >
          <Flex
            flexDirection="column"
            pt={6}
            gap={6}
            maxW="950px"
            dangerouslySetInnerHTML={{ __html: noticeData?.content as string }}
          ></Flex>
        </Flex>
        {/* Buttons */}
        <Flex
          borderTop={isMobile ? '1px solid #C9C9C9' : '2px solid #E8E8E8'}
          justifyContent="space-between"
          py={isMobile ? 8 : 4}
        >
          <Flex gap={4}>
            <Button
              border="1px solid #E8E8E8"
              borderRadius={0}
              color="black"
              fontWeight={400}
              h={isMobile ? '50.55px' : '60px'}
              w={isMobile ? '90px' : '101px'}
              leftIcon={<ChevronLeftIcon color="#CCCDCE" />}
              // TODO: 없는 index 처리 + notice list 도 받아둬야 함 (id 기준으로 불러와야 하고 임의 Number 로 진행하면 안됨)
              onClick={() => changeTargetArticleId(-1)}
            >
              이전글
            </Button>
            <Button
              border="1px solid #E8E8E8"
              borderRadius={0}
              color="black"
              fontWeight={400}
              h={isMobile ? '50.55px' : '60px'}
              w={isMobile ? '90px' : '101px'}
              rightIcon={<ChevronRightIcon color="#CCCDCE" />}
              onClick={() => changeTargetArticleId(1)}
            >
              다음글
            </Button>
          </Flex>
          <Button
            border="1px solid #E8E8E8"
            borderRadius={0}
            color="black"
            fontWeight={500}
            h={isMobile ? '50.55px' : '60px'}
            w={isMobile ? '90px' : '101px'}
            onClick={() => navigate('/service/notice')}
          >
            목록
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

export default NoticeArticle
