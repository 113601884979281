import {
  Flex,
  Checkbox,
  Text,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react'
import TermsPolicy from '../modal/TermsPolicy'

interface UserInfoConsentProps {
  isConsent: boolean
  setIsConsent: React.Dispatch<React.SetStateAction<boolean>>
}

const UserInfoConsent = ({ isConsent, setIsConsent }: UserInfoConsentProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const {
    isOpen: isTermsModalOpen,
    onOpen: onTermsModalOpen,
    onClose: onTermsModalClose,
  } = useDisclosure()

  return (
    <Flex w="full" flexDirection="column" py={4} gap={12}>
      <Flex>
        <Flex
          justifyContent={isMobile ? 'center' : 'space-between'}
          border="1px solid #CCCDCE"
          w="full"
          h={isMobile ? '106px' : '78px'}
          alignItems={isMobile ? 'stretch' : 'center'}
          px={6}
          flexDirection={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 0}
        >
          <Flex pr={4} alignItems="center">
            <Text
              fontWeight={500}
              fontSize={isMobile ? '16px' : '18px'}
              minW="max-content"
            >
              개인정보 수집·이용에 대한 동의
            </Text>
          </Flex>
          <Flex gap={4} alignItems="center" justifyContent="space-between">
            <TermsPolicy
              isTermsModal={false}
              isConsent={isConsent}
              setIsConsent={setIsConsent}
              isModalOpen={isTermsModalOpen}
              onModalOpen={onTermsModalOpen}
              onModalClose={onTermsModalClose}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UserInfoConsent
