import { AxiosError } from 'axios'
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import axiosInstance from '@/API/http'
import { ErrorResponseData } from '@/util/util'

export interface NoticeResponseWithPagination {
  links?: {
    next?: string
    previous?: string
  }
  count: number
  page_count: number
  page_number: number
  page_size: number
  results: NoticeContent[]
}

export interface NoticeContent {
  id: number
  previous_id?: number
  next_id?: number
  article_number: number
  title: string
  content: string
  created_at: string
  view_count: number
  is_pinned: boolean
}

export interface InquiryRequest {
  company_name: string
  contact_name: string
  email: string
  phone: string
  title: string
  detail: string
}

export interface FAQResponse {
  title: string
  content: string
}

export const isAllInquiryParams = (
  params: Partial<InquiryRequest>,
): params is InquiryRequest => {
  return (
    typeof params.company_name === 'string' &&
    typeof params.contact_name === 'string' &&
    typeof params.email === 'string' &&
    typeof params.phone === 'string' &&
    typeof params.title === 'string' &&
    typeof params.detail === 'string'
  )
}

const useGetNoticeList = (
  page: number,
  searchText?: string
): UseQueryResult<
  NoticeResponseWithPagination,
  AxiosError<ErrorResponseData>
> => {
  return useQuery({
    queryKey: ['getNoticeList', page, searchText],
    queryFn: async () => {
      let uri = `/article/notice/?page=${page}`
      if(searchText) uri += `&title=${searchText}`
      const response = await axiosInstance.get(uri)
      return response.data
    },
  })
}

const useGetNotice = (
  articleNum: string | undefined,
): UseQueryResult<NoticeContent, AxiosError<ErrorResponseData>> => {
  return useQuery({
    queryKey: ['getNotice'],
    queryFn: async () => {
      if (articleNum) {
        const response = await axiosInstance.get(
          `/article/notice/${articleNum}`,
        )
        return response.data
      }
    },
  })
}

const useGetFAQ = (): UseQueryResult<
  FAQResponse[],
  AxiosError<ErrorResponseData>
> => {
  return useQuery({
    queryKey: ['getFAQ'],
    queryFn: async () => {
      const response = await axiosInstance.get(`/article/faq`)
      return response.data
    },
  })
}

const useUploadInquiry = () => {
  return useMutation<void, AxiosError<ErrorResponseData>, InquiryRequest>({
    mutationFn: async (inquiryData: InquiryRequest) => {
      await axiosInstance.post(`/article/inquiry/`, inquiryData)
    },
  })
}

export { useGetNoticeList, useGetNotice, useGetFAQ, useUploadInquiry }
