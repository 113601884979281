// 1. Import the extendTheme function
import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  fonts: {
    fontFamily: `font-family: "Pretendard", sans-serif;`,
  },
  components: {
    Button: {
      baseStyle: {
        control: {
          bg: '#3B2D79',
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          // bg: 'white',
        },
      },
    },
  },
  colors: {
    exgo: {
      // 300: '#00BCCC',
      // 500: '#3B2D79',
      900: '#212121',
    },
  },
}
export default extendTheme(
  colors,
  withDefaultColorScheme({
    colorScheme: 'exgo',
    components: ['Input', 'Button', 'Checkbox', 'RangeSlider'],
  }),
)
