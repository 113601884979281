import { ChevronRightIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Flex,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Image,
  Text,
  Divider,
  Button,
  Box,
  useMediaQuery,
} from '@chakra-ui/react'
import AccordionCategory from '@/components/category/AccordionCategory'
import {
  ProductRequest,
  ProductResponse,
  useGetProductDetail,
} from '@/API/productAPI'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'

interface ContextType {
  isLoading: boolean
  URLParams: Partial<ProductRequest>
  setURLParams: React.Dispatch<React.SetStateAction<Partial<ProductRequest>>>
}

const ProductDetail = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate()
  const { productId } = useParams<{ productId: string }>() // category 별 제품 페이지
  const [productDetail, setProductDetail] = useState<ProductResponse | null>(
    null,
  )
  const [hoverThumbnailURL, setHoverThumbnailURL] = useState<string>('')
  const { data: productDetailData, refetch } = useGetProductDetail(
    Number(productId),
  )

  const { isLoading, URLParams, setURLParams } = useOutletContext<ContextType>()

  useEffect(() => {
    if (productId) {
      refetch()
    }
  }, [productId, refetch])

  useEffect(() => {
    if (productDetailData) {
      setProductDetail(productDetailData)
      setHoverThumbnailURL(productDetailData.thumbnails[0])
    }
  }, [productDetailData, productId])

  const productThumbnails = () => {
    return productDetail?.thumbnails.map((thumbnail, i) => {
      const thumbnailImageURL = thumbnail || '/example/iphone.jpeg'
      return (
        <Flex
          border={
            hoverThumbnailURL === thumbnailImageURL
              ? '1px solid #004A8D'
              : '1px solid #EDEDED'
          }
          key={i}
          w="81px"
          h="81px"
          alignItems="center"
          justifyContent="center"
        >
          <Image
            key={i}
            src={thumbnailImageURL}
            onMouseEnter={() => setHoverThumbnailURL(thumbnailImageURL)}
            w="74px"
            h="61px"
            objectFit="contain"
          />
        </Flex>
      )
    })
  }
  const relatedProducts = () => {
    // todo: change to realted Products
    return productDetail?.related_products?.map((product, i) => {
      return (
        <Flex flexDirection="column" key={i} gap={2}>
          <Flex
            pos="relative"
            w="176px"
            h="176px"
            border="1px solid #EDEDED"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={() => navigate(`/product/${product.id}`)} // 어라...? 눌러도 내용이 안바뀜...?
          >
            <Box
              w="270px"
              h="226px"
              backgroundImage={product.thumbnail}
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              backgroundSize="contain"
            />
          </Flex>
          <Flex flexDirection="column" pt={2} pb={8} gap={1}>
            <Text color="#7F8589" fontSize="14px" fontWeight="600">
              {product.company}
            </Text>
            <Text fontWeight="600">{product.name}</Text>
          </Flex>
        </Flex>
      )
    })
  }

  return (
    <>
      <Flex
        w="full"
        px={4}
        py={isMobile ? 4 : 10}
        gap={isMobile ? 0 : 8}
        flexDirection={isMobile ? 'column' : 'row'}
        pt={isMobile ? 0 : 6}
      >
        <AccordionCategory
          isSearchPage={false}
          URLParams={URLParams}
          setURLParams={setURLParams}
        />
        <Flex w="full" flexDirection="column" gap={2} overflow="auto">
          {!isMobile && (
            <Breadcrumb
              display="flex"
              alignItems="center"
              my={1}
              h="40px"
              spacing="8px"
              separator={<ChevronRightIcon color="gray.500" />}
            >
              {productDetail?.category?.map((category, idx) => {
                const isLastItem = productDetail?.category?.length === idx + 1
                return (
                  <BreadcrumbItem
                    fontWeight={600}
                    key={idx}
                    isCurrentPage={isLastItem}
                    color={isLastItem ? '#004A8D' : 'black'}
                  >
                    <BreadcrumbLink href="#">{category}</BreadcrumbLink>
                  </BreadcrumbItem>
                )
              })}
            </Breadcrumb>
          )}
          <Flex gap={6} flexDirection={isMobile ? 'column' : 'row'}>
            <Flex flexDirection="column" w="full" maxW="461px">
              <Flex
                h="461px"
                border="1px solid #EDEDED"
                alignItems="center"
                justifyContent="center"
              >
                <Image w="408px" h="342px" src={hoverThumbnailURL} />
              </Flex>
              <Flex py={4} justifyContent="space-between" gap={4}>
                {productThumbnails()}
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              gap={4}
              w="full"
            >
              <Flex flexDirection="column" gap={2}>
                <Text fontSize="24px" fontWeight="bold">
                  {productDetail?.name || ''}
                </Text>
                <Text fontSize="16px" fontWeight="600">
                  {productDetail?.company || ''}
                </Text>
              </Flex>
              <Divider my={4} borderColor="#CCCCCC" opacity={1} />
              {/* 요약글 추가되면 변경 */}
              <Text fontSize="16px" fontWeight="500" color="#7F8589">
                {productDetail?.description}
              </Text>
              <Divider my={4} borderColor="#CCCCCC" opacity={1} />
              <Flex flexDirection="column" justifyContent="flex-start" gap={3}>
                <Flex gap={10}>
                  <Text fontSize="16px" fontWeight="600" minW="100px">
                    원산지
                  </Text>
                  {/* 원산지 추가되면 변경 */}
                  <Text fontSize="16px" fontWeight="600" color="#7F8589">
                    {productDetail?.country_of_origin || '한국'}
                  </Text>
                </Flex>
                <Flex gap={10}>
                  <Text fontSize="16px" fontWeight="600" minW="100px">
                    수량
                  </Text>
                  <Text fontSize="16px" fontWeight="600" color="#7F8589">
                    MOQ : {productDetail?.moq || 1} ea
                  </Text>
                </Flex>
                <Flex gap={10}>
                  <Text fontSize="16px" fontWeight="600" minW="100px">
                    키워드
                  </Text>
                  {/* 키워드 매칭되면 변경 */}
                  <Text fontSize="16px" fontWeight="600" color="#7F8589">
                    {productDetail?.keywords?.join(',')}
                  </Text>
                </Flex>
              </Flex>
              <Divider my={4} borderColor="#CCCCCC" opacity={1} />
            </Flex>
          </Flex>
          <Flex py={6} flexDirection="column" gap={4}>
            <Text fontWeight={700} fontSize="20px">
              이 제품과 연관된 다른 제품
            </Text>
            <Flex w="full" gap={8} overflowX="auto">
              {relatedProducts()}
            </Flex>
          </Flex>
          {/* 제품 세부 설명 섹션 */}
          <Flex flexDirection="column" gap={8}>
            <Flex
              w="full"
              h="50px"
              bgColor="#F7F7F7"
              alignItems="center"
              px={4}
            >
              <Text fontWeight={700} fontSize="18px">
                Product Detail
              </Text>
            </Flex>
            <Flex
              flexDirection="column"
              gap={8}
              whiteSpace="break-spaces"
              wordBreak="break-word"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(productDetail?.content || ''),
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default ProductDetail
