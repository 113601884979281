const PortList = [
  { 
    "port": "ABIDJAN", 
    "country": "Ivory Coast" 
  }, 
  { 
    "port": "ABU DHABI", 
    "country": "U.A.E." 
  },
  {
    "port": "ABU QIR", 
    "country": "Egypt" 
  },
  {
    "port": "ADDIS ABABA", 
    "country": "Ethiopia" 
  },
  {
    "port": "ADELAIDE", 
    "country": "Australia" 
  },
  {
    "port": "ADEN", 
    "country": "Yemen" 
  },
  {
    "port": "AJMAN", 
    "country": "U.A.E." 
  },
  {
    "port": "AKITA", 
    "country": "Japan" 
  },
  {
    "port": "ALEXANDRIA", 
    "country": "Egypt" 
  },
  {
    "port": "ALGECIRAS", 
    "country": "Spain" 
  },
  {
    "port": "ALGIERS", 
    "country": "Algeria" 
  },
  {
    "port": "ALIAGA", 
    "country": "Turkey" 
  },
  {
    "port": "ALMATY", 
    "country": "Kazakhstan" 
  },
  {
    "port": "ANQING", 
    "country": "China" 
  },
  {
    "port": "ANTWERP", 
    "country": "Belgium" 
  },
  {
    "port": "APAPA", 
    "country": "Nigeria" 
  },
  {
    "port": "APIA", 
    "country": "Samoa" 
  },
  {
    "port": "AQABA", 
    "country": "Jordan" 
  },
  {
    "port": "ARICA", 
    "country": "Chile" 
  },
  {
    "port": "ASSALUYEH", 
    "country": "U.A.E." 
  },
  {
    "port": "ASUNCION", 
    "country": "Paraguay" 
  },
  {
    "port": "ATLANTA", 
    "country": "US Georgia" 
  },
  {
    "port": "AUCKLAND", 
    "country": "New Zealand" 
  },
  {
    "port": "BAHRAIN", 
    "country": "Bahrain" 
  },
  {
    "port": "BALTIMORE", 
    "country": "US Maryland" 
  },
  {
    "port": "BANDAR ABBAS", 
    "country": "Iran" 
  },
  {
    "port": "BANDAR IMAM KHOMEINI", 
    "country": "Iran" 
  },
  {
    "port": "BANGALORE", 
    "country": "India" 
  },
  {
    "port": "BANGKOK", 
    "country": "Thailand" 
  },
  {
    "port": "BANJARMASIN", 
    "country": "Indonesia" 
  },
  {
    "port": "BARCELONA", 
    "country": "Spain" 
  },
  {
    "port": "BATAM", 
    "country": "Indonesia" 
  },
  {
    "port": "BATANGAS", 
    "country": "Philippines" 
  },
  {
    "port": "BEIJIAO", 
    "country": "China" 
  },
  {
    "port": "BEIRA", 
    "country": "Mozambique" 
  },
  {
    "port": "BEIRUT", 
    "country": "Lebanon" 
  },
  {
    "port": "BELAWAN", 
    "country": "Indonesia" 
  },
  {
    "port": "BENGHAZI", 
    "country": "Libya" 
  },
  {
    "port": "BERBERA", 
    "country": "Somalia" 
  },
  {
    "port": "BILBAO", 
    "country": "Spain" 
  },
  {
    "port": "BINTULU", 
    "country": "Malaysia" 
  },
  {
    "port": "BIRGUNJ", 
    "country": "Nepal" 
  },
  {
    "port": "BOSTON", 
    "country": "US Massachusetts" 
  },
  {
    "port": "BRATISLAVA", 
    "country": "Slovakia" 
  },
  {
    "port": "BREMERHAVEN", 
    "country": "Germany" 
  },
  {
    "port": "BRISBANE", 
    "country": "Australia" 
  },
  {
    "port": "BUDAPEST", 
    "country": "Hungary" 
  },
  {
    "port": "BUENAVENTURA", 
    "country": "Colombia" 
  },
  {
    "port": "BUENOS AIRES", 
    "country": "Argentina" 
  },
  {
    "port": "BUSHEHR", 
    "country": "Iran" 
  },
  {
    "port": "CAGAYAN DE ORO", 
    "country": "Philippines" 
  },
  {
    "port": "CALCUTTA", 
    "country": "India" 
  },
  {
    "port": "KOLKATA", 
    "country": "India" 
  },
  {
    "port": "CALLAO", 
    "country": "Peru" 
  },
  {
    "port": "CAMBODIA", 
    "country": "Cambodia" 
  },
  {
    "port": "CANCUN", 
    "country": "Mexico" 
  },
  {
    "port": "CAPE TOWN", 
    "country": "South Africa" 
  },
  {
    "port": "CARTAGENA(COL)", 
    "country": "Colombia" 
  },
  {
    "port": "CASABLANCA", 
    "country": "Morocco" 
  },
  {
    "port": "CAT LAI", 
    "country": "Vietnam" 
  },
  {
    "port": "CAUCEDO", 
    "country": "Dominican Republic" 
  },
  {
    "port": "CEBU", 
    "country": "Philippines" 
  },
  {
    "port": "CHABAHAR", 
    "country": "Iran" 
  },
  {
    "port": "CHANGSHA", 
    "country": "China" 
  },
  {
    "port": "CHANGSHU", 
    "country": "China" 
  },
  {
    "port": "CHANGZHOU", 
    "country": "China" 
  },
  {
    "port": "CHARLESTON", 
    "country": "US S.Carolina" 
  },
  {
    "port": "CHENNAI", 
    "country": "India" 
  },
  {
    "port": "CHIBA", 
    "country": "Japan" 
  },
  {
    "port": "CHICAGO", 
    "country": "US Illinois" 
  },
  {
    "port": "CHITTAGONG", 
    "country": "Bangladesh" 
  },
  {
    "port": "CHONGQING", 
    "country": "China" 
  },
  {
    "port": "CHRISTCHURCH", 
    "country": "New Zealand" 
  },
  {
    "port": "CHUUK", 
    "country": "Micronesia" 
  },
  {
    "port": "COCHIN", 
    "country": "India" 
  },
  {
    "port": "COLOMBO", 
    "country": "Sri Lanka" 
  },
  {
    "port": "COLON", 
    "country": "Panama" 
  },
  {
    "port": "CONSTANTA", 
    "country": "Romania" 
  },
  {
    "port": "COTONOU", 
    "country": "Benin" 
  },
  {
    "port": "CRISTOBAL", 
    "country": "Panama" 
  },
  {
    "port": "DACHAN BAY", 
    "country": "China" 
  },
  {
    "port": "DAFENG", 
    "country": "China" 
  },
  {
    "port": "DAKAR", 
    "country": "Senegal" 
  },
  {
    "port": "DALIAN", 
    "country": "China" 
  },
  {
    "port": "DALLAS", 
    "country": "US Texas" 
  },
  {
    "port": "DAMIETTA", 
    "country": "Egypt" 
  },
  {
    "port": "DAMMAM", 
    "country": "Saudi Arabia" 
  },
  {
    "port": "DAMPIER", 
    "country": "Australia" 
  },
  {
    "port": "DANANG", 
    "country": "Vietnam" 
  },
  {
    "port": "DANDONG", 
    "country": "China" 
  },
  {
    "port": "DAR ES SALAAM", 
    "country": "Tabzania" 
  },
  {
    "port": "DARWIN", 
    "country": "Australia" 
  },
  {
    "port": "DAVAO", 
    "country": "Philippines" 
  },
  {
    "port": "DILI", 
    "country": "Timor" 
  },
  {
    "port": "DJIBOUTI", 
    "country": "Djibouti" 
  },
  {
    "port": "DONGGUAN", 
    "country": "China" 
  },
  {
    "port": "DOUALA", 
    "country": "Cameroon" 
  },
  {
    "port": "DUBAI", 
    "country": "U.A.E." 
  },
  {
    "port": "DUBLIN", 
    "country": "Irish Republic" 
  },
  {
    "port": "DURBAN", 
    "country": "South Africa" 
  },
  {
    "port": "EBEYE", 
    "country": "Micronesia" 
  },
  {
    "port": "EMBAKASI", 
    "country": "Kenya" 
  },
  {
    "port": "ENSENADA", 
    "country": "Mexico" 
  },
  {
    "port": "EVERETT", 
    "country": "US Washington" 
  },
  {
    "port": "FELIXSTOWE", 
    "country": "United Kingdom" 
  },
  {
    "port": "FOS", 
    "country": "France" 
  },
  {
    "port": "FOS SUR MER", 
    "country": "France" 
  },
  {
    "port": "FRASER SURREY", 
    "country": "Canada" 
  },
  {
    "port": "FREMANTLE", 
    "country": "Australia" 
  },
  {
    "port": "FUKUOKA", 
    "country": "Japan" 
  },
  {
    "port": "FUKUYAMA", 
    "country": "Japan" 
  },
  {
    "port": "FUNAFUTI", 
    "country": "Tuvalu" 
  },
  {
    "port": "FUTUNA", 
    "country": "Wallis and Futuna(France)" 
  },
  {
    "port": "FUZHOU", 
    "country": "China" 
  },
  {
    "port": "GDANSK", 
    "country": "Poland" 
  },
  {
    "port": "GEBZE", 
    "country": "Turkey" 
  },
  {
    "port": "GEMLIK", 
    "country": "Turkey" 
  },
  {
    "port": "GENERAL SANTOS", 
    "country": "Philippines" 
  },
  {
    "port": "GENOA", 
    "country": "Italy" 
  },
  {
    "port": "GEORGE TOWN", 
    "country": "U.K. Cayman Islands" 
  },
  {
    "port": "GIOIA TAURO", 
    "country": "Italy" 
  },
  {
    "port": "GOTHENBURG", 
    "country": "Sweden" 
  },
  {
    "port": "GUAM", 
    "country": "U.S. Guam" 
  },
  {
    "port": "GUANGZHOU", 
    "country": "China" 
  },
  {
    "port": "GUATEMALA CITY", 
    "country": "Guatemala" 
  },
  {
    "port": "GUAYAQUIL", 
    "country": "Equador" 
  },
  {
    "port": "HACHINOHE", 
    "country": "Japan" 
  },
  {
    "port": "HAIPHONG", 
    "country": "Vietnam" 
  },
  {
    "port": "HAKATA", 
    "country": "Japan" 
  },
  {
    "port": "HALDIA", 
    "country": "India" 
  },
  {
    "port": "HAMAD", 
    "country": "Qatar" 
  },
  {
    "port": "HAMADA", 
    "country": "Japan" 
  },
  {
    "port": "HAMBURG", 
    "country": "Germany" 
  },
  {
    "port": "HAMILTON", 
    "country": "Bermuda" 
  },
  {
    "port": "HAZIRA", 
    "country": "India" 
  },
  {
    "port": "HELSINKI", 
    "country": "Finland" 
  },
  {
    "port": "HIBIKI", 
    "country": "Japan" 
  },
  {
    "port": "HIBIKINADA", 
    "country": "Japan" 
  },
  {
    "port": "HILO", 
    "country": "US Hawaii" 
  },
  {
    "port": "HIROSHIMA", 
    "country": "Japan" 
  },
  {
    "port": "HITACHINAKA", 
    "country": "Japan" 
  },
  {
    "port": "HOCHIMINH", 
    "country": "Vietnam" 
  },
  {
    "port": "HODEIDAH", 
    "country": "Yemen" 
  },
  {
    "port": "HONG KONG", 
    "country": "China" 
  },
  {
    "port": "HONIARA", 
    "country": "Solomon Islands" 
  },
  {
    "port": "HONOLULU", 
    "country": "US Hawaii" 
  },
  {
    "port": "HOSOSHIMA", 
    "country": "Japan" 
  },
  {
    "port": "HOUSTON", 
    "country": "US Texas" 
  },
  {
    "port": "HSINCHU", 
    "country": "Taiwan" 
  },
  {
    "port": "HUANGHUA", 
    "country": "China" 
  },
  {
    "port": "HUANGPU", 
    "country": "China" 
  },
  {
    "port": "IMABARI", 
    "country": "Japan" 
  },
  {
    "port": "IMARI", 
    "country": "Japan" 
  },
  {
    "port": "IQUIQUE", 
    "country": "Chile" 
  },
  {
    "port": "ISHIKARA", 
    "country": "Japan" 
  },
  {
    "port": "ISKENDERUN", 
    "country": "Turkey" 
  },
  {
    "port": "ISTANBUL", 
    "country": "Turkey" 
  },
  {
    "port": "ITAJAI", 
    "country": "Brazil" 
  },
  {
    "port": "ITAPOA", 
    "country": "Brazil" 
  },
  {
    "port": "IWAKUNI", 
    "country": "Japan" 
  },
  {
    "port": "IYOMOSHIMA", 
    "country": "Japan" 
  },
  {
    "port": "IZMIR", 
    "country": "Turkey" 
  },
  {
    "port": "IZMIT", 
    "country": "Turkey" 
  },
  {
    "port": "JACKSONVILLE", 
    "country": "US Florida" 
  },
  {
    "port": "JAKARTA", 
    "country": "Indonesia" 
  },
  {
    "port": "JEBEL ALI", 
    "country": "U.A.E." 
  },
  {
    "port": "JEDDAH", 
    "country": "Saudi Arabia" 
  },
  {
    "port": "JIANGYIN", 
    "country": "China" 
  },
  {
    "port": "JINGTANG", 
    "country": "China" 
  },
  {
    "port": "JIUJIANG", 
    "country": "China" 
  },
  {
    "port": "JIZAN", 
    "country": "Saudi Arabia" 
  },
  {
    "port": "JOHANNESBURG", 
    "country": "South Africa" 
  },
  {
    "port": "JOHOR", 
    "country": "Malaysia" 
  },
  {
    "port": "JUBAIL", 
    "country": "Saudi Arabia" 
  },
  {
    "port": "KAHULUI", 
    "country": "US Hawaii" 
  },
  {
    "port": "KALININGRAD", 
    "country": "Russia" 
  },
  {
    "port": "KANAZAWA", 
    "country": "Japan" 
  },
  {
    "port": "KANSAS CITY", 
    "country": "U.S.A" 
  },
  {
    "port": "KAOHSIUNG", 
    "country": "Taiwan" 
  },
  {
    "port": "KARACHI", 
    "country": "Pakistan" 
  },
  {
    "port": "KASHIMA", 
    "country": "Japan" 
  },
  {
    "port": "KATTUPALLI", 
    "country": "India" 
  },
  {
    "port": "KAWAIHAE", 
    "country": "US Hawaii" 
  },
  {
    "port": "KEELUNG", 
    "country": "Taiwan" 
  },
  {
    "port": "KHOR FAKKAN", 
    "country": "U.A.E." 
  },
  {
    "port": "KHORRAMSHAHR", 
    "country": "Iran" 
  },
  {
    "port": "KINGSTON", 
    "country": "Jamaica" 
  },
  {
    "port": "KISH ISLAND", 
    "country": "Iran" 
  },
  {
    "port": "KOBE", 
    "country": "Japan" 
  },
  {
    "port": "KOCHI", 
    "country": "Japan" 
  },
  {
    "port": "KOLKATA", 
    "country": "India" 
  },
  {
    "port": "KOPER", 
    "country": "Slovenia" 
  },
  {
    "port": "KOROR", 
    "country": "Palau" 
  },
  {
    "port": "KORSAKOV", 
    "country": "Russia" 
  },
  {
    "port": "KOSRAE", 
    "country": "Micronesia" 
  },
  {
    "port": "KOTA KINABALU", 
    "country": "Malaysia" 
  },
  {
    "port": "KOTKA", 
    "country": "Finland" 
  },
  {
    "port": "KUANTAN", 
    "country": "Malaysia" 
  },
  {
    "port": "KUCHING", 
    "country": "Malaysia" 
  },
  {
    "port": "KUMAMOTO", 
    "country": "Japan" 
  },
  {
    "port": "KUWAIT", 
    "country": "Kuwait" 
  },
  {
    "port": "KWAJALEIN", 
    "country": "Marshall Islands" 
  },
  {
    "port": "LA SPEZIA", 
    "country": "Italy" 
  },
  {
    "port": "LAE", 
    "country": "Papua New Guinea" 
  },
  {
    "port": "LAEM CHABANG", 
    "country": "Thailand" 
  },
  {
    "port": "LAGOS", 
    "country": "Nigeria" 
  },
  {
    "port": "LAOS", 
    "country": "Laos" 
  },
  {
    "port": "LAT KRABANG", 
    "country": "Thailand" 
  },
  {
    "port": "LAUTOKA", 
    "country": "Fiji" 
  },
  {
    "port": "LAZARO CARDENAS", 
    "country": "Mexico" 
  },
  {
    "port": "LE HAVRE", 
    "country": "France" 
  },
  {
    "port": "LELIU", 
    "country": "China" 
  },
  {
    "port": "LIANYUNGANG", 
    "country": "China" 
  },
  {
    "port": "LIBREVILLE", 
    "country": "Gabon" 
  },
  {
    "port": "LIRQUEN", 
    "country": "Chile" 
  },
  {
    "port": "LISBON", 
    "country": "Portugal" 
  },
  {
    "port": "LIVERPOOL", 
    "country": "United Kingdom" 
  },
  {
    "port": "LOME", 
    "country": "Togo" 
  },
  {
    "port": "LONG BEACH", 
    "country": "US California" 
  },
  {
    "port": "LOS ANGELES", 
    "country": "US California" 
  },
  {
    "port": "LUZHOU", 
    "country": "China" 
  },
  {
    "port": "LYTTELTON", 
    "country": "New Zealand" 
  },
  {
    "port": "MACAU", 
    "country": "China" 
  },
  {
    "port": "MADANG", 
    "country": "Papua New Guinea" 
  },
  {
    "port": "MADRAS", 
    "country": "India" 
  },
  {
    "port": "MAIZURU", 
    "country": "Japan" 
  },
  {
    "port": "MAJURO", 
    "country": "Marshall Islands" 
  },
  {
    "port": "MAKASSAR", 
    "country": "Indonesia" 
  },
  {
    "port": "MALE", 
    "country": "Maldives" 
  },
  {
    "port": "MANILA", 
    "country": "Philippines" 
  },
  {
    "port": "MANZANILLO(MEX)", 
    "country": "Mexico" 
  },
  {
    "port": "MAPUTO", 
    "country": "Mozambique" 
  },
  {
    "port": "MARGHERA", 
    "country": "Italy" 
  },
  {
    "port": "MARSDEN POINT", 
    "country": "New Zealand" 
  },
  {
    "port": "MARSH HARBOUR", 
    "country": "Bahamas" 
  },
  {
    "port": "MASSAWA", 
    "country": "Eritrea" 
  },
  {
    "port": "MATSUYAMA", 
    "country": "Japan" 
  },
  {
    "port": "MELBOURNE", 
    "country": "Australia" 
  },
  {
    "port": "MEMPHIS", 
    "country": "US Tennessee" 
  },
  {
    "port": "MERSIN", 
    "country": "Turkey" 
  },
  {
    "port": "MIAMI", 
    "country": "US Florida" 
  },
  {
    "port": "MIRI", 
    "country": "Malaysia" 
  },
  {
    "port": "MISURATA", 
    "country": "Libya" 
  },
  {
    "port": "MIYAGI SENDAI", 
    "country": "Japan" 
  },
  {
    "port": "MIZUSHIMA", 
    "country": "Japan" 
  },
  {
    "port": "MOBILE", 
    "country": "US Alabama" 
  },
  {
    "port": "MODJO", 
    "country": "Ethiopia" 
  },
  {
    "port": "MOJI", 
    "country": "Japan" 
  },
  {
    "port": "MOMBASA", 
    "country": "Kenya" 
  },
  {
    "port": "MONTEVIDEO", 
    "country": "Uruguay" 
  },
  {
    "port": "MONTREAL", 
    "country": "Canada" 
  },
  {
    "port": "MOSCOW", 
    "country": "Russia" 
  },
  {
    "port": "MOTUKEA", 
    "country": "New Zealand" 
  },
  {
    "port": "MUARA", 
    "country": "Brunei" 
  },
  {
    "port": "MUNDRA", 
    "country": "India" 
  },
  {
    "port": "NACALA", 
    "country": "Mozambique" 
  },
  {
    "port": "NAGASAKI", 
    "country": "Japan" 
  },
  {
    "port": "NAGOYA", 
    "country": "Japan" 
  },
  {
    "port": "NAHA", 
    "country": "Japan" 
  },
  {
    "port": "NAIROBI", 
    "country": "Kenya" 
  },
  {
    "port": "NAKANOSEKI", 
    "country": "Japan" 
  },
  {
    "port": "NAKHODKA", 
    "country": "Russia" 
  },
  {
    "port": "NANCHANG", 
    "country": "China" 
  },
  {
    "port": "NANJING", 
    "country": "China" 
  },
  {
    "port": "NANSHA", 
    "country": "China" 
  },
  {
    "port": "NANTONG", 
    "country": "China" 
  },
  {
    "port": "NAOETSU", 
    "country": "Japan" 
  },
  {
    "port": "NAPIER", 
    "country": "New Zealand" 
  },
  {
    "port": "NAVEGANTES", 
    "country": "Brazil" 
  },
  {
    "port": "NAWILIWILI", 
    "country": "US Hawaii" 
  },
  {
    "port": "NEW DELHI", 
    "country": "India" 
  },
  {
    "port": "NEW ORLEANS", 
    "country": "US Louisiana" 
  },
  {
    "port": "NEW YORK", 
    "country": "US N.Y." 
  },
  {
    "port": "NHAVA SHEVA", 
    "country": "India" 
  },
  {
    "port": "NIIGATA", 
    "country": "Japan" 
  },
  {
    "port": "NINGBO-ZHOUSHAN", 
    "country": "China" 
  },
  {
    "port": "NORFOLK", 
    "country": "US Virginia" 
  },
  {
    "port": "NORFOLK ISLAND", 
    "country": "Australia" 
  },
  {
    "port": "NOUMEA", 
    "country": "France New Caledonia" 
  },
  {
    "port": "NOVOROSSIYSK", 
    "country": "Russia" 
  },
  {
    "port": "NUKUALOFA", 
    "country": "Tonga" 
  },
  {
    "port": "OAKLAND", 
    "country": "US California" 
  },
  {
    "port": "OITA", 
    "country": "Japan" 
  },
  {
    "port": "ONAHAMA", 
    "country": "Japan" 
  },
  {
    "port": "ONNE", 
    "country": "Nigeria" 
  },
  {
    "port": "OSAKA", 
    "country": "Japan" 
  },
  {
    "port": "OSLO", 
    "country": "Norway" 
  },
  {
    "port": "PAGO PAGO", 
    "country": "American Samoa" 
  },
  {
    "port": "PANJANG", 
    "country": "Indonesia" 
  },
  {
    "port": "PAPEETE", 
    "country": "French Polynesia" 
  },
  {
    "port": "PARANAGUA", 
    "country": "Brazil" 
  },
  {
    "port": "PASIR GUDANG", 
    "country": "Malaysia" 
  },
  {
    "port": "PENANG", 
    "country": "Malaysia" 
  },
  {
    "port": "PHNOMPENH", 
    "country": "Cambodia" 
  },
  {
    "port": "PIPAVAV", 
    "country": "India" 
  },
  {
    "port": "PIRAEUS", 
    "country": "Greece" 
  },
  {
    "port": "POHNPEI", 
    "country": "Micronesia" 
  },
  {
    "port": "POINT LISAS", 
    "country": "Trinidad and  Tobago" 
  },
  {
    "port": "POINTE NOIRE", 
    "country": "Republic of the Congo" 
  },
  {
    "port": "PONTIANAK", 
    "country": "Indonesia" 
  },
  {
    "port": "PORT HEDLAND", 
    "country": "Australia" 
  },
  {
    "port": "PORT KELANG", 
    "country": "Malaysia" 
  },
  {
    "port": "PORT KING ABDULLAH", 
    "country": "Saudi Arabia" 
  },
  {
    "port": "PORT LOUIS", 
    "country": "Mauritius" 
  },
  {
    "port": "PORT MORESBY", 
    "country": "Papua New Guinea" 
  },
  {
    "port": "PORT SUDAN", 
    "country": "Sudan" 
  },
  {
    "port": "PORT VILA", 
    "country": "Vanuatu" 
  },
  {
    "port": "PORTLAND(US)", 
    "country": "US Oregon" 
  },
  {
    "port": "POTI", 
    "country": "Georgia" 
  },
  {
    "port": "PRINCE RUPERT", 
    "country": "Canada" 
  },
  {
    "port": "PROVIDENCIALES", 
    "country": "Turks and Caicos Islands" 
  },
  {
    "port": "PUERTO CALDERA", 
    "country": "Costa Rica" 
  },
  {
    "port": "PUERTO QUETZAL", 
    "country": "Guatemala" 
  },
  {
    "port": "QESHM", 
    "country": "Iran" 
  },
  {
    "port": "QINGDAO", 
    "country": "China" 
  },
  {
    "port": "QINHUANGDAO", 
    "country": "China" 
  },
  {
    "port": "QINZHOU", 
    "country": "China" 
  },
  {
    "port": "QUI NHON", 
    "country": "Vietnam" 
  },
  {
    "port": "RABAUL", 
    "country": "Papua New Guinea" 
  },
  {
    "port": "RADES", 
    "country": "Tunisia" 
  },
  {
    "port": "REUNION", 
    "country": "South Africa" 
  },
  {
    "port": "RIJEKA", 
    "country": "Croatia" 
  },
  {
    "port": "RIO DE JANEIRO", 
    "country": "Brazil" 
  },
  {
    "port": "RIO GRANDE", 
    "country": "Brazil" 
  },
  {
    "port": "RIO HAINA", 
    "country": "Dominican Republic" 
  },
  {
    "port": "RIYADH", 
    "country": "Saudi Arabia" 
  },
  {
    "port": "RIZHAO", 
    "country": "China" 
  },
  {
    "port": "ROATAN", 
    "country": "Honduras" 
  },
  {
    "port": "ROTTERDAM", 
    "country": "Nethelands" 
  },
  {
    "port": "SAIPAN", 
    "country": "U.S. Northern Mariana Islands" 
  },
  {
    "port": "SAKAIMINATO", 
    "country": "Japan" 
  },
  {
    "port": "SAKATA", 
    "country": "Japan" 
  },
  {
    "port": "SALALAH", 
    "country": "Oman" 
  },
  {
    "port": "SAMARINDA", 
    "country": "Indonesia" 
  },
  {
    "port": "SAN ANTONIO", 
    "country": "Chile" 
  },
  {
    "port": "SAN JOSE(CRC)", 
    "country": "Costa Rica" 
  },
  {
    "port": "SAN JUAN", 
    "country": "Puerto Rico" 
  },
  {
    "port": "SANDAKAN", 
    "country": "Malaysia" 
  },
  {
    "port": "SANSHUI", 
    "country": "China" 
  },
  {
    "port": "SANTO", 
    "country": "Vanuatu" 
  },
  {
    "port": "SANTOS", 
    "country": "Brazil" 
  },
  {
    "port": "SATSUMASENDAI", 
    "country": "Japan" 
  },
  {
    "port": "SAVANNAH", 
    "country": "US Georgia" 
  },
  {
    "port": "SEATTLE", 
    "country": "US Washington" 
  },
  {
    "port": "SEMARANG", 
    "country": "Indonesia" 
  },
  {
    "port": "SENBOKU", 
    "country": "Japan" 
  },
  {
    "port": "SENDAI", 
    "country": "Japan" 
  },
  {
    "port": "SHANGHAI", 
    "country": "China" 
  },
  {
    "port": "SHANTOU", 
    "country": "China" 
  },
  {
    "port": "SHARJAH", 
    "country": "U.A.E." 
  },
  {
    "port": "SHATIAN", 
    "country": "China" 
  },
  {
    "port": "SHEKOU", 
    "country": "China" 
  },
  {
    "port": "SHIBUSHI", 
    "country": "Japan" 
  },
  {
    "port": "SHIDAO", 
    "country": "China" 
  },
  {
    "port": "SHIMIZU", 
    "country": "Japan" 
  },
  {
    "port": "SHIMONOSEKI", 
    "country": "Japan" 
  },
  {
    "port": "SHUAIBA", 
    "country": "Kuwait" 
  },
  {
    "port": "SHUWAIKH", 
    "country": "Kuwait" 
  },
  {
    "port": "SIBU", 
    "country": "Malaysia" 
  },
  {
    "port": "SIHANOUKVILLE", 
    "country": "Cambodia" 
  },
  {
    "port": "SINES", 
    "country": "Portugal" 
  },
  {
    "port": "SINGAPORE", 
    "country": "Singpore" 
  },
  {
    "port": "SOHAR", 
    "country": "Oman" 
  },
  {
    "port": "SOKHNA", 
    "country": "Egypt" 
  },
  {
    "port": "SONGKHLA", 
    "country": "Thailand" 
  },
  {
    "port": "SOUTHAMPTON", 
    "country": "United Kingdom" 
  },
  {
    "port": "ST. PETERSBURG", 
    "country": "Russia" 
  },
  {
    "port": "SUBIC", 
    "country": "Philippines" 
  },
  {
    "port": "SUBIC BAY", 
    "country": "Philippines" 
  },
  {
    "port": "SURABAYA", 
    "country": "Indonesia" 
  },
  {
    "port": "SUVA", 
    "country": "Fiji" 
  },
  {
    "port": "SYDNEY", 
    "country": "Australia" 
  },
  {
    "port": "TACOMA", 
    "country": "US Washington" 
  },
  {
    "port": "TAICANG", 
    "country": "China" 
  },
  {
    "port": "TAICHUNG", 
    "country": "Taiwan" 
  },
  {
    "port": "TAIPEI", 
    "country": "Taiwan" 
  },
  {
    "port": "TAIPING", 
    "country": "China" 
  },
  {
    "port": "TAKAMATSU", 
    "country": "Japan" 
  },
  {
    "port": "TAMATAVE", 
    "country": "Madagascar" 
  },
  {
    "port": "TANJUNG PELEPAS", 
    "country": "Malaysia" 
  },
  {
    "port": "TAO YUAN", 
    "country": "Taiwan" 
  },
  {
    "port": "TARAWA", 
    "country": "Kiribati" 
  },
  {
    "port": "TAURANGA", 
    "country": "New Zealand" 
  },
  {
    "port": "TAWAU", 
    "country": "Malaysia" 
  },
  {
    "port": "TEKIRDAG", 
    "country": "Turkey" 
  },
  {
    "port": "TEMA", 
    "country": "Ghana" 
  },
  {
    "port": "THESSALONIKI", 
    "country": "Greece" 
  },
  {
    "port": "TIANJIN", 
    "country": "China" 
  },
  {
    "port": "TIMARU", 
    "country": "New Zealand" 
  },
  {
    "port": "TINCAN", 
    "country": "Nigeria" 
  },
  {
    "port": "TOKUSHIMA", 
    "country": "Japan" 
  },
  {
    "port": "TOKUYAMA", 
    "country": "Japan" 
  },
  {
    "port": "TOYKO", 
    "country": "Japan" 
  },
  {
    "port": "TOMAKOMAI", 
    "country": "Japan" 
  },
  {
    "port": "TONGLING", 
    "country": "China" 
  },
  {
    "port": "TORONTO", 
    "country": "Canada" 
  },
  {
    "port": "TOWNSVILLE", 
    "country": "Australia" 
  },
  {
    "port": "TOYAMA", 
    "country": "Japan" 
  },
  {
    "port": "TOYAMA SHINKO", 
    "country": "Japan" 
  },
  {
    "port": "TOYOHASHI", 
    "country": "Japan" 
  },
  {
    "port": "TRIESTE", 
    "country": "Italy" 
  },
  {
    "port": "TSURUGA", 
    "country": "Japan" 
  },
  {
    "port": "TUTICORIN", 
    "country": "India" 
  },
  {
    "port": "ULAN BATOR", 
    "country": "Mongolia" 
  },
  {
    "port": "UMM QASR", 
    "country": "Iraq" 
  },
  {
    "port": "VALENCIA", 
    "country": "Spain" 
  },
  {
    "port": "VALPARAISO", 
    "country": "Chile" 
  },
  {
    "port": "VANCOUVER", 
    "country": "US Washington" 
  },
  {
    "port": "VANCOUVER B.C.", 
    "country": "Canada" 
  },
  {
    "port": "VARNA", 
    "country": "Bulgaria" 
  },
  {
    "port": "VENICE", 
    "country": "Italy" 
  },
  {
    "port": "VIENTIANE", 
    "country": "Laos" 
  },
  {
    "port": "VILA", 
    "country": "Vanuatu" 
  },
  {
    "port": "VIZAG", 
    "country": "India" 
  },
  {
    "port": "VLADIVOSTOK", 
    "country": "Russia" 
  },
  {
    "port": "VOSTOCHNY", 
    "country": "Russia" 
  },
  {
    "port": "VUNG TAU", 
    "country": "Vietnam" 
  },
  {
    "port": "WEIFANG", 
    "country": "China" 
  },
  {
    "port": "WEIHAI", 
    "country": "China" 
  },
  {
    "port": "WELLINGTON", 
    "country": "New Zealand" 
  },
  {
    "port": "WILHELMSHAVEN", 
    "country": "Germany" 
  },
  {
    "port": "WILLIS", 
    "country": "Australia" 
  },
  {
    "port": "WILMINGTON(DEL)", 
    "country": "US Connecticut" 
  },
  {
    "port": "WUHAN", 
    "country": "China" 
  },
  {
    "port": "WUHU", 
    "country": "China" 
  },
  {
    "port": "XIAMEN", 
    "country": "China" 
  },
  {
    "port": "XINGANG", 
    "country": "China" 
  },
  {
    "port": "YANGON", 
    "country": "China" 
  },
  {
    "port": "YANGZHOU", 
    "country": "China" 
  },
  {
    "port": "YANTAI", 
    "country": "China" 
  },
  {
    "port": "YAP", 
    "country": "Micronesia" 
  },
  {
    "port": "YARIMCA", 
    "country": "Turkey" 
  },
  {
    "port": "YATSUSHIRO", 
    "country": "Japan" 
  },
  {
    "port": "YICHANG", 
    "country": "China" 
  },
  {
    "port": "YINGKOU", 
    "country": "China" 
  },
  {
    "port": "YOKKAICHI", 
    "country": "Japan" 
  },
  {
    "port": "YOKOHAMA", 
    "country": "Japan" 
  },
  {
    "port": "ZANZIBAR", 
    "country": "Tanzania" 
  },
  {
    "port": "ZEEBRUGGE", 
    "country": "Belgium" 
  },
  {
    "port": "ZHANGJIAGANG", 
    "country": "China" 
  },
  {
    "port": "ZHENJIANG", 
    "country": "China" 
  },
  {
    "port": "ZHONGSHAN", 
    "country": "China" 
  },
  {
    "port": "ZHUHAI", 
    "country": "China" 
  },
  {
    "port": "BUSAN", 
    "country": "Korea" 
  },
  {
    "port": "INCHEON", 
    "country": "Korea" 
  },
  {
    "port": "GWANGYANG", 
    "country": "Korea" 
  },
  {
    "port": "PYEONGTAEK", 
    "country": "Korea" 
  },
  {
    "port": "ULSAN", 
    "country": "Korea" 
  },
  {
    "port": "DAESAN", 
    "country": "Korea" 
  },
  {
    "port": "GUNSAN", 
    "country": "Korea" 
  }
]

export default PortList