import { ProductResponse, useGetProductList } from '@/API/productAPI'
import {
  Flex,
  Heading,
  Button,
  Text,
  Divider,
  Box,
  useMediaQuery,
} from '@chakra-ui/react'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const CategoryBest = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate()
  const [bestProducts, setBestProducts] = useState<ProductResponse[] | []>([])
  const [categoryList, setCategoryList] = useState<any[] | null>(null)
  const [categoryNum, setCategoryNum] = useState<string>('1')

  const {
    data: bestProductList,
    isLoading,
    refetch,
  } = useGetProductList({ best: 'true', category: categoryNum }, true)

  const resetDataAfterSetCategoryNum = (categoryNo: number) => {
    setCategoryNum(categoryNo.toString())
  }

  useLayoutEffect(() => {
    if (bestProductList) {
      setBestProducts(bestProductList?.results)
      if (bestProductList?.popular_categories) {
        setCategoryList(
          Array.from([['전체', 1]]).concat(bestProductList?.popular_categories),
        )
      }
    }
  }, [bestProductList])

  useEffect(() => {
    if (categoryNum) {
      refetch()
    }
  }, [categoryNum])

  // useState setList ('전체' 포함)

  const bestCategoryList = () => {
    return (
      <Flex
        gap={4}
        justifyContent={isMobile ? 'flex-start' : 'right'}
        alignItems="center"
        overflowX="auto"
        whiteSpace="nowrap"
        w="100%"
      >
        {categoryList?.map((category, idx) => {
          return (
            <Flex key={idx} gap={4} alignItems="center">
              {/* <Text fontWeight="bold">전체</Text> */}
              {idx !== 0 && (
                <Divider
                  orientation="vertical"
                  borderColor="#7F8589"
                  height="18px"
                />
              )}
              <Button
                variant="unstyled"
                fontWeight={600}
                fontSize={isMobile ? 'md' : '18px'}
                onClick={() => resetDataAfterSetCategoryNum(category[1])}
              >
                <Text color={idx === 0 ? 'black' : '#7F8589'} fontWeight={600}>
                  {category[0]}
                </Text>
              </Button>
              {idx + 1 === categoryList?.length && (
                <Divider
                  orientation="vertical"
                  borderColor="#7F8589"
                  height="18px"
                />
              )}
            </Flex>
          )
        })}
      </Flex>
    )
  }

  const innerProductFrame = (product: ProductResponse) => {
    const thumbnailImageURL = product.thumbnail
      ? `${product.thumbnail}`
      : '/example/iphone.jpeg'
    return (
      <Flex
        w="278px"
        h="278px"
        border="1px solid #EDEDED"
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        onClick={() => navigate(`/product/${product.id}`)}
      >
        <Box
          w="270px"
          h="226px"
          backgroundImage={thumbnailImageURL}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="contain"
        />
      </Flex>
    )
  }

  return (
    <Flex
      id="category-best"
      py={20}
      px={isMobile ? 6 : '10%'}
      maxH="1080px"
      minW="100vw"
    >
      <Flex
        flexDirection="column"
        gap={isMobile ? 4 : 6}
        justifyContent="center"
        w="full"
      >
        <Flex flexDirection="column" gap={2} pb={2}>
          <Text
            fontSize={isMobile ? '12px' : '18px'}
            color="#7F8589"
            fontWeight={600}
          >
            PRODUCT
          </Text>
          <Heading color="#004A8D" fontSize={isMobile ? '34px' : '50px'}>
            Category Best
          </Heading>
        </Flex>
        <Button
          border="1.5px solid #7F8589"
          borderRadius="60px"
          py="20px"
          px="40px"
          w={isMobile ? '166px' : '217px'}
          h={isMobile ? '45px' : '56px'}
          onClick={() => navigate('/product/list')}
        >
          <Text color="#7F8589">전체 상품 보러가기</Text>
        </Button>
        <Flex flexDirection="column" gap={10} py={isMobile ? 8 : 0}>
          {bestCategoryList()}
          <Flex gap={8} w="100%" overflowX="auto">
            {bestProducts.length > 0
              ? bestProducts.map((product, idx) => {
                  return (
                    <Flex flexDirection="column" key={idx}>
                      {innerProductFrame(product)}
                      <Flex flexDirection="column" pt={2} pb={8} gap={1}>
                        <Text color="#7F8589" fontSize="14px" fontWeight="600">
                          {`${product.company}`}
                        </Text>
                        <Text fontWeight="600">{`${product.name}`}</Text>
                      </Flex>
                    </Flex>
                  )
                })
              : !isLoading && (
                  <Flex
                    w="full"
                    h="367px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    제품이 없습니다.
                  </Flex>
                )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CategoryBest
