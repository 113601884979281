import { Flex, Heading, Image, Text, useMediaQuery } from '@chakra-ui/react'

const Mission = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  return (
    <Flex
      id="mission"
      py={20}
      pb={isMobile ? 14 : 20}
      px={isMobile ? 6 : '10%'}
      minW="100vw"
      bgColor="#F7F7F7"
      flexDirection="column"
      gap={isMobile ? 6 : 0}
    >
      <Flex flexDirection="column" gap={6} justifyContent="center">
        <Flex flexDirection="column" gap={2} pb={2}>
          <Text
            fontSize={isMobile ? '12px' : '18px'}
            color="#7F8589"
            fontWeight={600}
          >
            OUR MISSION
          </Text>
          <Heading
            color="#004A8D"
            fontSize={isMobile ? '34px' : '50px'}
            lineHeight={isMobile ? '47.6px' : '72.8px'}
            wordBreak="keep-all"
          >
            국내 기업의 수출을 <br />
            성장과 혁신으로 이끄는 엑스고
          </Heading>
          <Text
            fontSize={isMobile ? '16px' : '22px'}
            fontWeight="600"
            color="#004A8D"
            py={2}
          >
            Empowering domestic enterprises' exports through growth and
            innovation
          </Text>
        </Flex>
      </Flex>
      <Flex justifyContent="center">
        <Image
          w={isMobile ? '350px' : '750px'}
          h={isMobile ? '333px' : '750px'}
          src="/menu/mission.png"
        />
      </Flex>
    </Flex>
  )
}

export default Mission
