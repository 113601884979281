import {
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import Service from '@/pages/main/Service'
import Partners from '@/pages/main/Partners'
import CategoryBest from '@/pages/main/CategoryBest'
import FAQ from '@/pages/main/FAQ'
import MainLayout from '@/components/MainLayout'
import Registration from '@/components/product/Registration'
import { homeTabContents } from '@/lib/PageContents'
import { useNavigate } from 'react-router-dom'

function Home() {
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const returnTabPanels = () => {
    return homeTabContents(isMobile).map((eachTab, idx) => {
      const splitTitle = eachTab.title.split('.')
      const splitSubTitle = eachTab.subTitle.split('.')
      return (
        <TabPanel
          key={idx}
          className="main-background"
          display="flex"
          alignItems="center"
          m="0"
          h={isMobile ? '667px' : '1080px'}
          backgroundImage={`/main/main_${idx + 1}.jpeg`}
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
          w="full"
          px={isMobile ? 6 : '10%'}
        >
          <Flex flexDirection="column" gap={6} zIndex={4}>
            <Flex flexDirection="column">
              {splitTitle.map((title, i) => {
                return (
                  <Flex
                    color="white"
                    key={i}
                    fontSize={isMobile ? '32px' : '60px'}
                    fontWeight="600"
                    lineHeight={isMobile ? '43.2px' : '90px'}
                    whiteSpace={isMobile ? 'normal' : 'pre-line'}
                  >
                    {title}
                  </Flex>
                )
              })}
            </Flex>
            <Flex flexDirection="column">
              {splitSubTitle.map((subTitle, i) => {
                return (
                  <Flex
                    color="white"
                    key={i}
                    fontSize={isMobile ? 'md' : '26px'}
                    fontWeight="600"
                    lineHeight={isMobile ? '22.4px' : '60px'}
                  >
                    {subTitle}
                  </Flex>
                )
              })}
            </Flex>
            <Button
              bgColor="transparent"
              color="white"
              borderRadius="60px"
              w="217px"
              h="56px"
              border="1.5px solid white"
              my={isMobile ? 4 : 8}
              onClick={() => navigate('/service/contact')}
            >
              지금 바로 문의하기
            </Button>
          </Flex>
        </TabPanel>
      )
    })
  }
  return (
    <MainLayout>
      <Flex
        h={isMobile ? 'auto' : '1080px'}
        bgColor="#4A637B"
        alignItems="center"
        flexDirection="column"
        minW="100vw"
        minH={isMobile ? '667px' : 'auto'}
        maxH="1080px"
      >
        <Tabs
          display="flex"
          w="full"
          h="full"
          justifyContent="center"
          flexDirection="column"
          position="relative"
        >
          <TabPanels transition="all0.3s ease-in-out">
            {returnTabPanels()}
          </TabPanels>
          <Flex w="full" justifyContent="center">
            {/* 메인 페이지 회사 소개글 바로 밑, 페이지 컨테이너 */}
            <TabList
              w="150px"
              position="absolute"
              bottom={isMobile ? '2rem' : '3rem'}
              justifyContent="center"
              alignItems="flex-end"
            >
              <Tab
                w="50px"
                borderBottom="3px solid"
                borderColor="#7F8589"
                _selected={{ borderColor: 'white' }}
              ></Tab>
              <Tab
                w="50px"
                borderBottom="3px solid"
                borderColor="#7F8589"
                _selected={{ borderColor: 'white' }}
              ></Tab>
              <Tab
                w="50px"
                borderBottom="3px solid"
                borderColor="#7F8589"
                _selected={{ borderColor: 'white' }}
              ></Tab>
            </TabList>
          </Flex>
        </Tabs>
      </Flex>
      <Service />
      <Partners />
      <CategoryBest />
      <FAQ />
      <Registration isProductPage={false} />
    </MainLayout>
  )
}

export default Home
