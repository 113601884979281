import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/index.css'
import { RouterProvider } from 'react-router-dom'
import theme from '@/lib/theme'
import { ChakraProvider } from '@chakra-ui/react'
import router from '@/router/router'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from '@/API/queryClient'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)
