import {
  NoticeResponseWithPagination,
  useGetNoticeList,
} from '@/API/serviceAPI'
import _ from 'lodash'
import { useEffect, useLayoutEffect, useState } from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'

const NoticeLayout = () => {
  const INITIAL_PAGE = 1
  const INITIAL_PAGINATION_PAGE = 1

  const [searchParams] = useSearchParams()
  const searchText = searchParams.get('search') || ''

  const [paginationPage, setPaginationPage] = useState<number>(
    INITIAL_PAGINATION_PAGE,
  )
  const [maxPageNumber, setMaxPageNumber] = useState<number>(0)
  const [pageList, setPageList] = useState<number[]>([])
  const [page, setPage] = useState(INITIAL_PAGE)

  const [noticeList, setNoticeList] =
    useState<NoticeResponseWithPagination | null>(null)

  const { data: noticeListData, refetch: refetchNoticeList } =
    useGetNoticeList(page, searchText)

  const setPageListByData = (data: any, pageSize: number) => {
    const MAX_PAGE_NUMBER = Math.ceil(data && data.count / pageSize)
    const START_PAGE_NUMBER = (paginationPage - 1) * 10 + 1
    const TEMP_END_PAGE_NUMBER = paginationPage * 10
    const END_PAGE_NUMBER =
      MAX_PAGE_NUMBER < TEMP_END_PAGE_NUMBER
        ? MAX_PAGE_NUMBER
        : TEMP_END_PAGE_NUMBER
    setPageList(_.range(START_PAGE_NUMBER, END_PAGE_NUMBER + 1))
    setMaxPageNumber(MAX_PAGE_NUMBER)
  }

  useEffect(() => {
    if (page) {
      const PAGE_STRING_ARRAY = page.toString().split('')
      const PAGE_LAST_DIGIT = PAGE_STRING_ARRAY[PAGE_STRING_ARRAY.length - 1]
      const PAGE_EXCEPT_LAST_DIGIT = page
        .toString()
        .substring(0, page.toString().length - 1)
      if (page > 10 && PAGE_LAST_DIGIT === '1') {
        // increase Pagination
        setPaginationPage(Number(PAGE_EXCEPT_LAST_DIGIT) + 1)
      } else if (PAGE_LAST_DIGIT === '0') {
        // decrease Pagination
        setPaginationPage(Number(PAGE_EXCEPT_LAST_DIGIT))
      }
      refetchNoticeList()
    } else if (page === 0) {
      // fetch failed & no data
      setPage(INITIAL_PAGE)
    }
  }, [page])

  useLayoutEffect(() => {
    if (noticeListData) {
      setNoticeList(noticeListData)
      setPageListByData(noticeListData, 10)
    }
  }, [noticeListData])

  return (
    <>
      <Outlet
        context={{ page, setPage, maxPageNumber, pageList, noticeList }}
      />
    </>
  )
}

export default NoticeLayout
