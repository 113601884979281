import { SearchIcon, TriangleDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  chakra,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useMediaQuery,
  Divider,
} from '@chakra-ui/react'
import _ from 'lodash'
import { FaBell } from 'react-icons/fa6'
import Pagination from '@/components/pagination/Pagination'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { ReferenceResponseWithPagination } from '@/API/referenceAPI'
import { toLocalDateString } from '@/util/util'
import { useState } from 'react'

interface ContextType {
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  maxPageNumber: number
  pageList: number[]
  referenceList: ReferenceResponseWithPagination | null
}

const Reference = () => {
  const [searchText, setSearchText] = useState('')
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate()

  const { page, setPage, maxPageNumber, pageList, referenceList } =
    useOutletContext<ContextType>()

  const BellIcon = chakra(FaBell)

  const returnTableBody = () => {
    return referenceList?.results?.map((result, i) => {
      return (
        <Tr
          key={i}
          cursor="pointer"
          onClick={() => navigate(`/reference/${result.id}`)}
        >
          <Td>
            <Flex justifyContent="center" alignItems="center">
              {/* todo: is_pinned 공지사항은 고정 */}
              {result.is_pinned ? (
                <BellIcon color="#004A8D" fontSize="21px" />
              ) : (
                i + 1
              )}
            </Flex>
          </Td>
          <Td className={`${result.is_pinned ? 'td-reference' : ''} td-title`}>
            {result.title}
          </Td>
          <Td>{toLocalDateString(result.created_at)}</Td>
          <Td>{result.view_count}</Td>
        </Tr>
      )
    })
  }

  const returnMobileFlexTag = () => {
    return referenceList?.results?.map((result, i) => {
      return (
        <Flex
          key={i}
          px={4}
          h="72px"
          flexDirection="column"
          borderBottom={'1px solid #EDEDED'}
          justifyContent="center"
          gap={1}
          onClick={() => navigate(`/reference/${result.id}`)}
        >
          <Flex w="full" justifyContent="space-between">
            <Flex gap={2}>
              {result.is_pinned && <BellIcon color="#004A8D" fontSize="18px" />}
              <Text fontSize="12px">
                {toLocalDateString(result.created_at)}
              </Text>
            </Flex>
            <Text fontSize="12px">{result.view_count}</Text>
          </Flex>
          <Flex
            color="#01111E"
            fontSize="14px"
            fontWeight={result.is_pinned ? 600 : 500}
          >
            {result.title}
          </Flex>
        </Flex>
      )
    })
  }

  return (
    <>
      <Flex
        px={isMobile ? 6 : '10%'}
        py={isMobile ? 12 : 16}
        flexDirection="column"
        gap={isMobile ? 8 : 12}
      >
        {/* 제목 select 란 + 검색 Input */}
        <Flex w="full" gap={2}>
          <Flex
            w="137px"
            border="1px solid"
            borderColor="#EDEDED"
            justifyContent="center"
          >
            <Select
              w="102px"
              h={isMobile ? '48px' : '58px'}
              fontSize="md"
              textAlign="left"
              border={0}
              icon={
                <TriangleDownIcon
                  w="full"
                  fontSize="8px"
                  color="#787E88 !important"
                />
              }
            >
              <option value="title">제목</option>
            </Select>
          </Flex>
          <InputGroup w="max-content" display="flex" alignItems="center">
            <Input
              w={isMobile ? 'full' : '436px'}
              h={isMobile ? '50px' : '60px'}
              border="1px solid"
              borderColor="#EDEDED"
              borderRadius={0}
              fontSize="18px"
              fontWeight={500}
              textAlign="center"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
            <InputRightElement w="60px" h={isMobile ? '50px' : '60px'}>
              <Button variant="unstyled" onClick={() => {
                setPage(1)
                navigate(`/reference?search=${searchText}`)
              }}>
                <SearchIcon color="#004A8D" fontSize="18px" />
              </Button>
            </InputRightElement>
          </InputGroup>
        </Flex>
        {/* 게시판 화면 */}
        <Flex flexDirection={isMobile ? 'column' : 'row'}>
          {isMobile ? (
            <>
              <Divider borderColor="#004A8D" opacity={1} />
              {returnMobileFlexTag()}
            </>
          ) : (
            <Table>
              <Thead
                zIndex={1}
                borderTop="1px solid #004A8D"
                borderBottom="1px solid #CCCDCE"
                h="64px"
              >
                <Tr>
                  <Th w="195px">번호</Th>
                  <Th>제목</Th>
                  <Th w="250px">작성일</Th>
                  <Th w="140px">조회수</Th>
                </Tr>
              </Thead>
              <Tbody>{returnTableBody()}</Tbody>
            </Table>
          )}
        </Flex>
        <Flex>
          <Pagination
            page={page}
            setPage={setPage}
            maxPageNumber={maxPageNumber}
            pageList={pageList}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default Reference
