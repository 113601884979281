import { AxiosError } from 'axios'
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import axiosInstance from '@/API/http'
import { ErrorResponseData } from '@/util/util'

export interface ReferenceResponseWithPagination {
  links?: {
    next?: string
    previous?: string
  }
  count: number
  page_count: number
  page_number: number
  page_size: number
  results: ReferenceContent[]
}

export interface ReferenceContent {
  id: number
  previous_id?: number
  next_id?: number
  article_number: number
  title: string
  content: string
  created_at: string
  view_count: number
  is_pinned: boolean
}


const useGetReferenceList = (
  page: number,
  searchText?: string
): UseQueryResult<
  ReferenceResponseWithPagination,
  AxiosError<ErrorResponseData>
> => {
  return useQuery({
    queryKey: ['getReferenceList', page, searchText],
    queryFn: async () => {
      let uri = `/article/reference/?page=${page}`
      if(searchText) uri += `&title=${searchText}`
      const response = await axiosInstance.get(uri)
      return response.data
    },
  })
}

const useGetReference = (
  articleNum: string | undefined,
): UseQueryResult<ReferenceContent, AxiosError<ErrorResponseData>> => {
  return useQuery({
    queryKey: ['getReference'],
    queryFn: async () => {
      if (articleNum) {
        const response = await axiosInstance.get(
          `/article/reference/${articleNum}`,
        )
        return response.data
      }
    },
  })
}

export { useGetReferenceList, useGetReference }
