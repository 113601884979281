import { SearchIcon } from '@chakra-ui/icons'
import {
  Flex,
  Divider,
  InputGroup,
  Input,
  InputRightElement,
  Text,
  Select,
  useMediaQuery,
} from '@chakra-ui/react'
import AccordionCategory from '@/components/category/AccordionCategory'
import {
  ProductRequest,
  ProductResponse,
  ProductResponseWithPagination,
} from '@/API/productAPI'
import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import ProductGrid from './ProductGrid'

interface ContextType {
  productListData: ProductResponseWithPagination | null
  isLoading: boolean
  URLParams: Partial<ProductRequest>
  setURLParams: React.Dispatch<React.SetStateAction<Partial<ProductRequest>>>
}

const ProductList = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  // 원본 리스트
  const [productList, setProductList] = useState<ProductResponse[] | null>(null)

  // 실제 표출 리스트
  const [productFilteredList, setProductFilteredList] = useState<ProductResponse[] | null>(null);
  
  // 결과 내 검색 - 검색어
  const [searchKeyword, setSearchKeyword] = useState<string>('')

  const { productListData, isLoading, URLParams, setURLParams } =
    useOutletContext<ContextType>()

  useEffect(() => {
    if (productListData) {
      setProductList(productListData.results)
      setProductFilteredList(productListData.results)
    }
  }, [productListData])


  const toggleSearch = () => {
    if(searchKeyword) {
      const filteredProducts = productList?.filter((product: ProductResponse) => 
        product.company.includes(searchKeyword) || product.name.includes(searchKeyword)
      )
      setProductFilteredList(filteredProducts ?? null)
    } else {
      setProductFilteredList(productList)
    }
  }


  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if(productFilteredList) {
      const sortValue = event.target.value;
      const sortedList: ProductResponse[] = [...productFilteredList];


      switch (sortValue) {
        case 'name':
          sortedList.sort((a, b) => a.name.localeCompare(b.name)); // 이름 순으로 정렬
          break;
        case 'company':
          sortedList.sort((a, b) => a.company.localeCompare(b.company)); // 회사 이름 순으로 정렬
          break;
        case 'latest':
          sortedList.sort((a, b) => b.id - a.id); // id 기준으로 최신 순 정렬 (id가 높을수록 최신)
          break;
        default: 
          break;
      }
    
      setProductFilteredList(sortedList); // 정렬된 리스트 상태 업데이트
    }
  };

  
  return (
    <Flex
      w="full"
      justifyContent="center"
    >
      <Flex
        w="full"
        maxW="1500px"
        px={4}
        py={isMobile ? 4 : 10}
        gap={isMobile ? 0 : 8}
        flexDirection={isMobile ? 'column' : 'row'}
        pt={isMobile ? 0 : 6}
      >
        <AccordionCategory
          isSearchPage={false}
          URLParams={URLParams}
          setURLParams={setURLParams}
        />
        <Flex 
          w="full" 
          flexDirection="column" 
          gap={2} 
          overflow="auto"
          overflowX="hidden"
        >
          <Text fontWeight="bold" py={2}>
            상품 {`(${productList?.length || 0})`}
          </Text>
          <Divider borderWidth="1px" borderColor="black" opacity={1} mb={2} />
          <Flex px={1} justifyContent="space-between" alignItems="center">
            <InputGroup w="max-content" display="flex" alignItems="center">
              <Input
                w="223px"
                h="40px"
                bgColor="#F7F7F7"
                borderWidth={0}
                borderRadius="50px"
                placeholder="결과 내 검색"
                _placeholder={{ color: '#CCCDCE' }}
                value={searchKeyword || ''}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <InputRightElement mx={3} h="full">
                <SearchIcon color="#004A8D" fontSize="18px" onClick={toggleSearch}/>
              </InputRightElement>
            </InputGroup>
            <Select
              w="102px"
              h="30px"
              borderRadius={0}
              border="none"
              borderBottom="1px solid #7F8589"
              iconColor="#7F8589"
              onChange={handleSelectChange}
            >
              <option>정렬기준</option>
              <option value="latest">최신순</option>
              <option value="name">가나다순</option>
              <option value="company">회사이름순</option>
            </Select>
          </Flex>
          <ProductGrid
            productList={productFilteredList}
            isLoading={isLoading}
            isSearchPage={false}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProductList
