import { Button, Divider, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import AccordionCategory from '@/components/category/AccordionCategory'
import {
  ProductRequest,
  ProductResponse,
  ProductResponseWithPagination,
  useGetPopularKeywordList,
  useGetProductList,
} from '@/API/productAPI'
import { useEffect, useState } from 'react'
import ProductGrid from './ProductGrid'
import { useNavigate, useOutletContext } from 'react-router-dom'
import useKeywordStore from '@/store/KeywordStore'
import { selector } from '@/util/util'
import { useShallow } from 'zustand/react/shallow'

interface ContextType {
  productListData: ProductResponseWithPagination | null
  isLoading: boolean
  URLParams: Partial<ProductRequest>
  setURLParams: React.Dispatch<React.SetStateAction<Partial<ProductRequest>>>
}

const ProductSearch = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const [productList, setProductList] = useState<ProductResponse[] | null>(null)
  const [popularKeywords, setPopularKeywords] = useState<string[]>([])

  const navigate = useNavigate()

  const { productListData, isLoading, URLParams, setURLParams } =
    useOutletContext<ContextType>()

  const { data: popularKeywordList } = useGetPopularKeywordList()
  const { setKeyword } = useKeywordStore(useShallow(selector))

  const navigateProductListPageWithKeyword = (itemName: string) => {
    setKeyword(itemName)
    navigate(`/product/list`)
  }

  useEffect(() => {
    if (productListData) {
      setProductList(productListData.results)
    }
  }, [productListData])

  useEffect(() => {
    if (popularKeywordList) {
      setPopularKeywords(popularKeywordList)
    }
  }, [popularKeywordList])

  const returnPopularSearch = () => {
    return popularKeywords?.map((item, idx) => {
      return (
        <Button
          key={idx}
          h="40px"
          minW="83px"
          border="1px solid #EDEDED"
          borderRadius="50px"
          onClick={() => navigateProductListPageWithKeyword(item)}
        >
          <Text fontWeight={600} color="#7F8589" px="5px">
            {item}
          </Text>
        </Button>
      )
    })
  }

  return (
    <Flex
      w="full"
      justifyContent="center"
    >
      {/* Product Search End */}
      {/* Catgegory & Product Result Start */}
      <Flex
        w="full"
        maxW="1500px"
        px={4}
        py={10}
        gap={isMobile ? 0 : 8}
        pt={isMobile ? 0 : 6}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {/* 카테고리 */}
        <AccordionCategory
          isSearchPage={true}
          URLParams={URLParams}
          setURLParams={setURLParams}
        />

        <Flex
          w="full"
          flexDirection="column"
          gap={2}
          maxW="-webkit-fill-available"
          overflowX="hidden"
        >
          <Text fontWeight="bold" py={2}>
            인기 검색어
          </Text>
          <Divider borderWidth="1px" borderColor="black" opacity={1} mb={2} />
          <Flex flexDirection="column">
            {/* 인기 검색어 Row */}
            <Flex gap={2} minH="50px" overflowX="auto">
              {returnPopularSearch()}
            </Flex>

            {/* 상품 리스트 */}
            <ProductGrid
              productList={productList}
              isLoading={isLoading}
              isSearchPage={true}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ProductSearch
