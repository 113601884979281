import { AxiosError } from 'axios'
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import axiosInstance from '@/API/http'
import { ErrorResponseData, partialData } from '@/util/util'

export interface ProductRequest {
  id?: number
  category?: string
  search?: string
  best?: string
}

export interface ProductResponseWithPagination {
  links?: {
    next?: string
    previous?: string
  }
  count: number
  page_count: number
  page_number: number
  page_size: number
  results: ProductResponse[]
  popular_categories?: any[]
}

export interface ProductRegisterRequest {
  company_name: string
  contact_name: string
  category: number
  phone_number: string
  product_name: string
  email: string
  description: string
  files: File[]
}

export interface ProductResponse {
  id: number
  name: string
  country_of_origin?: string
  moq: number
  category?: string[]
  company: string
  keywords?: [keyword: string]
  description?: string
  thumbnail?: string
  related_products?: RelatedProduct[]
  thumbnails: [thumbnail: string]
  content?: string
}

export interface RelatedProduct {
  id: number
  name: string
  moq: number
  company: string
  thumbnail?: string
}

export interface CategoryList {
  id?: number
  sub_categories?: CategoryList[]
  name: string
  parent?: number
}

export const isAllRequestParams = (
  params: Partial<ProductRegisterRequest>,
): params is ProductRegisterRequest => {
  return (
    typeof params.company_name === 'string' &&
    typeof params.contact_name === 'string' &&
    typeof params.email === 'string' &&
    typeof params.phone_number === 'string' &&
    typeof params.product_name === 'string' &&
    typeof params.description === 'string'
  )
}

const useGetCategoryList = (): UseQueryResult<
  CategoryList[],
  AxiosError<ErrorResponseData>
> => {
  return useQuery({
    queryKey: ['getCategoryList'],
    queryFn: async () => {
      const response = await axiosInstance.get(`/product/categories/`)
      return response.data
    },
  })
}

const useGetProductList = (
  params?: Partial<ProductRequest>,
  enabled?: boolean,
  // options?: UseQueryOptions
): UseQueryResult<
  ProductResponseWithPagination,
  AxiosError<ErrorResponseData>
> => {
  const filteredParams = partialData(params)
  let queryString: string
  if (filteredParams !== null) {
    queryString = new URLSearchParams(filteredParams as any).toString()
  }
  return useQuery({
    queryKey: ['getProductList'],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `/product/${queryString ? `?${queryString}` : ''}`,
      )
      return response.data
    },
    enabled: enabled,
  })
}

const useGetPopularKeywordList = (): UseQueryResult<
  string[],
  AxiosError<ErrorResponseData>
> => {
  return useQuery({
    queryKey: ['getPopularKeywordList'],
    queryFn: async () => {
      const response = await axiosInstance.get(`/product/popular_keyword/`)
      return response.data
    },
  })
}

const useGetPopularProductList = (
  enabled?: boolean,
): UseQueryResult<
  ProductResponseWithPagination,
  AxiosError<ErrorResponseData>
> => {
  return useQuery({
    queryKey: ['getPopularProductList'],
    queryFn: async () => {
      const response = await axiosInstance.get(`/product/popular/`)
      return response.data
    },
    enabled: enabled,
  })
}

const useGetProductDetail = (
  id: number,
): UseQueryResult<ProductResponse, AxiosError<ErrorResponseData>> => {
  return useQuery({
    queryKey: ['getProductDetail', id],
    queryFn: async () => {
      const response = await axiosInstance.get(`/product/${id}/`)
      return response.data
    },
  })
}

const useRequestNewProduct = () => {
  return useMutation<
    void,
    AxiosError<ErrorResponseData>,
    ProductRegisterRequest
  >({
    mutationFn: async (registerData: ProductRegisterRequest) => {
      const files: File[] = registerData.files
      const formData = new FormData()

      for(let item of files) {
        formData.append("files", item)
      }

      formData.append("company_name", registerData.company_name)
      formData.append("contact_name", registerData.contact_name)
      formData.append("category", registerData.category.toString())
      formData.append("phone_number", registerData.phone_number)
      formData.append("product_name", registerData.product_name)
      formData.append("email", registerData.email)
      formData.append("description", registerData.description)
      
      await axiosInstance.post(`/product/request`, formData)
    },
  })
}

export {
  useGetCategoryList,
  useGetProductList,
  useGetPopularKeywordList,
  useGetPopularProductList,
  useGetProductDetail,
  useRequestNewProduct,
}
