import { createBrowserRouter, RouteObject } from 'react-router-dom'
import Home from '@/pages/main/Home'
import ProductPage from '@/pages/product/ProductPage'
import ProductSearch from '@/pages/product/ProductSearch'
import ProductList from '@/pages/product/ProductList'
import ServicePage from '@/pages/service/ServicePage'
import Notice from '@/pages/service/notice/Notice'
import Contact from '@/pages/service/Contact'
import About from '@/pages/about/About'
import ProductDetail from '@/pages/product/detail/ProductDetail'
import Registration from '@/components/product/Registration'
import Login from '@/pages/user/Login'
import SignUp from '@/pages/user/SignUp'
import NoticeArticle from '@/pages/service/notice/NoticeArticle'
import MyPage from '@/pages/user/MyPage'
import NoticeLayout from '@/pages/service/notice/NoticeLayout'
import Estimate from '@/pages/estimate/Estimate'
import PrivatePolicy from '@/pages/policy/Private'
import ServicePolicy from '@/pages/policy/Service'
import ReferencePage from '@/pages/reference/ReferencePage'
import ReferenceLayout from '@/pages/reference/ReferenceLayout'
import Reference from '@/pages/reference/Reference'
import ReferenceArticle from '@/pages/reference/ReferenceArticle'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/user/login',
    element: <Login />,
  },
  {
    path: '/user/signup',
    element: <SignUp />,
  },
  {
    path: '/user/mypage',
    element: <MyPage />,
  },
  {
    path: '/product',
    element: <ProductPage />,
    children: [
      {
        index: true,
        element: <ProductSearch />,
      },
      {
        path: 'search',
        element: <ProductSearch />,
      },
      {
        path: 'list',
        element: <ProductList />,
      },
      {
        path: ':productId',
        element: <ProductDetail />,
      },
    ],
  },
  {
    path: '/service',
    element: <ServicePage />,
    children: [
      {
        path: 'notice',
        element: <NoticeLayout />,
        children: [
          {
            index: true,
            element: <Notice />,
          },
          {
            path: ':articleId',
            element: <NoticeArticle />,
          },
        ],
      },
      {
        path: 'register',
        element: <Registration isProductPage={true} />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
    ],
  },
  {
    path: '/reference',
    element: <ReferencePage />,
    children: [
      {
        path: '',
        element: <ReferenceLayout />,
        children: [
          {
            index: true,
            element: <Reference />,
          },
          {
            path: ':articleId',
            element: <ReferenceArticle />,
          },
        ],
      }
    ],
  },
  {
    path: '/about',
    element: <About />,
  },
  {
    path: '/estimate',
    element: <Estimate />,
  },
  {
    path: '/policy',
    children: [
      {
        path: 'private',
        element: <PrivatePolicy />,
      },
      {
        path: 'service',
        element: <ServicePolicy />,
      }
    ]
  }
]

const router = createBrowserRouter(routes)

export default router
