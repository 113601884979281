import { useResetPassword, VerifyRequest } from '@/API/userAPI'
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  chakra,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Divider,
  ModalCloseButton,
  useMediaQuery,
  useToast,
  UseToastOptions,
  IconButton,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { HiEyeOff } from 'react-icons/hi'
import { HiEye } from 'react-icons/hi2'

interface ResetPasswordProps {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
  onCloseFindPasswordModal: () => void
  verifyParams: Partial<VerifyRequest>
}

const ResetPassword = ({
  isOpen,
  onOpen,
  onClose,
  onCloseFindPasswordModal,
  verifyParams,
}: ResetPasswordProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const toast = useToast()
  // submit 후 이전 모달까지 동시에 닫히도록 설정
  const { mutate: resetPassword, isSuccess } = useResetPassword()

  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [show, setShow] = useState<boolean>(false)
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (password === confirmPassword) {
      const verifyParamsWithPassword = {
        email: verifyParams.email as string,
        code: verifyParams.code as string,
        password: password,
      }
      resetPassword(verifyParamsWithPassword)
    } else {
      toast(
        ToastUtil(
          '비밀번호 불일치 오류',
          'error',
          '입력한 두 비밀번호가 일치하지 않습니다. 다시 입력해 주세요.',
        ) as UseToastOptions,
      )
    }
  }

  useEffect(() => {
    if (isSuccess) {
      toast(
        ToastUtil(
          '비밀번호 변경 완료',
          'success',
          '비밀번호 변경이 완료 되었습니다.',
        ) as UseToastOptions,
      )
      onCloseFindPasswordModal()
      onClose()
    }
  }, [isSuccess])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent p={10} borderRadius={0}>
          <ModalHeader px={isMobile ? 0 : 4}>
            <Flex py={4} fontSize={isMobile ? 'md' : '18px'} fontWeight={700}>
              비밀번호 초기화
            </Flex>
            <Divider border="1px solid black" opacity={1} />
          </ModalHeader>
          <ModalCloseButton fontSize="18px" />
          <ModalBody py={isMobile ? 4 : 8} px={isMobile ? 0 : 4}>
            <chakra.form onSubmit={handleSubmit}>
              <FormControl>
                <Stack spacing={6}>
                  <Flex flexDirection="column" gap={2}>
                    <FormLabel
                      fontWeight={500}
                      fontSize={isMobile ? 'md' : '18px'}
                    >
                      비밀번호
                    </FormLabel>

                    <InputGroup>
                      <Input
                        id="password"
                        h={isMobile ? '50px' : '60px'}
                        type={show ? 'text' : 'password'}
                        bgColor="#F7F7F7"
                        borderRadius={0}
                        border={0}
                        value={password || ''}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputRightElement height="100%">
                        <IconButton
                          size="lg"
                          bg="transparent !important"
                          variant="ghost"
                          aria-label={
                            show ? 'Mask password' : 'Reveal password'
                          }
                          icon={show ? <HiEyeOff /> : <HiEye />}
                          boxSize="6"
                          onClick={() => setShow(!show)}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Flex>
                  <Flex flexDirection="column" gap={2}>
                    <FormLabel fontWeight={500} fontSize="18px">
                      비밀번호 확인
                    </FormLabel>
                    <InputGroup>
                      <Input
                        id="confirm-password"
                        h={isMobile ? '50px' : '60px'}
                        type={showConfirm ? 'text' : 'password'}
                        bgColor="#F7F7F7"
                        borderRadius={0}
                        border={0}
                        value={confirmPassword || ''}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <InputRightElement height="100%">
                        <IconButton
                          size="lg"
                          bg="transparent !important"
                          variant="ghost"
                          aria-label={
                            showConfirm ? 'Mask password' : 'Reveal password'
                          }
                          icon={showConfirm ? <HiEyeOff /> : <HiEye />}
                          boxSize="6"
                          onClick={() => setShowConfirm(!showConfirm)}
                        />
                      </InputRightElement>
                    </InputGroup>
                  </Flex>
                  <Flex w="full" justifyContent="center">
                    <Button
                      type="submit"
                      w={isMobile ? 'full' : '272px'}
                      h={isMobile ? '50px' : '60px'}
                      bgColor="#0D3A65"
                      borderRadius={0}
                      my={4}
                    >
                      입력완료{' '}
                    </Button>
                  </Flex>
                </Stack>
              </FormControl>
            </chakra.form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ResetPassword
function joinUser(USER_INFO: {
  email: any
  fullname: void
  password: string
  is_active: boolean
  is_admin: boolean
}) {
  throw new Error('Function not implemented.')
}

function ToastUtil(arg0: string, arg1: string, arg2: string): UseToastOptions {
  throw new Error('Function not implemented.')
}
