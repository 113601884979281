import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  Image,
  useMediaQuery,
} from '@chakra-ui/react'
import { scrollIntoPage } from '@/util/util'
import { TriangleDownIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate()
  return (
    <Flex
      w="full"
      h={isMobile ? 'auto' : '389px'}
      gap={8}
      justifyContent="space-between"
      alignItems="center"
      px={isMobile ? 2 : '10%'}
      py={isMobile ? 12 : 'auto'}
      backgroundColor="#192530"
    >
      <Flex
        flexDirection="column"
        w="full"
        justifyContent="center"
        gap={4}
        px={4}
      >
        <Flex
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
          gap={6}
        >
          <Flex
            w="full"
            justifyContent="space-between"
            alignItems={isMobile ? 'flex-start' : 'center'}
            flexDirection={isMobile ? 'column' : 'row'}
            gap={isMobile ? 4 : 0}
          >
            <Button
              fontWeight="700"
              fontSize="30px"
              onClick={() => scrollIntoPage('#home')}
              px={0}
            >
              <Image
                src="/logo.png"
                w="92px"
                h="26px"
                filter="brightness(0) invert(1)"
              />
            </Button>
            <Flex gap={8}>
              <Button
                variant="unstyled"
                color="white"
                fontSize="16px"
                fontWeight="bold"
                onClick={() => navigate('/about')}
              >
                회사소개
              </Button>
              <Button
                variant="unstyled"
                color="white"
                fontSize="16px"
                fontWeight="bold"
                onClick={() => navigate('/product/list')}
              >
                상품조회
              </Button>
              <Button
                variant="unstyled"
                color="white"
                fontSize="16px"
                fontWeight="bold"
                onClick={() => navigate('/service/contact')}
              >
                고객센터
              </Button>
            </Flex>
          </Flex>
          <Flex w="full">
            <Divider borderColor="#7F8589" opacity={0.2} px={8} />
          </Flex>
        </Flex>
        <Flex
          flexDirection="column"
          gap={isMobile ? 4 : 2}
          pb={isMobile ? 0 : 4}
        >
          <Flex
            mt={6}
            gap={isMobile ? 4 : 6}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Box>
              <Text color="white" fontSize="14px" fontWeight="bold">
                주식회사 엑스고
              </Text>
            </Box>
            <Flex gap={2}>
              <Text color="white" fontSize="14px" fontWeight="bold">
                대표자
              </Text>
              <Text color="#7F8589" fontSize="14px">
                강택연
              </Text>
            </Flex>
            <Flex gap={2}>
              <Text color="white" fontSize="14px" fontWeight="bold">
                사업자등록번호
              </Text>
              <Text color="#7F8589" fontSize="14px">
                892-53-00900
              </Text>
            </Flex>
          </Flex>
          <Flex
            my={2}
            gap={isMobile ? 4 : 6}
            flexDirection={isMobile ? 'column' : 'row'}
          >
            <Flex gap={2}>
              <Text color="white" fontSize="14px" fontWeight="bold">
                주소
              </Text>
              <Text color="#7F8589" fontSize="14px">
                경기도 수원시 팔달구 향교로 160, 3층 8호 창업지원센터 창업관 308호, 309호(중동, 제일빌딩)
              </Text>
            </Flex>
            <Flex gap={2}>
              <Text color="white" fontSize="14px" fontWeight="bold">
                전화번호
              </Text>
              <Text color="#7F8589" fontSize="14px">
                031-8068-6558
              </Text>
            </Flex>
            <Flex gap={2}>
              <Text color="white" fontSize="14px" fontWeight="bold">
                FAX
              </Text>
              <Text color="#7F8589" fontSize="14px">
                +82-504-376-4087
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          justifyContent="space-between"
          pt={isMobile ? 2 : 8}
          w="full"
          flexDirection={isMobile ? 'column-reverse' : 'row'}
          gap={isMobile ? 6 : 0}
        >
          <Flex gap={4} flexDirection={isMobile ? 'column' : 'row'}>
            <Text color="#7F8589" fontSize={isMobile ? '14px' : 'md'}>
              Copyright(C) 2024 Exgo. All rights reserved.
            </Text>
            <Flex gap={4}>
              <Text textDecor="underline" color="#7F8589" cursor="pointer" onClick={() => navigate('/policy/service')}>
                이용약관
              </Text>
              <Text textDecor="underline" color="#7F8589" cursor="pointer" onClick={() => navigate('/policy/private')}>
                개인정보처리방침
              </Text>
            </Flex>
          </Flex>
          <Button
            h="36px"
            w={isMobile ? 'auto' : '234px'}
            variant="outline"
            color="white"
            borderRadius="0"
            border="1px solid #CCCDCE"
            display="flex"
            justifyContent="space-between"
            rightIcon={<TriangleDownIcon fontSize="5px" />}
            onClick={() => window.open('https://www.forwarder.kr')}
          >
            <Text fontSize="14px" fontWeight="400">
              www.forwarder.kr
            </Text>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Footer
