import PortList from "@/lib/PortList";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Flex, Select } from "@chakra-ui/react";

interface PortInputProps {
  isMobile: boolean;
  defaultValue?: string | undefined;
  onChange: Function;
  country?: string | undefined;
}





const PortInput: React.FC<PortInputProps> = ({ 
  isMobile, 
  onChange,
  country
}) => {
  // PortList Country 중복 제거
  const GetPortList = () => {
    let portList: string[] = [];
    
    for(let item of PortList) 
      if(!portList.includes(item.port)) {
        if(country) {
          if(item.country === country) 
            portList.push(item.port)
        }
        else 
          portList.push(item.port)
      }

    return portList;
  }

  return (
    <Flex w="full">
      <Select
        placeholder="항구(Port)"
        minW="full"
        h={isMobile ? '50px' : '60px'}
        bgColor="#F7F7F7"
        borderRadius={0}
        border="none"
        icon={<TriangleDownIcon fontSize="8px" />}
        textIndent="1rem"
        defaultValue="클릭하여 카테고리를 선택해 주세요."
        onChange={(e) => onChange(e.target.value)}
      >
        {GetPortList()?.map((category, idx) => {
          return (
            <option key={idx} value={category}>
              {category}
            </option>
          )
        })}
      </Select>
    </Flex>
  )
}


export default PortInput