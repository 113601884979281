import { serviceTabContents } from '@/lib/PageContents'
import {
  Box,
  Button,
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

interface ServiceProps {
  isNoButton?: boolean
}

const Service = ({ isNoButton }: ServiceProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const navigate = useNavigate();

  const returnServiceTabContents = () => {
    return serviceTabContents().map((eachTab, idx) => {
      return (
        <Box
          as={isMobile ? TabPanel : Box}
          key={idx}
          className="row skill-box"
          flexBasis={isMobile ? 'none' : { base: '100%', sm: '30%', md: '20%' }}
          px={isMobile ? 0 : 'auto'}
          display={isMobile ? 'flex' : 'block'}
          justifyContent={isMobile ? 'center' : 'normal'}
        >
          <Flex
            className="row-content"
            flexDirection="column"
            gap={isMobile ? 2 : 4}
            alignItems="center"
          >
            <Flex flexDirection="column" gap={2} minH="150px">
              <Text fontSize="24px" fontWeight="bold">
                {eachTab.title}
              </Text>
              <Text fontSize="16px" color="#7F8589">
                {eachTab.content}
              </Text>
            </Flex>
            <Box
              w={isMobile ? 'full' : '339px'}
              h="205px"
              backgroundImage={`/main/thumbnail/main_tn_${idx + 1}.jpeg`}
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              backgroundSize="cover"
            />
          </Flex>
        </Box>
      )
    })
  }
  return (
    <Flex
      id="service"
      py={20}
      px={isMobile ? 6 : '10%'}
      // maxH="1080px"
      minW="100vw"
    >
      <Flex
        flexDirection="column"
        gap={isMobile ? 4 : 6}
        justifyContent="center"
      >
        <Flex flexDirection="column" gap={2} pb={2}>
          <Text
            fontSize={isMobile ? '12px' : '18px'}
            color="#7F8589"
            fontWeight={600}
          >
            WHAT WE DO
          </Text>
          <Heading color="#004A8D" fontSize={isMobile ? '34px' : '50px'}>
            Total Service
          </Heading>
        </Flex>
        {!isNoButton && (
          <Button
            border="1.5px solid #7F8589"
            borderRadius="60px"
            py="20px"
            px="40px"
            w={isMobile ? '166px' : '217px'}
            h={isMobile ? '45px' : '56px'}
            onClick={() => navigate('/about')}
          >
            <Text color="#7F8589" fontSize={isMobile ? '14px' : 'md'}>
              서비스 소개 보러가기
            </Text>
          </Button>
        )}
        {isMobile ? (
          <Tabs
            display="flex"
            w="full"
            h="full"
            justifyContent="center"
            flexDirection="column"
            position="relative"
          >
            <TabPanels transition="all0.3s ease-in-out">
              {returnServiceTabContents()}
            </TabPanels>
            <Flex w="full" justifyContent="center">
              {/* 메인 페이지 회사 소개글 바로 밑, 페이지 컨테이너 */}
              <TabList
                w="150px"
                position="absolute"
                bottom={'-2rem'}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Tab
                  w="50px"
                  borderBottom="3px solid"
                  borderColor="#EDEDED"
                  _selected={{ borderColor: '#0E3961' }}
                ></Tab>
                <Tab
                  w="50px"
                  borderBottom="3px solid"
                  borderColor="#EDEDED"
                  _selected={{ borderColor: '#0E3961' }}
                ></Tab>
                <Tab
                  w="50px"
                  borderBottom="3px solid"
                  borderColor="#EDEDED"
                  _selected={{ borderColor: '#0E3961' }}
                ></Tab>
                <Tab
                  w="50px"
                  borderBottom="3px solid"
                  borderColor="#EDEDED"
                  _selected={{ borderColor: '#0E3961' }}
                ></Tab>
              </TabList>
            </Flex>
          </Tabs>
        ) : (
          <Flex
            id="skills-row-box"
            w="full"
            pb={4}
            pt={8}
            justifyContent={{
              base: 'space-between ',
              sm: 'center',
              md: 'space-between',
            }}
            flexWrap="wrap"
            gap={8}
            overflowY="auto"
          >
            {returnServiceTabContents()}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default Service
