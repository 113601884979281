import { AddIcon, TriangleDownIcon } from '@chakra-ui/icons'
import {
  Flex,
  Heading,
  Button,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useMediaQuery,
  UseToastOptions,
  useToast,
  chakra,
  Select,
  Grid,
} from '@chakra-ui/react'
import UserInfoConsent from '@/components/user/UserInfoConsent'
import { useEffect, useState } from 'react'
import {
  isAllRequestParams,
  ProductRegisterRequest,
  useGetCategoryList,
  useRequestNewProduct,
} from '@/API/productAPI'
import {
  ToastUtil,
  checkEmailValidation,
  checkPhoneNumberVaildation,
} from '@/util/util'
import { useNavigate } from 'react-router-dom'
import PhoneInput from '../form/PhoneInput'

interface RegistrationProps {
  isProductPage: boolean
}

const Registration = ({ isProductPage }: RegistrationProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const toast = useToast()
  const navigate = useNavigate()

  const [isConsent, setIsConsent] = useState<boolean>(false)
  const [targetCategory, setTargetCategory] = useState<number>(0)

  const [files, setFiles] = useState<File[]>([])

  const { mutate: requestNewProduct, isSuccess } = useRequestNewProduct()
  const { data: categoryListData } = useGetCategoryList()

  const toggleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const target = e.target as HTMLFormElement
    const inputs = Array.from(target.elements) as Array<HTMLInputElement>
    const detailTextArea = target.elements[6] as HTMLTextAreaElement
    const params: Partial<ProductRegisterRequest> = {}

    inputs.map((input) => {
      if (input.tagName === 'INPUT' && !input.id.includes('field')) {
        const id = input.id as keyof ProductRegisterRequest
        params[id] = input.value as any
      }
    })

    if (detailTextArea.value) {
      params['description'] = detailTextArea.value
    } else {
      toast(
        ToastUtil(
          '상세 내용 기재 필요',
          'warning',
          '상세 내용을 기재하여 문의해 주세요.',
        ) as UseToastOptions,
      )
      return
    }

    if (isAllRequestParams(params)) {
      const isVaildEmail = checkEmailValidation(params.email)
      const isVaildPhoneNumber = checkPhoneNumberVaildation(params.phone_number)
      if (
        isVaildEmail &&
        isVaildPhoneNumber &&
        isConsent &&
        targetCategory !== 0
      ) {
        const validatePhone = params.phone_number.replace(/-/gi, '')

        const paramsWithCategory = {
          ...params,
          category: targetCategory,
          phone_number: validatePhone,
          files: files
        }

        console.log(paramsWithCategory, files)

        requestNewProduct(paramsWithCategory)
      } else if (!isVaildEmail) {
        toast(
          ToastUtil(
            '이메일 주소 형식 오류',
            'warning',
            '올바른 이메일 주소를 입력해주세요.',
          ) as UseToastOptions,
        )
      } else if (!isVaildPhoneNumber) {
        toast(
          ToastUtil(
            '전화번호 형식 오류',
            'warning',
            '올바른 전화번호를 입력해 주세요.',
          ) as UseToastOptions,
        )
      } else if (!isConsent) {
        toast(
          ToastUtil(
            '개인정보 이용 동의 필요',
            'warning',
            '개인정보 수집 및 이용에 대한 동의가 필요합니다.',
          ) as UseToastOptions,
        )
      } else {
        toast(
          ToastUtil(
            '공란 확인 필요',
            'warning',
            '비어있는 란이 있습니다. 내용을 모두 기재한 후 제출해 주세요.',
          ) as UseToastOptions,
        )
      }
    }
  }

  const handleFilesSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      console.log("선택된 파일:", Array.from(files).map((file) => file.name))
      setFiles(Array.from(files))
    }
  }

  const fetchCategoryList = () => {
    return (
      <Flex w="full">
        <Select
          minW="full"
          h={isMobile ? '50px' : '60px'}
          bgColor="#F7F7F7"
          borderRadius={0}
          border="none"
          icon={<TriangleDownIcon fontSize="8px" />}
          textIndent="1rem"
          defaultValue="클릭하여 카테고리를 선택해 주세요."
          onChange={(e) => setTargetCategory(Number(e.target.value))}
        >
          {categoryListData?.map((category, idx) => {
            return (
              <option key={idx} value={category.id}>
                {category.name}
              </option>
            )
          })}
        </Select>
      </Flex>
    )
  }

  const returnInputForm = () => {
    const eachFormId = [
      {
        name: '기업명',
        id: 'company_name',
      },
      {
        name: '담당자명',
        id: 'contact_name',
      },
      { name: '제품 카테고리', id: 'category' },
      { name: '전화번호', id: 'phone_number' },
      { name: '제품명', id: 'product_name' },
      { name: '이메일', id: 'email' },
    ]
    return eachFormId.map((eachData, idx) => {
      return (
        <Flex
          flex={1}
          minWidth={isMobile ? 'full' : '100%'}
          flexDirection="column"
          key={idx}
          gap={isMobile ? 2 : 4}
          id={`input-group`}
        >
          <FormLabel fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>
            {eachData.name}
          </FormLabel>
          {eachData.id === 'category' ? (
            fetchCategoryList()
          ) : (
            <>
              {eachData.id === 'phone_number' ? 
                <PhoneInput
                  isMobile={isMobile}
                  id="phone_number"
                />
                :
                <Input
                  id={eachData.id}
                  w="auto"
                  h={isMobile ? '50px' : '60px'}
                  bgColor="#F7F7F7"
                  borderRadius={0}
                  border="none"
                  placeholder={
                    eachData.id === 'phone_number' ? '010-xxxx-xxxx' : ''
                  }
                />
              }
            </>
          )}
        </Flex>
      )
    })
  }

  useEffect(() => {
    if (isSuccess) {
      toast(
        ToastUtil(
          '상품 등록 요청 성공',
          'success',
          '상품 등록이 승인될 경우, 추후 상품 목록에서 등록된 상품을 확인하실 수 있습니다.',
        ) as UseToastOptions,
      )
    }
  }, [isSuccess])

  return (
    <Flex
      id="registration"
      py={isMobile ? 10 : isProductPage ? 16 : 32}
      px={isMobile ? 6 : isProductPage ? '15%' : '10%'}
      minW="100vw"
      // 조건문 재 확인 필요
      flexDirection={isProductPage || isMobile ? 'column' : 'row'}
    >
      <Flex
        flexDirection="column"
        gap={isMobile ? 4 : 6}
        justifyContent="flex-start"
        w="full"
        maxW={isProductPage ? 'full' : '400px'}
        py={6}
      >
        <Flex
          flexDirection="column"
          gap={isProductPage ? 4 : 2}
          pb={2}
          alignItems={isProductPage ? 'center' : 'flex-start'}
        >
          {isProductPage ? (
            <></>
          ) : (
            <Text
              fontSize={isMobile ? '12px' : '18px'}
              color="#7F8589"
              fontWeight={600}
            >
              CONTACT
            </Text>
          )}
          <Heading color="#004A8D" fontSize={isMobile ? '34px' : '50px'}>
            Registration
          </Heading>
          {isProductPage && (
            <Text
              fontSize={isMobile ? 'md' : '22px'}
              color="#787E88"
              fontWeight={500}
              textAlign="center"
            >
              제품에 자신 있는 기업은 지금 신청하세요. <br />
              수출은 저희가 책임지겠습니다.
            </Text>
          )}
        </Flex>
        {/* {!isProductPage && (
          <Button
            border="1.5px solid #7F8589"
            borderRadius="60px"
            py="20px"
            px="40px"
            w={isMobile ? '166px' : '217px'}
            h={isMobile ? '45px' : '56px'}
          >
            <Text color="#7F8589">
              상품 등록 요청하기
            </Text>
          </Button>
        )} */}
      </Flex>
      <Flex w="full" py={isProductPage || isMobile ? 8 : 20} overflowY="auto">
        <chakra.form onSubmit={toggleSubmit}>
          <FormControl
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gap={isMobile ? 4 : 8}
          >
            <Grid
              w="full"
              gap={7}
              px="2px"
              templateColumns={{
                md: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
            >
              {returnInputForm()}

            </Grid>
            <Flex px="2px" flex="full" w="full" flexDirection="column" gap={2}>
              <FormLabel fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>
                제품 상세설명
              </FormLabel>
              <Textarea
                minH={isMobile ? '300px' : '185px'}
                bgColor="#F7F7F7"
                borderRadius={0}
                border="none"
              />
            </Flex>
            <Flex flex="full" flexDirection="column" gap={2}>
              <FormLabel
                fontSize={isMobile ? 'md' : '18px'}
                fontWeight={500}
                display="flex"
                flexDirection="row"
                gap={1}
              >
                <Text w={isMobile ? '100px' : 'auto'}>관련 자료</Text>
                <Text color="#7F8589">
                  (제품 이미지, 카달로그, 상세자료, 회사소개서 등 최대 3개까지
                  가능)
                </Text>
              </FormLabel>


              <Input
                id="files"
                type="file"
                multiple
                onChange={handleFilesSelected}
                display="none" // 숨기기
              />
              <Button
                as="label"
                htmlFor="files"
                cursor="pointer"
                colorScheme="gray"
                size="md"
                variant="outline"
                w={isMobile ? '124.17px' : '149px'}
                h={isMobile ? '50px' : '60px'}
                borderRadius="0"
                border="1px solid #7F8589"
                leftIcon={<AddIcon color="#7F8589" />}
                color="#7F8589"
                mb={2}
                bgColor="#F7F7F7"
              >
                파일첨부
              </Button>

              <Text fontSize="16px" color="#6f7c89">
                * 파일 이름과 제품명은 영어로 해주세요.
              </Text>
            </Flex>
            {/* {isProductPage && ( */}
              <>
                <UserInfoConsent
                  isConsent={isConsent}
                  setIsConsent={setIsConsent}
                />
                <Flex w="full" justifyContent="center" my={4}>
                  <Button
                    type="submit"
                    w={isMobile ? "full" : "462px"}
                    h={isMobile ? '50px' : '60px'}
                    bgColor="#0D3A65"
                    borderRadius={0}
                  >
                    상품 등록 요청하기
                  </Button>
                </Flex>
              </>
            {/* )} */}
          </FormControl>
        </chakra.form>
      </Flex>
    </Flex>
  )
}

export default Registration
