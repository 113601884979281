import { createIcon } from '@chakra-ui/react'

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 22 19',
  path: (
    <path
      d="M1 9.5L11 1M11 1L21 9.5M11 1L18 6.95V17C18 17.5523 17.5523 18 17 18H5C4.44772 18 4 17.5523 4 17V6.95L11 1Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      fill="#004A8D"
    />
  ),
})
