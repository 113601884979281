import MainLayout from "@/components/MainLayout"
import { PrivatePolicyText } from "@/lib/Policy"
import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react"

const PrivatePolicy = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  return (
    <MainLayout>
      <Flex
        minH={isMobile ? '650px' : '850px'}
        alignItems="center"
        flexDirection="column"
        py={isMobile ? 20 : 32}
        w="full"
      >        
        <Box maxW="700px" w="full" >
          <Text
            w="full" 
            fontWeight={700} 
            fontSize="40px" 
            marginBottom="60px"
            textAlign="center"
            color="#004A8D"
          >
            개인정보처리방침
          </Text>

          <Box
            whiteSpace="pre-wrap"
            px="40px"
            color="#787E88"
            fontWeight={500}
          >
            {PrivatePolicyText}
          </Box>
        </Box>
      </Flex>
    </MainLayout>
  )
}
export default PrivatePolicy