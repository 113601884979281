import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import MainLayout from '@/components/MainLayout'
import { HomeIcon } from '@/lib/HomeIcon'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useLayoutEffect, useState } from 'react'
import { SearchIcon } from '@chakra-ui/icons'
import {
  ProductRequest,
  ProductResponseWithPagination,
  useGetPopularProductList,
  useGetProductList,
} from '@/API/productAPI'
import { useShallow } from 'zustand/react/shallow'
import useKeywordStore from '@/store/KeywordStore'
import { selector } from '@/util/util'

const ProductPage = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const navigate = useNavigate()
  const location = useLocation()
  const [isProductSearch, setIsProductSearch] = useState<boolean>(true)
  const [URLParams, setURLParams] = useState<Partial<ProductRequest>>({})
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [isEnabledSearchPage, setIsEnabledSearchPage] = useState<boolean>(false)
  const [isEnabledListPage, setIsEnabledListPage] = useState<boolean>(false)
  const [productListData, setProductListData] =
    useState<ProductResponseWithPagination | null>(null)

  const { keyword } = useKeywordStore(useShallow(selector))

  const {
    data: productList,
    isLoading,
    refetch,
  } = useGetProductList(URLParams, isEnabledListPage)

  const {
    data: popularProductListData,
    isLoading: isPopularProductListLoading,
    refetch: refetchPopularProductListLoading,
  } = useGetPopularProductList(isEnabledSearchPage)

  const isListDetailPage =
    (location.pathname.includes('list') || location.pathname !== '/product') &&
    !location.pathname.includes('search')

  const navigatePageAfterResetKeyword = (path: string) => {
    setSearchKeyword('')
    setURLParams({})
    navigate(`/product${path}`)
  }

  const toggleSearch = () => {
    setURLParams({
      ...URLParams,
      search: searchKeyword,
    })
    navigate(`/product/list`)
  }

  useLayoutEffect(() => {
    if (keyword) {
      setSearchKeyword(keyword)
      setURLParams({
        ...URLParams,
        search: keyword,
      })
    }
  }, [keyword])

  useLayoutEffect(() => {
    if (location) {
      setIsProductSearch(!isListDetailPage)
      if (!isListDetailPage) {
        setIsEnabledListPage(false)
        setIsEnabledSearchPage(true)
      } else {
        setIsEnabledSearchPage(false)
        setIsEnabledListPage(true)
      }
    }
  }, [location])

  useEffect(() => {
    if (isListDetailPage && productList) {
      setProductListData(productList)
    } else if (!isListDetailPage && popularProductListData) {
      setProductListData(popularProductListData)
    }
  }, [location, productList, popularProductListData])

  useEffect(() => {
    if (isListDetailPage) {
      refetch()
    } else if (!isListDetailPage) {
      refetchPopularProductListLoading()
    }
  }, [URLParams])

  return (
    <MainLayout>
      <Flex flexDirection="column">
        <Flex
          className="product-inquiry-bg"
          h={isMobile ? '270px' : '453px'}
          backgroundImage="/menu/product_inquiry.jpeg"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          backgroundSize="cover"
          flexDirection="column"
          justifyContent="center"
          alignItems="stretch"
          pt={20}
          pl={isMobile ? 2 : '10%'}
          w="full"
        >
          <Flex
            h="full"
            flexDirection="column"
            justifyContent="space-between"
            zIndex={4}
          >
            <Flex
              flexDirection="column"
              gap={2}
              pb={2}
              h="full"
              justifyContent="center"
              px={isMobile ? 4 : 0}
            >
              <Text
                fontSize={isMobile ? '12px' : '18px'}
                color="#CCCDCE"
                fontWeight={600}
              >
                PRODUCT
              </Text>
              {/* todo: 정가운데 정렬 */}
              <Heading
                color="white"
                fontSize={isMobile ? '30px' : '50px'}
                fontWeight="600"
              >
                상품조회
              </Heading>
            </Flex>
            <Flex
              h={isMobile ? '50px' : '67px'}
              alignItems="center"
              justifyContent="space-between"
              w="full"
            >
              <Box />
              <Flex bgColor="white" alignItems="center">
                <Button
                  w={isMobile ? '50px' : '67px'}
                  h={isMobile ? '50px' : '67px'}
                  bgColor="#004A8D"
                  borderRadius={0}
                  onClick={() => navigate('/product')}
                >
                  <HomeIcon boxSize={6} />
                </Button>
                <Flex
                  px={8}
                  gap={8}
                  w={isMobile ? 'full' : '483px'}
                  // justifyContent={isMobile ? 'space-between' : 'normal'}
                >
                  <Button
                    variant="unstyled"
                    onClick={() => navigatePageAfterResetKeyword('/search')}
                  >
                    <Text
                      textDecor={isProductSearch ? 'underline' : 'none'}
                      color={isProductSearch ? '#004A8D' : '#7F8589'}
                      fontWeight={600}
                    >
                      상품검색
                    </Text>
                  </Button>
                  <Button
                    variant="unstyled"
                    onClick={() => navigatePageAfterResetKeyword('/list')}
                  >
                    <Text
                      textDecor={!isProductSearch ? 'underline' : 'none'}
                      color={!isProductSearch ? '#004A8D' : '#7F8589'}
                      fontWeight={600}
                    >
                      상품목록
                    </Text>
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          px={isMobile ? 2 : '10%'}
          py={isMobile ? 4 : 12}
          flexDirection="column"
          bgColor="white"
          zIndex={1}
        >
          <Flex
            px={4}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={8}
            py={4}
          >
            <InputGroup
              display="flex"
              w={isMobile ? 'full' : '710px'}
              alignItems="center"
            >
              <InputLeftElement mx={3} h="full">
                <SearchIcon color="#004A8D" fontSize="18px" />
              </InputLeftElement>
              <Input
                w={isMobile ? 'full' : '710px'}
                h={isMobile ? '48px' : '60px'}
                bgColor="#F7F7F7"
                borderWidth={0}
                borderRadius="50px"
                placeholder="상품을 검색해보세요."
                fontSize={isMobile ? '14px' : '18px'}
                fontWeight={500}
                textAlign="center"
                value={searchKeyword || ''}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              <InputRightElement w="81px" h="full" mx={isMobile ? 1 : 3}>
                <Button
                  w={isMobile ? '64.8px' : 'full'}
                  h={isMobile ? '36px' : '45px'}
                  bgColor={'#004A8D'}
                  borderRadius="50px"
                  fontSize={isMobile ? '12px' : 'md'}
                  onClick={toggleSearch}
                >
                  검색
                </Button>
              </InputRightElement>
            </InputGroup>
          </Flex>
          <Outlet
            context={{
              productListData,
              isLoading,
              setURLParams,
            }}
          />
        </Flex>
      </Flex>
    </MainLayout>
  )
}

export default ProductPage
