import { PrivatePolicyText } from '@/lib/Policy'
import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Button,
  Heading,
  Checkbox,
  ModalCloseButton,
  Box,
  useMediaQuery,
} from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'

interface TermsPolicyProps {
  isTermsModal: boolean
  isConsent?: boolean
  setIsConsent?: Dispatch<SetStateAction<boolean>>
  isModalOpen: boolean
  onModalOpen: () => void
  onModalClose: () => void
}

const TermsPolicy = ({
  isTermsModal,
  isConsent,
  setIsConsent,
  isModalOpen,
  onModalOpen,
  onModalClose,
}: TermsPolicyProps) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const handleConsent = () => {
    if (setIsConsent) {
      setIsConsent(true)
    }
    onModalClose()
  }

  return (
    <>
      {setIsConsent ? (
        <Flex
          gap={4}
          w="full"
          justifyContent={isMobile ? 'space-between' : 'flex-end'}
          alignItems="center"
        >
          <Text
            cursor="pointer"
            fontWeight={600}
            fontSize={isMobile ? '14px' : '18px'}
            color="#CCCDCE"
            textDecoration="underline"
            onClick={onModalOpen}
          >
            내용보기
          </Text>
          <Flex gap={4}>
            <Text
              fontWeight={600}
              fontSize={isMobile ? '16px' : '18px'}
              color="#004A8D"
            >
              동의하기
            </Text>
            <Checkbox
              colorScheme="blue"
              isChecked={isConsent}
              onChange={(e) => setIsConsent(e.target.checked)}
            />
          </Flex>
        </Flex>
      ) : (
        <Box
          fontSize="sm"
          cursor="pointer"
          onClick={onModalOpen}
          textDecor={isTermsModal ? 'none' : 'underline'}
        >
          {isTermsModal ? '이용약관' : '개인정보처리방침'}
        </Box>
      )}
      <Modal
        size="6xl"
        isOpen={isModalOpen}
        onClose={onModalClose}
        scrollBehavior="inside"
      >
        <ModalOverlay id="no_padding_top" />
        <ModalContent px={4}>
          <ModalHeader>
            <Flex justifyContent="center" pt={4} pb={2}>
              <Heading fontSize="3xl">
                {isTermsModal ? '이용약관' : '개인정보처리방침'}
              </Heading>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text 
              px={4}
              whiteSpace="pre-wrap"
            >
              {/* todo: 내용 대체 */}
              {PrivatePolicyText}
            </Text>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="center" my={6}>
            <Button
              h="60px"
              w="full"
              onClick={handleConsent}
              whiteSpace="normal"
              bgColor="#0D3A65"
            >
              {setIsConsent
                ? `
              위의 ${isTermsModal ? '이용약관' : '처리방침'}에 동의합니다.`
                : '확인'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TermsPolicy
