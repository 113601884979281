import { QueryCache, QueryClient } from '@tanstack/react-query'
import axiosInstance from './http'
import { handleAPIError } from '../util/util'

const defaultQueryFn = async ({ queryKey }: any) => {
  try {
    const { data } = await axiosInstance.get(queryKey[0])
    return data
  } catch (error) {
    throw error
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      throwOnError: true,
    },
    mutations: {
      onError: (error) => handleAPIError(error),
    },
  },
  queryCache: new QueryCache({
    onError: (error) => handleAPIError(error),
  }),
})

export default queryClient
