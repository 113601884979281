import { AxiosError } from 'axios'
import { useMutation, useQuery, UseQueryResult } from '@tanstack/react-query'
import axiosInstance from '@/API/http'
import { ErrorResponseData, partialData } from '@/util/util'


export interface CargoProps {
  info: string | undefined;
  type: string | undefined;
  danger_class: string | undefined;
  length: number | undefined;
  width: number | undefined;
  height: number | undefined;
  unit: string | undefined;
  min_temperature: number | undefined;
  max_temperature: number | undefined;
  container_type: string | undefined;
  container_count: number | undefined;
  weight: number | undefined; 
}


export interface EstimateState {
  export_or_import: string,
  phone: string,
  scheduled_departure_date: string,
  transportation_mode: string,
  departure_country: string,
  departure_port: string,
  destination_country: string,
  destination_port: string,
  stuffing_province: string,
  stuffing_city: string,
  lcl_length: number,
  lcl_width: number,
  lcl_height: number,
  lcl_unit: string,
  lcl_weight: number,
  memo: string,
  cargos: CargoProps[]
}


// ESTIMATE 시 체크
export const isAllStateParams = (
  params: Partial<EstimateState>,
): params is EstimateState => {
  return (
    typeof params.export_or_import === 'string' &&
    typeof params.scheduled_departure_date === 'string' &&
    typeof params.transportation_mode === 'string' &&
    typeof params.departure_country === 'string' &&
    typeof params.departure_port === 'string' &&
    typeof params.destination_country === 'string' &&
    typeof params.destination_port === 'string' &&
    typeof params.stuffing_province === 'string' &&
    typeof params.stuffing_city === 'string'
  )
}


const useRequestNewEstimate = () => {
  return useMutation<
  void,
  AxiosError<ErrorResponseData>,
  EstimateState
>({
  mutationFn: async (registerData: EstimateState) => {
    await axiosInstance.post(`/product/estimate`, registerData)
  },
})
}

export {
  useRequestNewEstimate
}